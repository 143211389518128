@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playwrite+CU:wght@100..400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");
/* ------------------------------------- */
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap');


.flipX {
  animation: 6s anim-flipX ease infinite;
}
@keyframes anim-flipX {
  0% {
    /* opacity: 0; */
    opacity: 1;
    transform: rotateX(90def);
  }
  25% {
    opacity: 1;
    transform: rotateX(720deg);
    color: var(--web-main);
  }
  100% {
    /* animate nothing to pause animation at the end */
    opacity: 1;
    transform: rotateX(720deg);
  }
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


@media only screen and (max-width: 600px) {
  .web-navbar {
    padding: 10px;
  }
}

.calculator-content-bg {
  min-height: 400px;
}

.calculator-content {
  margin-top: -400px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: mulish;
  font-family: "Playwrite CU";
  font-family: roboto; */
  /* font-family: inter; */
  /* font-family: poppins; */
  /* font-family: "Source Sans 3", sans-serif; */
}

:root {
  --main: #174555;
  --invoice: #174555;
  --web-main: #174555;

  
  --bg-dark: #1c3036;
  --white: #ffffff;
  --bg-light: #e8eff9;
  
  --web-main: #e91d2e;
  --web-bg-light :#e91d2e20;
  /* --web-main: #174555;
  --web-bg-light :#174555; */
}

body {
  background-color: var(--white);
  font-size: 15px;
  font-family: poppins;
}
body button,
body input,
body textarea{
  font-family: poppins;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  resize: none;
}

.rupee-text {
  display: inline;
  font-family: sans-serif;
}
.rupee-text::before {
  content: "₹";
}

/* ----------------------------------------------------------------------------------------------- */

.home-page {
  max-width: 1200px;
  margin: 20px auto;
  display: grid;
  grid-template-columns: 4fr 5fr;
  align-items: center;
}
.home-page img {
  width: 600px;
}

.home-page-content-heading-1 {
  font-size: 3rem;
  font-weight: 500;
}
.home-page-content-heading-2 {
  font-size: 1.2rem;
}
.home-page-content-heading-3 {
  font-size: 2rem;
  font-weight: 600;
}
.home-page-content-heading-4 {
  font-size: 4rem;
  font-weight: 700;
}


/* ====================================================================================== */
/* ====================================================================================== */

.slick-dots li button:before{
  font-size: 12px;
}

/* ====================================================================================== */
/* ====================================================================================== */





/* ====================================================================================== */
/* ====================================================================================== */





@media only screen and (max-width: 600px) {
  .home-page {
    max-width: 1200px;
    margin: 20px auto;

    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }
  .home-page img {
    width: 100%;
    padding: 20px 60px 0;
  }

  .home-page-content-heading-1 {
    font-size: 2.5rem;
  }
  .home-page-content-heading-2 {
    font-size: 1.1rem;
  }
  .home-page-content-heading-3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .home-page-content-heading-4 {
    font-size: 3rem;
    font-weight: 700;
  }
}
