
.special-purpose-tools-main{
    max-width: 1140px;
    margin: 100px auto;
}

.special-purpose-tools-heading{
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
}


.special-purpose-tools-content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 50px 30px;
    gap: 50px ;
    margin: 30px 0;
}

.special-purpose-tools-col{
    display: flex;
    align-items: center;
    gap: 10px;
    /* border: 1px solid; */
}


.special-purpose-tools-col-icon{
    display: flex;
}
.special-purpose-tools-col-label{
    text-decoration: none;
    color: #314259;
    font-size: 1rem;
}


@media only screen and (max-width: 1000px) {

    
.special-purpose-tools-main{
    margin: 60px auto;
    padding: 0 30px;
}

.special-purpose-tools-heading{
    font-size: 1.5rem;
}


.special-purpose-tools-content{
    grid-template-columns: 1fr ;
    gap: 25px ;
    margin: 10px 0;
}




    
    
}


