.add-staff-form{
    background-color: #00000060;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: auto;
    left: 0;
    
    position: fixed;
    left: 0;
    width: 100%;
}

.add-staff-form-main{
    background-color: white;
    border-radius: 5px;
    width: 600px;
    /* margin: auto; */
}

.add-staff-form-head{
    display: flex;
    padding: 10px;
    justify-content: space-between;
    font-size: 1.3rem;
    font-weight: 600;
    border-bottom: solid 1px #00000030;
    color: var(--main);
}

.add-staff-form-head i{
    font-size: 1.8rem;
    cursor: pointer;
}


/* ------------------------------------------------------------------ */

.add-staff-form-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 10px 20px;
}

.add-staff-form-col>label{
    color: #000000bd;
    font-weight: 500;
    font-size: .9rem;
}
.add-staff-form-col select,
.add-staff-form-col input{
    width: 100%;
    padding: 5px 10px;
    border: solid 1px #00000030;
    border-radius: 5px;
}

/* ------------------------------------------------------------------------------- */

.add-staff-form-option{
    padding: 10px 20px 0;
    color: blue;
    font-weight: 500;
    cursor: pointer;
}

.add-staff-form-heading{
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background: rgb(from var(--main) r g b / 15%) !important;    
    align-items: center;
}

.add-staff-form-heading>div{
    font-size: 1rem;
    font-weight: 600;
    color: var(--main);
}
.add-staff-form-heading>span{
    color: red;
    font-weight: 500;
    font-size: .9rem;
    cursor: pointer;
}

.add-staff-form-col>div{
    display: flex;
}
.add-staff-form-col>div>select{
    width: 60%;
    font-weight: 500;
    padding: 5px;
    background-color: #00000020;

}


.add-staff-form-buttons{
    display: flex;
    justify-content: flex-end;
    gap: 30px;
    padding: 20px;
}
.add-staff-form-buttons>div,
.add-staff-form-buttons>button{
    border: solid 2px var(--main);
    font-size: 1.1rem;
    font-weight: 500;
    padding: 2px 20px;
    color: var(--main);
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
}

.add-staff-form-buttons>button{
    background-color: var(--main);
    color: white;
}


