.start-invoicing{
    /* background-color: var(--main)15; */
}
.start-invoicing-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: auto;
    padding: 50px 20px;
    gap: 50px;
}

.start-invoicing-heading-1{
    font-size: 2.5rem;
    font-weight: 600;
    font-family: "Roboto Slab";
    color: var(--web-main);
}
.start-invoicing-heading-2{
    font-size: 1.1rem;
    display: flex; 
    align-items: center;
    gap: 5px;
}
.start-invoicing-heading-2 div{
    font-weight: 600;
    font-size: 1.4rem;
}
.start-invoicing-heading-2>s{
    font-size: 1.3rem;
    font-weight: 600;
}
.start-invoicing-link{
    background-color: var(--main);
    color: white;
    font-size: 1.2rem;
    padding: 10px 30px;
    border-radius: 10px;
    text-decoration: none;
    font-weight: 500;
}

@media only screen and (max-width: 720px) {

    .start-invoicing-main{
        flex-direction: column;
        text-align: center;
        gap: 20px;        
    }
    
    .start-invoicing-heading-1{
        font-size: 1.8rem;
    }

    .start-invoicing-heading-2{
        font-size: .9rem;
        display: block;
    }

    
.start-invoicing-heading-2 div{
    font-size: 1.2rem;
    display: inline;
}

    .start-invoicing-heading-2>s{
        font-size: 1.1rem;
    }

    
    
}

