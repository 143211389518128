.top-features{
    background-color: #3c90ac3b;
    padding: 10px;

}
.top-features-main{
    max-width: 1300px;
    margin: 40px auto;

}


.top-features-heading{
    font-size: 2.2rem;
    font-weight: 600;
    color: var(--main);
    display: none;
}

.top-features-container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-columns: 1fr 1.2fr 1.1fr 1.1fr;
    margin: 30px auto;
    gap: 30px;
}

.top-features-col{
    display: flex;
    /* background-color: #3c90ac3b; */
    background-color: white;
    padding: 15px 10px 20px;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}


.top-features-col-heading{
    font-weight: 600;
    font-size: 1.15rem;
}
.top-features-col-text{
    font-size: .85rem;
    margin: 5px 0 15px;
}


.top-features-col-button{
    background-color: #3c90ac3b;
    /* background-color: white; */
    text-decoration: none;
    color: var(--main);
    font-weight: 600;
    padding: 5px 15px;
    border-radius: 5px;
}



/* -------------------------------------------------------------------------------- */

.top-features-col-icon>img{
    height: 70px;
    filter: drop-shadow(0 0 0.75rem #0d3b4b8e);
}


@media only screen and (max-width: 720px) {

    .top-features-main{
        margin: 50px 20px;
    }
    
    
    .top-features-heading{
        font-size: 1.8rem;
    }
    
    .top-features-container{
        grid-template-columns: 1fr ;
        margin: 10px auto;
        gap: 25px;
    }
    
    .top-features-col{
        padding: 15px 20px 20px;
        gap: 10px;
    }
    
    
    .top-features-col-heading{
        font-weight: 600;
        font-size: 1.15rem;
    }
    .top-features-col-text{
        font-size: .85rem;
        margin: 5px 0 15px;
    }
    
    
    
    
    
    /* -------------------------------------------------------------------------------- */
    
    .top-features-col-icon>img{
        height: 80px;
    }
    
    
    
    
}

