
.small-buss-acc-software-main{
    width: 85%;
    margin: 100px auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: #314259;
    gap: 50px;
}

.small-buss-acc-software-heading{
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 1.2;
}

.small-buss-acc-software-text{
    margin: 40px 0 80px;
}

.small-buss-acc-software-button{
    color: #fff;
    background-color: var(--web-main);
    padding: 10px 50px;
    font-size: 1.2rem;
    font-weight: 500;
    text-decoration: none;
    border-radius: 10px;
}

.small-buss-acc-software-image>img{
    width: 100%;
}


@media only screen and (max-width: 1000px) {

.small-buss-acc-software-main{
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column-reverse;
    margin: 60px 0;
    gap: 20px;
}
    
.small-buss-acc-software-heading{
    font-size: 1.8rem;
}
.small-buss-acc-software-heading>br{
    display: none;
}
.small-buss-acc-software-text{
    margin: 20px 0 30px;
}
.small-buss-acc-software-button{
    padding: 5px 30px;
    font-size: 1rem;
}

.small-buss-acc-software-image{
    width: 70%;
    margin: auto;
}

}





