
.footer{
    background: url(../../../../media/images/in.jpg);
}

.footer-main{
    background-color: #000000ea;
    color: #b9b9b9e0;
    color: #ffffffe0;
}
.footer-main a{
    color: #b9b9b9e0;
    color: #ffffffe0;
    text-decoration: none;
}
.footer-main a:hover{
    text-decoration: underline;
}

.footer-container{
    padding: 80px 0px 60px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-columns: 4.7fr 3fr;
    max-width: 1250px;
    margin: auto;
}

.footer-container-col-1{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 50px;
}
.footer-container-col-1-col{
    display: grid;
    gap: 15px;
}
.footer-container-col-1-col>a>i{
    /* color: red; */
    width: 25px;
    text-align: center;
}

.footer-container-col-2{
    padding: 0 30px 0 60px;
    align-content: center;
}
.footer-container-col-2-heading{
    font-size: 1.5rem;
    color: white;
    font-weight: 500;
}

.footer-container-col-2-text{
    font-size: .9rem;
    margin: 10px 0 20px;
}

.footer-container-col-2-input{
    /* border: solid black; */
    display: flex;
}
.footer-container-col-2-input>input{
    width: 100%;
    border: none;
    padding: 8px 15px;
    background-color: #00000060;
    color: white;
    border: 1px solid #fff;
    
}
.footer-container-col-2-input>button{
    background-color: white;
    color: #174555;
    font-weight: 600;
    border: none;
    padding: 8px 30px;
    
}

/* ------------------------------------------------------------------------------------------- */

.footer-end{
    display: flex;
    justify-content: space-between;
    border-top: 3px solid #fcfcfc65;
    max-width: 1200px;
    margin:auto;
    padding: 20px 0;
}
.footer-end-col-1{
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: .9rem;
}
.footer-end-col-1 img{
    width: 180px;
    /* opacity: .7; */
}

.footer-end-col-2{
    display: flex;
    gap: 10px;
    align-items: center;
}
.footer-end-col-2>a{
background-color: #ffffff1c;
width: 40px;
height: 40px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 15px;
}


@media only screen and (max-width: 720px) {
    

.footer-container{
    padding: 40px 15px;
    display: flex;
    flex-direction: column-reverse;
    gap: 50px;    
}

.footer-main a{
    font-size: .85rem;
}

.footer-container-col-1{
    justify-content: space-around;
    /* justify-content: start; */
    gap: 50px 10px;
}

/* .footer-container-col-1-col{
    display: grid;
    gap: 15px;
} */

 
/* .footer-container-col-1-col>a>i{
    width: 25px;
    text-align: center;
} */


.footer-container-col-2{
    padding: 0 10px ;
    align-content: center;
}
.footer-container-col-2-heading{
    font-size: 1.5rem;
    color: white;
    font-weight: 500;
}

.footer-container-col-2-text{
    font-size: .8rem;
    margin: 5px 0 15px;
}

/* .footer-container-col-2-input{
    display: flex;
} */







/* .footer-container-col-2-input>input{
    width: 100%;
    border: none;
    padding: 8px;
    background-color: #00000060;
    color: white;
    
} */
/* .footer-container-col-2-input>button{
    background-color: white;
    color: #174555;
    font-weight: 600;
    border: none;
    padding: 8px 30px;
    
} */

/* ------------------------------------------------------------------------------------------- */

.footer-end{
    /* display: flex;
    justify-content: space-between;
    border-top: 2px solid #174555a2;
    max-width: 1200px;
    margin:auto;
    padding: 20px 0; */


    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
    padding: 20px 0;
    margin: 0 20px;
    
    
}
.footer-end-col-1{
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.footer-end-col-1 img{
    width: 160px;
    opacity: .7;
}

.footer-end-col-2{
    display: flex;
    gap: 10px;
    align-items: center;
}
.footer-end-col-2>a{
/* background-color: #00000080; */
width: 40px;
height: 40px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 15px;
}



}