.stylish-a4-theme{
    background-color: #c2c2c2; 
    font-family: "Source Sans 3", sans-serif;
}

.stylish-a4-theme-data{
    width: 792px;
    background-color: #fff;
    margin: auto;
    padding: 0px 25px;
    font-size: 14px;
    font-family: "Source Sans 3", sans-serif !important;
}

.stylish-a4-theme-label{
    display: flex;
    text-transform: uppercase;
    align-items: center;
    gap: 6px;
    font-weight: 500;
    padding-top: 25px;
}

.stylish-a4-theme-label>span{
    border: solid 1px #868686;
    color: #868686;
    font-size: 12px;
    padding: 2px 5px;
}



.stylish-a4-theme-header{
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 15px;
}
.stylish-a4-theme-header-logo>img{
   
    max-width: 150px;
    max-height: 100px;
    min-width: 80px;
}

.stylish-a4-theme-header-company{
    font-size: 24px;
    font-weight: 700;
    color: var(--invoice);
}

.stylish-a4-theme-header-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 450px;
    gap: 0px 20px;
}

.stylish-a4-theme-header-col{
    display: flex;
    gap: 5px;
}

.stylish-a4-theme-header-col>div{
    font-weight: 600;
}

/* -------------------------------------------------------------------------------- */

.stylish-a4-theme-invoice-option{
    display: flex;
    justify-content: space-around;
    gap: 20px;
    border-top: 5px solid var(--invoice) ;
    /* background-color: #d8d8d8; */
    padding: 8px;
}

.stylish-a4-theme-invoice-option-col{
    display: flex;
    gap: 5px;
}
.stylish-a4-theme-invoice-option-col>div{
    font-weight: 600;
}

/* -------------------------------------------------------------------------------- */


.stylish-a4-theme-details{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.stylish-a4-theme-address{
    padding: 10px;
    display: flex;
    gap: 20px;
    flex: 1 ;
}
.stylish-a4-theme-address-data{
    width: 100%;
}
.stylish-a4-theme-address-heading{
    font-size: 16px;
    font-weight: 500;
}

.stylish-a4-theme-address-name{
    font-size: 16px;
    font-weight: 600;
}

.stylish-a4-theme-address-text>span{
    font-weight: 600;
}

.stylish-a4-theme-option>table>tr>td{
    padding: 6px 5px 0 ;
    vertical-align: top;
}
.stylish-a4-theme-option>table>tr>td:first-child{
    font-weight: 600;
}



/* -------------------------------------------------------------------------------- */

.stylish-a4-theme-items-table{
    min-height: 300px;
}

.stylish-a4-theme-items table{
    width: 100%;
}

.stylish-a4-theme-items table>tr>td{
    padding: 8px;
    text-align: center;
    vertical-align: top;
}

.stylish-a4-theme-items table>tr:first-child{
    /* background-color: #dfdfdf99; */
    font-weight: 600;
}


.stylish-a4-theme-items-total{
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
    background-color: #dfdfdf99;
    border-top: 2px solid var(--invoice);
}

.stylish-a4-theme-items-total{
    font-size: 16px;
    font-weight: 600;
}

.stylish-a4-theme-end{
    display: grid;
    grid-template-columns: 5fr 3fr;
    padding: 10px;
    padding-bottom: 0;
}

.stylish-a4-theme-end-heading{
    font-weight: 600;
    font-size: 15px;
}

.stylish-a4-theme-end-pre > pre{
    margin-bottom: 10px;
    font-family: "Source Sans 3", sans-serif;
    font-size: 13px;
    white-space: pre-wrap;
}

.stylish-a4-theme-end-col-bank{
    font-size: 13px;
}

.stylish-a4-theme-end-col-bank-details{
    margin-bottom: 10px;
}
.stylish-a4-theme-end-col-bank-details table tr>td:first-child{
    font-weight: 600;
}
.stylish-a4-theme-end-col-bank-details table tr>td:nth-child(2){
    padding-left: 10px;
}



.stylish-a4-theme-end-col-bank-payment{
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
}
.stylish-a4-theme-end-col-bank-payment-details>div{
    font-weight: 600;
}

.stylish-a4-theme-end-col-bank-payment-icons{
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
}
.stylish-a4-theme-end-col-bank-payment-icons>img{
    height: 12px;
}


.stylish-a4-theme-end-col-amount-row-2,
.stylish-a4-theme-end-col-amount-row-1{
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}

.stylish-a4-theme-end-col-amount-row-2{
    margin: 5px 0;
    border-top: solid 2px var(--invoice);
    border-bottom: solid 2px var(--invoice);
    font-weight: 600;
    font-size: 15px;
    /* padding: 10px;  */
}

.stylish-a4-theme-end-col-amount-words{
    text-align: end;
    padding-bottom: 30px;
}

.stylish-a4-theme-end-col-amount-words>div:first-child{
    font-weight: 600;
}
.stylish-a4-theme-end-col-amount-words>div:nth-child(2){
    text-transform: uppercase;
}






/* -------------------------------------------------------------------------------- */







