.right-technique-main {
  width: 80%;
  margin: 0 auto 100px;
}
.right-technique-heading {
  font-size: 2.2rem;
  text-align: center;
  font-weight: 600;
  color: #314259;
  margin-bottom: 30px;
}

.right-technique-content {
  width: 100%;
  display: flex;
  gap: 30px;
  justify-content: space-evenly;
  /* gap: 100px;
    justify-content: space-between; */
  /* background-color: #314259; */
}

.right-technique-content-col {
  background-color: #9992;
  text-align: center;
  flex: 1;
  /* width: 100%; */
  border-radius: 20px;
  padding: 20px 60px 40px;
}

.right-technique-content-icon {
  font-size: 5rem;
  color: var(--web-main);
}

.right-technique-content-text {
  font-size: 1.1rem;
  margin-top: 10px;
}

.right-technique-content-heading {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 10px;
}

.right-technique-content-icon {
  height: max-content;
  display: flex;
  justify-content: center;
}
.right-technique-content-icon > img {
  height: max-content;
  width: 82px;
  /* margin-bottom: -10px; */
}

@media only screen and (max-width: 1000px) {
  .right-technique-main {
    width: 100%;
    margin: 0px 0 60px;
    padding: 0 20px;
  }

  .right-technique-content {
    flex-direction: column;
  }

  .right-technique-heading {
    font-size: 1.5rem;
  }


.right-technique-content-col {
    padding: 20px;
}
.right-technique-content-icon {
    font-size: 3rem;
}

.right-technique-content-icon > img {

    width: 70px;
}
  
}
