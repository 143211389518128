.invoice-gst-theme {
  padding: 25px;
  /* background-color: #f3f3f3; */
  font-size: 14px;
}

.invoice-gst-theme-heading {
  display: flex;
  gap: 10px;
  font-weight: 600;
  align-items: center;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.invoice-gst-theme-heading > div {
  border: #aaa 2px solid;
  color: #707070;
  padding: 0 10px;
  font-size: 13px;
}

.invoice-gst-theme-content {
  border: solid 1px black;
}

.invoice-gst-theme-head {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.invoice-gst-theme-head-company {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 15px 10px;
}

.invoice-gst-theme-head-company-image > img {
    max-width: 110px;
    max-height: 100px;
}

.invoice-gst-theme-head-company-name {
    color: var(--invoice);
  font-size: 17px;
  font-weight: 700;
}
.invoice-gst-theme-head-company-text {
  line-height: 1.1;
  margin-bottom: 4px;
}
.invoice-gst-theme-head-company-text b {
  font-weight: 600;
}

.invoice-gst-theme-head-options {
  border-left: solid 1px;
  height: 100%;
  align-content: center;
}

.invoice-gst-theme-head-row {
  display: flex;
  justify-content: space-around;
  text-align: center;
  flex-wrap: wrap;
  padding: 15px 10px;
  gap: 10px;
}
.invoice-gst-theme-head-row:nth-child(2) {
  border-top: solid 1px;
}
.invoice-gst-theme-head-col {
  width: 108px;
}

.invoice-gst-theme-head-col > label {
  font-weight: 600;
}

.invoice-gst-theme-head-address {
  border-top: solid 1px #000;
  display: flex;
  gap: 1px;
}
.invoice-gst-theme-head-address > div {
  width: 100%;
  padding: 10px;
}
.invoice-gst-theme-head-address > div:nth-child(2) {
  border-left: solid 1px #000;
}

.invoice-gst-theme-head-address-heading {
  font-weight: 600;
}

.invoice-gst-theme-head-address-name {
  font-size: 16px;
  font-weight: 700;
}
.invoice-gst-theme-head-address-text {
    line-height: 1.1;
    margin-bottom: 4px;
}
.invoice-gst-theme-head-address-text>b{
    font-weight: 600;
}
/* table ====================================================================================== */

.invoice-gst-theme-table{
    font-size: 13px;
}

.invoice-gst-theme-table-body,
.invoice-gst-theme-table-head{
    display: flex;
}
.invoice-gst-theme-table-end
.invoice-gst-theme-table-body{
    border-top: 1px solid #000;
}
.invoice-gst-theme-table-end
.invoice-gst-theme-table-body>div{
    text-align: center !important;
    font-weight: 700;
}

.invoice-gst-theme-table-body>div,
.invoice-gst-theme-table-head>div{
    border-right: 1px solid;
    padding: 10px 5px;
    word-break: break-all;
    text-align: center;
}

.invoice-gst-theme-table-head>div{
    /* background-color: #00000020; */
    padding: 8px;
    border-top: #000 solid 1px;
    text-transform: uppercase;
    font-weight: 700;
}
.invoice-gst-theme-table-body>div>div{
    font-size: 12px;
    color: #909090;
}
.invoice-gst-theme-table-body>div>div>div{
    text-align: end;
    font-style: italic;
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.invoice-gst-theme-table-body>div:last-child,
.invoice-gst-theme-table-head>div:last-child{
    border-right: none;
}

.invoice-gst-theme-table-data{
    min-height: 200px;
    display: flex;
    flex-direction: column;
    border-top: #000 solid 1px;
}

.invoice-gst-theme-table-data>div:last-child{
    flex: 1;
}

.invoice-gst-theme-table-no{
    width: 7%;
}

.invoice-gst-theme-table-item{
    flex: 1;
}
.invoice-gst-theme-table-body
.invoice-gst-theme-table-item{
text-align: start !important;
}

.invoice-gst-theme-table-qty{
    width: 10%;
}

.invoice-gst-theme-table-cess,
.invoice-gst-theme-table-tax,
.invoice-gst-theme-table-disc{
    width: 8%;
}

.invoice-gst-theme-table-hsn{
    width: 10%;
}

.invoice-gst-theme-table-amount,
.invoice-gst-theme-table-rate{
    width: 14%;
}


/* tax table ============================================================== */

.invoice-gst-theme-tax-table{
    margin-top: 10px;
    border: #000 solid 1px;
    /* border-bottom: #000 solid 1px; */
}

.invoice-gst-theme-tax-table-body,
.invoice-gst-theme-tax-table-head{
    display: grid;
    grid-template-columns: .8fr 1.1fr 1fr 1fr .8fr 1.2fr ;
    text-align: end;
}

.invoice-gst-theme-tax-table-head>div{
    /* background-color: #00000020; */
    border-right: #000 solid 1px;
    align-content: center;
    text-align: center;
    font-weight: 600;
    border-bottom: #000 solid 1px;
    padding: 5px 2px;
}
.invoice-gst-theme-tax-table-body>div{
    border-right: #000 solid 1px;
    padding: 5px 2px;

}

.invoice-gst-theme-tax-table>div>div:last-child{
    border-right: none;
}

.invoice-gst-theme-tax-table-gst{
    padding: 0 !important;
}
.invoice-gst-theme-tax-table-gst-heading{
    border-bottom: #000 solid 1px;
}

.invoice-gst-theme-tax-table-gst-heading-1{
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.invoice-gst-theme-tax-table-gst-heading-1>div{
    padding: 5px 2px;
}

.invoice-gst-theme-tax-table-gst-heading-1>div:first-child{
    border-right: #000 solid 1px;
}

.invoice-gst-theme-end{
    border: #000 1px solid;
    border-top: none;
}
.invoice-gst-theme-end>div:nth-child(2)>div{
    border-top: #000 1px solid;
    
}

.invoice-gst-theme-end-amount{
    padding: 5px 10px;
}

.invoice-gst-theme-end-heading{
    font-weight: 600;
}

.invoice-gst-theme-end-row{
    display: flex;
}

.invoice-gst-theme-end-row>div{
    width: 100%;
    padding: 5px 10px;
    border-right: #000 solid 1px;
}

.invoice-gst-theme-end-row>div:last-child{
    border-right: none;
}

.invoice-gst-theme-end-bank-row{
    display: flex;
}
.invoice-gst-theme-end-bank-row>div{
    font-weight: 500;
    width: 80px;
}
.invoice-gst-theme-end-amount
.invoice-gst-theme-end-text{
    text-transform: capitalize;
}
.invoice-gst-theme-end-text{
    line-height: 1.1;
}
.invoice-gst-theme-end-text>pre{
    white-space: pre-wrap;
    word-break: break-all;
    font-size: 13px;
}

.invoice-gst-theme-end-signature{
    text-align: end;
    align-content: end;
}
.invoice-gst-theme-end-signature-box{
    height: 60px;
}
.invoice-gst-theme-end-signature-text>div{
    font-weight: 600;
}
.invoice-gst-theme-end-signature-img>img{
    max-width: 150px;
    max-height: 60px;
}


/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.setting-invoice-page-invoice
.invoice-gst-theme-head-col{
    width: 88px;    
}



/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.invoice-bill-book-theme
.invoice-gst-theme-head {
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr; */
    display: flex;
}
.invoice-gst-theme-head>div{
    width: 100%;
}



.invoice-bill-book-theme
.invoice-gst-theme-head-company-content{
    text-align: center;
    flex: 1;
}



.invoice-bill-book-theme
.invoice-gst-theme-head-address>div:nth-child(2){
    /* background-color: aqua; */
    border-left: none;
    padding: 0;
    height: 100%;
}
.invoice-bill-book-theme
.invoice-gst-theme-head-address>div:first-child{
    border-right: #000 1px solid;
    
}

.invoice-gst-theme-end-notes{
    margin-bottom: 10px;
}

.invoice-bill-book-theme
.invoice-gst-theme-table-data{
    min-height: 300px;
}



    