.faq-component {
  background-color: #e9e9e9;
  padding: 70px 0;
}

.faq-component-main {
  width: 80%;
  margin: auto;
}

.faq-component-heading {
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;
  color: #314259;
  margin-bottom: 40px;
}

.faq-component-content{
    background-color: #fff;
    border-radius: 10px;
}

.faq-component-content-col{
    border-bottom: #d3d6da 1px solid;
}
.faq-component-content>
    .faq-component-content-col:last-child{
        border: none;
    }

.faq-component-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    font-size: 1.3rem;
    font-weight: 600;
    color: #314259;
    cursor: pointer;
    gap: 10px;
}

.faq-component-question .fa-angle-down{
    color: var(--web-main);
}

.faq-component-answer{
    padding: 15px;
    background-color: var(--bg-light);
    display: grid;
    gap: 20px;
}

@media only screen and (max-width: 1000px) {

    .faq-component{
        padding: 30px 15px;
    }
    .faq-component-main{
        width: 100%;
    }
    
    .faq-component-heading {
      font-size: 1.4rem;
      /* padding: 15px; */
      margin-bottom: 15px;
    }

.faq-component-question {
    font-size: 1rem;
    font-weight: 500;
}
.faq-component-answer{
    font-size: .85rem;
}
    

    
}


