.talk-expert-popup {
  background-color: rgba(17, 17, 17, 0.651);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.talk-expert-popup-main {
  background: #fff;
  background: url(../../../../media/images/background/scroll-popup-step-2.webp);
  background-position: right;
  border-radius: 10px;
  background-size: cover;
}

.talk-expert-popup-close {
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
  margin-bottom: -70px;
  padding: 20px;
}
.talk-expert-popup-close i {
  cursor: pointer;
}

.talk-expert-popup-content {
  padding: 70px 20px;
  width: 1000px;
}

.talk-expert-popup-content-heading-2,
.talk-expert-popup-content-heading-1 {
  font-size: 60px;
  font-weight: 800;
  color: var(--web-main);
  line-height: 1.3;
}
.talk-expert-popup-content-heading-2 {
  color: #000;
  margin-bottom: 20px;
}
.talk-expert-popup-content-text {
  font-size: 25px;
}

.talk-expert-popup-content-form {
  display: flex;
  gap: 20px;
}

.talk-expert-popup-content-form-heading {
  font-size: 20px;
  margin-top: 20px;
}
.talk-expert-popup-content-form input {
  font-size: 20px;
  padding: 10px;
  border-radius: 8px;
}

.talk-expert-popup-content-form-button,
.talk-expert-popup-content-form button {
  background-color: var(--web-main);
  border: none;
  color: #fff;
  font-weight: 500;
  padding: 5px 20px;
  border-radius: 8px;
  font-size: 25px;
  cursor: pointer;
}
.talk-expert-popup-content-form-button{
  background-color: #8d9ca1;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: no-drop;
}

.free-trail-main
.talk-expert-popup-close {
    margin-right: -10px;
    margin-bottom: -15px;
    padding: 0;
    z-index: 3;
    position: relative;
}
.free-trail-main
.talk-expert-popup-close>i{
    background-color: #fff;
    color: #000;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    align-content: center;
    text-decoration: none;
}
.free-trail-content>img{
    width: 800px;
}


@media only screen and (max-width: 1000px) {
  .talk-expert-popup{
    padding: 15px;
  }
  .talk-expert-popup-main {
    background: #fff;
  }
  .talk-expert-popup-content {
    width: auto;
    padding: 30px 20px;
  }

  
.talk-expert-popup-content-heading-2,
.talk-expert-popup-content-heading-1{
    font-size: 30px;
}
.talk-expert-popup-content-heading-2{
    margin-bottom: 10px;
}
.talk-expert-popup-content-text{
    font-size: 15px;
}
.talk-expert-popup-content-form {
    flex-wrap: wrap;
    gap: 5px;
}
.talk-expert-popup-content-form-heading{
    font-size: 15px;
    margin-top: 10px;
}
.talk-expert-popup-content-form input {
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
    width: 220px;
}
.talk-expert-popup-content-form-button,
.talk-expert-popup-content-form button{
  width: 220px;
  font-size: 14px;
}
.talk-expert-popup-content-form-button{
  padding: 5px;
  justify-content: center;
}

.free-trail-main
.talk-expert-popup-close>i{
    background-color: #fff;
    color: #000;
    border-radius: 50%;
    width:  25px;
    height: 25px;
    text-align: center;
    align-content: center;
}

.free-trail-content>img{
    width: 100%;
    margin: auto;
}
}


