.about-header-main {
  background-color: aliceblue;
  padding: 150px;
  background: url(../../../../media/images/website/desktop-hero.webp);

  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-header-heading {
  color: #fff;
  font-size: 3rem;
  font-weight: 600;
}
.about-header-button {
  background-color: #ffc210;
  padding: 10px 40px;
  font-size: 1.4rem;
  border-radius: 30px;
  width: max-content;
}

.about-header-image {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .about-header-main {
    background: linear-gradient(
      174deg,
      rgba(237, 26, 59, 1) 0%,
      rgba(241, 91, 67, 1) 88%
    );
    padding: 100px 20px;
  }
  
  .about-header-heading {
      font-size: 2rem;
      text-align: center;
    }

.about-header-button {
    margin: auto;
    padding: 5px 30px;
    font-size: 1.2rem;
}
    
      .about-header-image {
        display: block;
        width: 80%;
        margin: 40px auto 10px;
      }

  .about-header-image > img {
    width: 100%;
  }
}
