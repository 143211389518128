.how-generate-qr-main {
  max-width: 1140px;
  margin: 80px auto;
}

.how-generate-qr-head-heading {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}
.how-generate-qr-head-text {
  padding: 20px 100px;
  font-size: 1rem;
}

.how-generate-qr-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  margin-top: 30px;
}

.how-generate-qr-content-image {
  width: 150px;
  margin: auto;
}
.how-generate-qr-content-image img {
  width: 150px;
}

.how-generate-qr-content-heading {
  font-size: 1rem;
  font-weight: 600;
}

.how-generate-qr-content-text>span{
  font-size: 1.3rem;
  color: var(--web-main);
  font-weight: 500;
  margin-right: 5px;
}

.how-generate-qr-button {
  background-color: var(--web-main);
  color: #fff;
  padding: 7px 20px;
  border-radius: 20px;
  font-size: 1rem;
  text-decoration: none;
  /* text-align: center; */
  display: block;
  width: max-content;
  margin: auto;
  margin-top: 30px;
}

.how-generate-qr-end {
  display: flex;
  justify-content: center;
  background-color: #e91838;
}
.how-generate-qr-end img {
  width: 100%;
  max-width: 800px;
}

@media only screen and (max-width: 1000px) {
  .how-generate-qr-main {
    padding: 20px;
    margin: 40px auto;
  }

  .how-generate-qr-head-heading {
    font-size: 1.4rem;
  }

  .how-generate-qr-head-text {
    padding: 10px;
    font-size: 0.85rem;
  }

  .how-generate-qr-content {
    grid-template-columns: 1fr;
  }
}
