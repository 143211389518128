.view-staff-main,
.view-party-page-main {
  width: 100%;
  height: calc(100vh - 22px);
  border-radius: 10px;
  margin-bottom: 30px;

  /* -------------------- */
  display: flex;
  background-color: #ffffff;
  color: #292d35;
  font-size: 15px;
}

.view-staff-col-filter,
.view-party-page-col-filter {
  /* background-color: #ffffff; */
  border-right: solid 1px #d9d4d4;
  padding: 15px 0;
  font-size: 13px;
  width: 250px;
}

.view-party-page-filter-empty {
  padding: 10px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.view-party-page-filter-head,
.view-party-page-filter-search {
  display: flex;
  border: solid 1px #d9d4d4;
  border-radius: 5px;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  margin: 0 15px 15px;
}

.view-party-page-filter-search input {
  border: none;
  flex: 1;
  width: 100%;
  outline: none;
}
.view-party-page-filter-search i {
  color: #79797c;
}

.view-party-page-filter-data-list {
  display: grid;
  /* margin: 15px 0px ; */
  padding: 0 15px;
  gap: 15px;
  max-height: calc(100vh - 105px);
  overflow: auto;
}

.view-party-page-filter-data-list::-webkit-scrollbar {
  width: 7px;
}

.view-party-page-filter-data-list::-webkit-scrollbar-thumb {
  background-color: #79797c;
  border-radius: 20px;
}

.view-party-page-filter-data-list > div {
  border: solid 1px #d9d4d4;
  border-radius: 10px;
  padding: 12px 8px;
  cursor: pointer;
}
.view-party-page-filter-data-active {
  background-color: #f1f0fc;
  border: solid 1px #b7b1eb !important;
}

.view-party-page-filter-balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #79797c;
  margin-top: 5px;
}

.view-staff .fa-arrow-down,
.view-party-page .fa-arrow-down {
  color: #49a563;
}

.view-staff .fa-arrow-up,
.view-party-page .fa-arrow-up {
  color: #d94141;
}

/* *********************************************************************************************888 */

.view-party-page-col-data {
  flex: 1;
  width: 100%;
  padding: 15px;
}

.view-party-page-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-party-page-top-heading i {
  cursor: pointer;
}

.view-party-page-top-heading {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}
.view-party-page-top-buttons {
  display: flex;
  gap: 15px;
  align-items: center;
}
.view-party-page-top-buttons > div {
  border: solid 1px #d9d4d4;
  padding: 0 10px;
  height: 35px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.view-party-page-top-delete {
  color: #d94141;
}

/* ----------------------------------- */

.view-party-page-data-options {
  display: flex;
  border-bottom: solid 1px #d9d4d4;
  gap: 15px;
  margin-top: 10px;
}
.view-party-page-data-options > div {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px;
  font-weight: 500;
  cursor: pointer;
}

.view-party-page-data-option-col svg {
  fill: #525a6a;
}

.view-party-page-data-option-col-active {
  color: #4c3cce;
  border-bottom: solid 2px #4c3cce;
}
.view-party-page-data-option-col-active svg {
  fill: #4c3cce;
}

/* ........................................... */

.view-party-page-data {
  padding: 10px 0;
}

.view-party-page-data-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  height: calc(100vh - 155px);
  overflow: auto;
  /* background: #000; */
}
.view-party-page-data-row::-webkit-scrollbar {
  width: 8px;
}
.view-party-page-data-row::-webkit-scrollbar-thumb {
  background-color: #79797c;
  border-radius: 20px;
}

.view-party-page-data-col {
  border: solid 1px #d9d4d4;
  border-radius: 5px;
  height: max-content;
  margin-bottom: 15px;
}

.view-party-page-data-col > div {
  padding: 8px 16px;
}

.view-party-page-data-col-head {
  border-radius: 5px 5px 0 0;
  background-color: #f6f7f7;
  border-bottom: solid 1px #d9d4d4;
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 600;
}
.view-party-page-data-col-data {
  display: grid;
  gap: 15px;
  padding: 12px 16px !important;
}

.view-party-page-data-col-data-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.view-party-page-data-col-data-heading {
  color: #79797c;
  margin-bottom: 5px;
}

.view-party-page-data-col-data-text span {
  color: #8f8f92;
  font-size: 13px;
}

/* .view-party-page-data-row-col{
    display: grid;
    gap: 15px;
} */

/* ---------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------- */

.view-party-page-transaction-data-table {
  border: solid 1px #d9d4d4;
  border-radius: 10px;
  margin: 15px 0;
  font-size: 14px;
}
.view-party-page-transaction-data-table-head,
.view-party-page-transaction-data-table-body {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.view-party-page-transaction-data-table > div > div {
  padding: 10px;
  border-right: solid 1px #d9d4d4;
  border-top: solid 1px #d9d4d4;
}
.view-party-page-transaction-data-table-head > div:last-child {
  border-right: none;
}
.view-party-page-transaction-data-table-head > div {
  /* border-top: solid 1px #d9d4d4; */
  border-top: none !important;
  background-color: #f0f1f3;
  font-weight: 600;
}
.view-party-page-transaction-data-table-body {
  max-height: calc(100vh - 190px);
  overflow: auto;
}
.view-party-page-transaction-data-table-body::-webkit-scrollbar {
  width: 8px;
}
.view-party-page-transaction-data-table-body::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 10px;
}

.view-party-page-transaction-data-table-empty {
  text-align: center;
  padding: 50px 0;
}

.view-party-page-transaction-partially-paid,
.view-party-page-transaction-paid,
.view-party-page-transaction-unpaid {
  color: #d94141;
  background-color: #d9414120;
  font-weight: 500;
  font-size: 13px;
  border-radius: 10px;
  padding: 0px 8px;
}
.view-party-page-transaction-partially-paid {
  color: #eb7100;
  background-color: #eb710020;
}
.view-party-page-transaction-paid {
  color: #49a563;
  background-color: #49a56320;
}

.view-party-page-transaction-data-small-main{
  display: none;
}


.view-party-page-transaction-data-table-body-edit{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.view-party-page-transaction-data-table-body-edit i{
  margin-right: 10px !important;
  font-size: 1.2rem;
  cursor: pointer;
  color: #4c3cce;

}

/* ---------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1000px) {

  .view-party-page-transaction-data-table{
    display: none;
  }
  
  .view-staff-main, .view-party-page-main{
    position: fixed;
    width: 100%;
    z-index: 1;
    height: 100%;
    overflow: auto;
  }
  
  .view-party-page-main{
    padding: 55px 0;

  }
  .view-party-page-col-data{
    padding: 0;
  }

  .view-party-page-top{
    border-bottom: #d3d3d3 1px solid;
    padding: 10px;
    position: fixed;
    background-color: #fff;
    width: 100%;
    top: 0;
  }
  
  .view-staff-col-filter,
  .view-party-page-col-filter{
    display: none;
  }

  .view-party-page-data-options{
    margin: 0 0px;
    padding: 0 10px;
    background-color: #e6e6e6;
    position: sticky;
    top: 0;
  }

  .view-party-page-data-row{
    display: flex;
    flex-direction: column;
    padding: 15px;
    height: 100%;
    
  }

  .view-party-page-data{
    /* padding: 0 15px; */
    padding: 0;
  }
  
  .view-party-page-transaction-data-small-main{
    padding: 0 15px 30px;
    display: block;
    overflow: auto;
    height: 100%;
  }
  
}



