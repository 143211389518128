
.setting-business-page-main,
.setting-invoice-page-main{
    background-color: white;
    height: calc(100vh - 20px);
    border-radius: 10px;
    font-size: 15px;
    overflow: auto;
}


.setting-business-page-head,
.setting-invoice-page-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 15px 25px;
}
.setting-business-page-main-heading,
.setting-invoice-page-heading{
    font-size: 18px;
}

.setting-business-page-buttons,
.setting-invoice-page-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.setting-business-page-button-cancel,
.setting-business-page-button-off,
.setting-business-page-button,
.setting-invoice-page-button-off,
.setting-invoice-page-button{
    background-color: #4c3cce;
    color: white;
    border: none;
    padding: 5px 40px;
    font-size: 13px;
    border-radius: 5px;
    cursor: pointer;
    border: solid 1px #4c3cce;
}

.setting-business-page-button-off,
.setting-invoice-page-button-off{
    opacity: .3;
    cursor: no-drop;
}
.setting-business-page-button-cancel{
    border: solid 1px #d9d4d4;
    color: #000;
    background-color: white;
}


.setting-invoice-page-content{
    display: flex;
    justify-content: space-between;
}

/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
.setting-invoice-page-invoice-main{
    /* height: 30px; */
    padding: 15px;
    background-color: var(--bg-light);
    border-radius: 10px;
    margin: 0 10px;
    flex: 1;
}

.setting-invoice-page-invoice{
    background-color: white;
    height: calc(100vh - 130px);
    width: 700px;
    overflow: auto;
    margin: auto;
}
.setting-invoice-page-invoice::-webkit-scrollbar{
    width: 8px;
}
.setting-invoice-page-invoice::-webkit-scrollbar-thumb{
    background-color: #888888;
    border-radius: 10px;
}
.setting-invoice-page-invoice::-webkit-scrollbar-track{
    background-color: #dddddd;
    border-radius: 10px;
}

/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
.setting-invoice-page-setting{
    width: 33%;
    min-width: 320px;
    border: solid 1px #d9d4d4;
    margin-right: 15px;
    border-radius: 10px;
    height: calc(100vh - 90px);
    overflow: auto;
}
.setting-invoice-page-setting::-webkit-scrollbar{
    width: 8px;
}
.setting-invoice-page-setting::-webkit-scrollbar-thumb{
    background-color: #777777;
    border-radius: 10px;
}
.setting-invoice-page-setting::-webkit-scrollbar-track{
    background-color: #eeeeee;
    border-radius: 10px ;
}

.setting-invoice-page-setting-col-active,
.setting-invoice-page-setting-col{
    border-bottom: solid 1px #d9d4d4;
    padding: 15px;
}
.setting-invoice-page-setting-col-active{
    border: solid 2px #0c8ce9;
}

.setting-invoice-page-setting-heading-1,
.setting-invoice-page-setting-heading{
    font-size: 16px;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    display: flex;
    cursor: pointer;
    text-decoration: none;
    color: #000000;
}

.setting-invoice-page-setting-heading-1{
    cursor: auto;
}
.setting-invoice-page-setting-heading-1>span{
    color: #0000ff;
    text-decoration: underline;
    cursor: pointer;
}
.setting-invoice-page-setting-heading-1>span>input{
    width: 1px;
    height: 1px;
}


.setting-invoice-page-setting-theme{
    margin-top: 5px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    color: #687e96;
    font-size: 12px;
    text-align: center;
}
.setting-invoice-page-setting-theme-col-active>img,
.setting-invoice-page-setting-theme-col>img{
    width: 100%;
    border: solid 1px #d9d4d4;
    border-radius: 5px;
    cursor: pointer;
}
.setting-invoice-page-setting-theme-col-active>img{
border: solid 4px #4c3cce;
}


.setting-invoice-page-setting-color{
    display: flex;
    gap: 5px;
    margin-top: 10px;
}
.setting-invoice-page-setting-color>div{
    /* border: solid 1px #d9d4d4; */
    height: 35px;
    cursor: pointer;
    flex: 1;
    color: white;
    font-size: 22px;
    text-align: center;
    align-content: center;
    padding-top: 2px;
}

.setting-invoice-page-setting-color>div:first-child{
    border-radius: 10px 0 0 10px;
    background-color: #000000;
}

.setting-invoice-page-setting-color>div:nth-child(2){
    background-color: #407400;
}

.setting-invoice-page-setting-color>div:nth-child(3){
    background-color: #0b6a9f;
}

.setting-invoice-page-setting-color>div:nth-child(4){
    background-color: #840bb2;
}

.setting-invoice-page-setting-color>div:nth-child(5){
    background-color: #c11111;
}

.setting-invoice-page-setting-color>div:nth-child(6){
    background-color: #5b57ae;
}

.setting-invoice-page-setting-color>div:nth-child(7){
    background-color: #cd9d23;
}

.setting-invoice-page-setting-color>div:nth-child(8){
    background-color: #bf6200;
}

.setting-invoice-page-setting-color>div:last-child{
    border-radius: 0 10px 10px 0;
}

.setting-invoice-page-setting-theme-setting-list{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 12px;
}

.setting-invoice-page-setting-theme-setting-list input{
    width:  17px;
    height: 17px;
    /* accent-color: var(--main); */
}

.setting-invoice-page-setting-theme-setting-list-custom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.setting-invoice-page-setting-theme-setting-list-custom i{
    color: #c11111;
    cursor: pointer;
}

.setting-invoice-page-setting-theme-setting-link{
    color: #0c8ce9;
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
    width: max-content;
}

.setting-invoice-page-setting-add-field{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 15px ;
}

.setting-invoice-page-setting-add-field input{
    flex: 1;
    border: solid 1px #d9d4d4;
    padding: 5px 10px;
    border-radius: 5px;
}
.setting-invoice-page-setting-add-field div{
    background-color: #4c3cce;
    color: white;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}
.setting-invoice-page-setting-add-field i{
    font-size: 22px;
    cursor: pointer;
}

.setting-invoice-page-setting-section{
    padding: 15px 20px 10px;
    margin: 0 -15px;
    border-bottom: solid 1px #d9d4d4;
}
.setting-invoice-page-setting-section select,
.setting-invoice-page-setting-section textarea{
    border: solid 1px #d9d4d4;
    width: 100%;
    padding: 5px 10px;
    border-radius: 5px;
    outline: none;
    margin-top: 5px;
}

.setting-invoice-page-setting-signature-alert{
    font-weight: 600;
    color: #c11111;
    text-align: center;

}

.setting-invoice-page-setting-signature-select-button,
.setting-invoice-page-setting-signature-show{
    margin-top: 15px;
    border: solid 1px #d9d4d4;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    padding: 4px 12px;
}
.setting-invoice-page-setting-signature-show>div{
    display: flex;
    align-items: center;
    gap: 6px;
}

.setting-invoice-page-setting-signature-show>div>img{
    max-height: 22px;
    max-width: 120px;
}

.setting-invoice-page-setting-signature-show>div>span{
    font-size: 12px;
}
.setting-invoice-page-setting-signature-show>i{
    font-size: 20px;
    cursor: pointer;
}

.setting-invoice-page-setting-signature-select-button{
    text-align: center;
    border: dashed #0c8ce9 1px;
    justify-content: center;
    color: #0c8ce9;
    font-weight: 600;
}

.setting-invoice-page-setting-signature-select-text{
    font-size: 12px;
    color: #687e96 ;
    margin-top: 5px;
}

    
    

/* ------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------- */
.inoice-setting-border{
    border: solid #0c8ce9 3px;
}

/* ------------------------------------------------------------------------------- */


@media only screen and (max-width: 1000px) {

    
.setting-business-page-main,
.setting-invoice-page-main{
    
    height: 100%;
}
    
    .setting-invoice-page-head{
        background-color: #fff;
        position: fixed;
        width: 100%;
        padding: 10px;
        border-bottom: solid 1px #d9d4d4;
        z-index: 1;
    }
    .setting-invoice-page-heading{
        display: flex;
        gap: 10px;
    }

    .setting-invoice-page-invoice-main{
        display: none;
    }

    .setting-invoice-page-setting{
        width: 100%;
        height: 100%;
        margin: 10px;
    }
    
    .setting-invoice-page-content{
        margin-top: 60px;
    }
    
}
