
.app-page-layout{
    background-color: var(--bg-light);
    position: fixed;
    width: 100%;
    height: 100vh;
    font-family: "Source Sans 3", sans-serif;
}

.app-page-layout pre,
.app-page-layout textarea,
.app-page-layout select,
.app-page-layout input{
    font-family: "Source Sans 3", sans-serif;
}

.app-page-main{
    display: flex;
}

.app-page-navbar{
    width: 250px;
    height: cal( 100vh - 20px );
    margin: 10px;
    overflow: auto;
    border-radius: 10px;
}

.app-page-content{
    width: calc(100% - 260px ) ;
    height: cal( 100vh - 20px );
    padding: 10px;
    overflow: auto;
    border-radius: 10px;    
}




@media only screen and (max-width: 1000px) {
    .app-page-layout{
        position: relative;
        overflow: auto;
        height: 100%;
    }
    .app-page-navbar{
        display: none;
    }
    .app-page-content{
        width: 100%;
        height: 100%;
        padding: 0;
    }
    
}


