
.elements-create-invoice-main{
    width: 90%;
    margin: 100px auto;
}

.elements-create-invoice-heading{
    font-size: 2.2rem;
    font-weight: 600;
    text-align: center;
    margin: 0 50px 30px;
}

.elements-create-invoice-content{
    display: grid;
    grid-template-columns: 3fr 4fr;
    gap: 30px;
}

.elements-create-invoice-image>img{
    width: 100%;
}

.elements-create-invoice-details{
    color: #666666;
    font-size: 1rem;
}

.elements-create-invoice-details-text{
    line-height: 2.2;
    margin-bottom: 10px;
}
.elements-create-invoice-details-heading{
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 10px;
}
.elements-create-invoice-details-heading>span{
    font-weight: 400;
}
.elements-create-invoice-details-points{
    margin: 10px 30px;
}

@media only screen and (max-width: 1000px) {

.elements-create-invoice-main{
    width: 100%;
    margin: 50px 0;
    padding: 0 20px;
}

.elements-create-invoice-heading{
    font-size: 1.5rem;
    margin: 0;
}
    
.elements-create-invoice-content{
    grid-template-columns: 1fr;
    gap: 10px;
}
    
.elements-create-invoice-image{
    width: 80%;
    margin: auto;
}
    
}



