.create-expense-page-main,
.app-expense-page-main,
.app-staff-page-main,
.app-cash-bank-main,
.app-dashboard-component-main {
  background-color: #ffff;
  border-radius: 10px;
  height: calc(100vh - 20px);
}

.create-expense-page-main-top,
.app-expense-page-top,
.app-staff-page-top,
.app-cash-bank-top,
.app-dashboard-component-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  border-bottom: solid 1px #d9d4d4;
}

.create-expense-page-main-top-heading,
.app-expense-page-top-heading,
.app-staff-page-heading,
.app-cash-bank-top-heading,
.app-dashboard-component-top-heading {
  font-size: 20px;
}
.create-expense-page-main-top-button,
.app-cash-bank-top-buttons,
.app-dashboard-component-top-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.create-expense-page-main-top-button > button,
.create-expense-page-main-top-button > div,
.create-expense-page-main-top-button > a,
.app-expense-page-top-button-create,
.app-staff-page-button-add,
.app-cash-bank-top-buttons > div,
.app-dashboard-component-top-buttons > a,
.app-dashboard-component-top-buttons > div {
  border: solid 1px #999999;
  border-radius: 5px;
  padding: 5px 20px;
  cursor: pointer;
  font-size: 13px;
  text-decoration: none;
  color: #000000;
}
.app-dashboard-component-top-book-demo {
  color: #488dff !important;
  border-color: #488dff !important;
  font-weight: 600;
}

.app-dashboard-component-content {
  padding: 20px;
  max-height: calc(100vh - 67px);
  overflow: auto;
}

.app-dashboard-component-content::-webkit-scrollbar{
  width: 8px;
} 
.app-dashboard-component-content::-webkit-scrollbar-thumb{
  background-color: #174555;
  border-radius: 10px;
  cursor: grabbing;
}




.app-dashboard-component-row-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.app-dashboard-component-row-2>.app-dashboard-component-section-2-col:first-child{
  border: #2cc646 solid 1px;
  background-color: #2cc64615;
}

.app-dashboard-component-row-2>.app-dashboard-component-section-2-col:last-child{
  border: #d01717 solid 1px;
  background-color: #d0171715;
}

.app-dashboard-component-row-2 img {
  width: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
}

/* -------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------- */

.app-dashboard-component-section-2 {
  margin: 40px 0 20px;
}
.app-dashboard-component-section-2-col {
  display: flex;
  justify-content: space-between;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border: solid 1px #d9d4d4;
  padding: 20px;
  font-size: 17px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
}

.app-dashboard-component-section-2-col-heading {
  display: flex;
  align-items: center;
  gap: 10px;
}

.app-dashboard-component-section-2
  > div
  > a:first-child
  > .app-dashboard-component-section-2-col-heading:first-child {
  color: #2cc646 !important;
}

.app-dashboard-component-section-2
  > div
  > a:last-child
  > .app-dashboard-component-section-2-col-heading:first-child {
  color: #d01717;
}

/* -------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------- */

.app-dashboard-component-section-3 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}

.app-dashboard-component-section-3 .app-transaction-page-table {
  font-size: 14px;
  margin: 0;
}

.app-transaction-page-table-link {
  text-align: center;
  width: 100%;
  padding: 10px;
}

.app-transaction-page-table-link a {
  color: #488dff;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
}

/* -------------------------------------------------------------------------------- */
.app-dashboard-component-section-3-col-2-cash-bank {
  border: solid 1px #d9d4d4;
  border: solid 1px #488dff;
  background-color: #488dff15;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  padding: 20px 15px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
}
.app-dashboard-component-section-3-col-2-cash-bank-heading {
  color: #687e96;
  color: #488dff;
  display: flex;
  align-items: center;
  gap: 5px;
}

.app-dashboard-component-section-3-col-2-image img {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  width: 100%;
  margin-top: 15px;
  border-radius: 5px;
  cursor: pointer;
}

/* -------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------- */

.sales-report-graph-main{
  border: #d9d4d4 1px solid;
  margin: 30px 0;
  border-radius: 5px;
}
.sales-report-graph-heading{
  border-bottom: #d9d4d4 1px solid;
  padding: 10px;
  font-weight: 500;
  display: block;
  color: #000000;
  text-decoration: none;
}

.sales-report-graph-heading>span{
  color: #687e96;
}

.sales-report-graph-content{
  padding: 20px;
  padding-left: 0;
  height: 300px;
  margin-left: -10px;
}


/* -------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------- */

@media only screen and (max-width: 1000px) {

  
.app-dashboard-component-main {
  /* background-color: #ffff;
  border-radius: 10px; */
}

.app-dashboard-component-main{
  height: auto;
  /* height: 100vh; */
  overflow: auto;

}
  
  .app-dashboard-component-top-heading {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .create-expense-page-main-top,
  .app-expense-page-top,
  .app-staff-page-top,
  .app-cash-bank-top,
  .app-dashboard-component-top {
    padding: 8px;
    position: fixed;
    top: 0px;
    background-color: white;
    width: 100%;
  }
  .app-dashboard-component-content{
    padding: 60px 20px 0;
    max-height: none;
    height: 100%;
  }

  .app-dashboard-component-section-1>
  .app-dashboard-component-row-2{
      grid-template-columns: 1fr;
    }
    .app-dashboard-component-row-2>a:first-child{
      /* display: none; */
    }
    
    
    .app-dashboard-component-top-book-demo{
      display: none;
    }
    
    
    .app-dashboard-component-section-2 {
      margin: 20px 0 ;
    }
    
    
    .app-dashboard-component-top-buttons > a{
      color: #fff;
      background-color: #174555 !important;
      border-color: #174555 !important;
      font-weight: 600;
      font-size: 12px;

    }
    
    .app-dashboard-component-section-2-col{
      flex-direction: column-reverse;
      padding: 10px;
    }

    .app-dashboard-component-section-2-col-heading {
      flex-direction: row-reverse;
      justify-content: start ;
    }
    
    
    
    .app-dashboard-component-section-3{
      display: flex;
      flex-direction: column-reverse;
    }
    
  
}

/* -------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------- */
