

.invoice-generator-header{
    background: url(../../../media/images/website/hero-section-bg-new.webp);
}

.invoice-generator-header-main{
    display: flex;
    align-items: center;
    max-width: 1000px;
    margin:  auto;
    padding: 100px 0;
    gap: 100px;
}

.invoice-generator-header-image>img{
    width: 100%;
}
    
.invoice-generator-header-heading{
    font-weight: 600;
    font-size: 3rem;
    line-height: 1.2;
    margin-bottom: 20px;
}

.invoice-generator-header-button{
    background-color: var(--web-main);
    color: #fff;
    width: max-content;
    padding: 10px 50px;
    font-size: 1.05rem;
    font-weight: 500;
    border-radius: 10px;
    margin-top: 50px;
    cursor: pointer;
}
    

@media only screen and (max-width: 1000px) {
    
    .invoice-generator-header-main{
        padding: 50px 20px;
    }

    .invoice-generator-header-heading{
        font-size: 2rem;
    }

    
.invoice-generator-header-button{
    padding: 8px 20px;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 10px;
    margin-top: 30px;
}
    
    
    .invoice-generator-header-image{
        display: none;
    }
    
}




