.app-package-duration{
    padding:20px;
    display: flex;
    gap: 40px;
}
.app-package-duration>div{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
    border-radius: 10px;
}
.app-package-duration-type{
    padding: 15px 30px;
}
    .app-package-duration-type-heading{

    font-size: 1.3rem;
    text-transform: capitalize;
    font-weight: 600;
    /* margin-bottom: 10px; */
}

.app-package-duration-type-text>span{
    font-weight: 600;
    font-size: 1.1rem;
}
.app-package-duration-type-button{
    margin: 20px 0 10px;
}

.app-package-duration-type-button>a{
    background-color: var( --main );
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 500;

}

/* ============================================================================================================ */
.app-package-duration-duration{
    flex: 1;
    display: flex;
    align-items: center;
    gap: 50px;
    justify-content: space-evenly;
    padding: 20px 40px;
}

.app-package-duration-duration-heading{
    font-size: 1.6rem;
    color: var(--main);
    font-weight: 700;
}

.app-package-duration-duration-content{
    display: flex;   
    gap: 50px;
}

.app-package-duration-duration-content-col{
    display: flex;
    gap: 10px;
    align-items: center;
}

.app-package-duration-duration-content-col-icon{
    font-size: 1.5rem;
    color: var( --main );
    background: rgb(from var(--main) r g b / 15%);
    height: 50px;
    width:  50px;
    align-content: center;
    text-align: center;
    border-radius: 50%;
}
.app-package-duration-duration-content-col-text{
    display: grid;
    font-size: 1.8rem;
    gap: 0px;
    font-weight: 500;
}
.app-package-duration-duration-content-col-text>span{
    font-size: 1.1rem;
    margin-top: -10px;
    font-weight: 400;
}


