.update-staff-popup {
  z-index: 5;
}

.app-staff-page-main {
  color: #28313b;
  font-size: 14px;
}
.app-staff-page-content-section-1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  gap: 20px;
  padding: 20px;
  max-width: 1100px;
  margin: auto;
}

.app-staff-page-content-section-1 > div {
  border: solid 1px #d9d4d4;
  border-radius: 5px;
}
.app-staff-page-content-section-1-col-img {
  height: 186px;
  align-content: center;
  border-bottom: solid 1px #d9d4d4;
}
.app-staff-page-content-section-1-col-text {
  width: 260px;
  margin: auto;
  padding: 15px;
}

.app-staff-page-content-section-2 > div {
  max-width: fit-content;
  margin: auto;
  padding: 10px 0;
}

.app-staff-page-content-section-2-text-1 {
  font-size: 25px;
  font-weight: 600;
}

.app-staff-page-content-section-2-text-3 {
  margin-top: -10px;
  background-color: #4c3cce;
  color: #ffff;
  padding: 5px 40px !important;
  border-radius: 5px;
  cursor: pointer;
}

/* --------------------------------------------------------- */

.app-staff-page-data {
  padding: 20px;
}
.app-staff-page-data-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-staff-page-data-top-date {
  font-size: 16px;
  font-weight: 500;
}
.app-staff-page-data-top-option {
  display: flex;
  align-items: center;
  border: solid 1px #d9d4d4;
  border-radius: 5px;
}
.app-staff-page-data-top-option i {
  padding: 5px 10px;
  cursor: pointer;
}
.app-staff-page-data-top-option input {
  border: none;
  outline: none;
}
.app-staff-page-data-top-option span i {
  color: #d9d4d4;
  cursor: no-drop;
}
.app-staff-page-data-top-option-month {
  width: 100px;
  text-align: center;
}

.app-staff-page-data-table {
  border: solid 1px #d9d4d4;
  margin: 10px 0;
  border-radius: 5px;
}
.app-staff-page-data-table-top {
  display: flex;
  gap: 60px;
  padding: 10px;
}

.app-staff-page-data-table-head,
.app-staff-page-data-table-body-data,
.app-staff-page-data-table-end {
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; */
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-bottom: solid 1px #d9d4d4;
}

.app-staff-page-data-table-head > div,
.app-staff-page-data-table-body-data > div,
.app-staff-page-data-table-end > div {
  padding: 7px 10px;
}
.app-staff-page-data-table-body-data:hover {
  /* cursor: pointer; */
  background-color: #f5f5f5;
}

.app-staff-page-data-table-empty {
  text-align: center;
  font-weight: 18px;
  font-weight: 600;
  padding: 50px 10px;
}
.app-staff-page-data-table-head {
  border-top: solid 1px #d9d4d4;
  background-color: #f0effd;
  color: #687e96;
  font-weight: 600;
}
.app-staff-page-data-table-end {
  border-top: solid 1px #d9d4d4;
  font-weight: 600;
  border-bottom: none;
}
.app-staff-page-data-table-body {
  height: calc(100vh - 245px);
  overflow: auto;
}
.app-staff-page-data-table-body::-webkit-scrollbar {
  width: 8px;
}
.app-staff-page-data-table-body::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 10px;
}

/* ---------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------- */

.view-staff-main {
  font-size: 14px;
}
.view-staff-filter-head {
  padding: 0 10px 15px;
  border: none;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #d9d4d4;
}

.view-staff-filter-head > div {
  border: solid 1px #4c3cce;
  color: #4c3cce;
  font-size: 12px;
  padding: 5px 20px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.view-staff-filter-data {
  height: calc(100vh - 85px);
  overflow: auto;
}
.view-staff-filter-data::-webkit-scrollbar {
  width: 7px;
}
.view-staff-filter-data::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 10px;
}

.view-staff-filter-data > div {
  border-bottom: solid 1px #d9d4d4;
  padding: 15px;
  font-size: 14px;
  cursor: pointer;
}
.view-staff-filter-data-list-active {
  background-color: #f5f3ff;
}
.view-staff-filter-data-value {
  margin-top: 2px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
}

.view-staff-col-data {
  flex: 1;
}

.view-staff-top {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #d9d4d4;
}

.view-staff-top-heading {
  display: flex;
  align-items: center;
   gap: 10px;
  font-size: 20px;
  font-weight: 500;
  font-size: 18px;
}

.view-staff-top-heading i {
  cursor: pointer;
}

.view-staff-top-buttons {
  display: flex;
  gap: 10px;
}
.view-staff-top-buttons > div {
  border: solid 1px #d9d4d4;
  border-radius: 5px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
}
.view-staff-top-payment {
  background-color: #4c3cce;
  background-color: #174555;
  color: #fff;
  border-color: #4c3cce !important;
  border-color: #174555 !important;
  font-weight: 600;
}

.view-staff-top-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #d9d4d4;
}
.view-staff-options {
  display: flex;
}
.view-staff-options > div {
  padding: 12px;
  width: 120px;
  text-align: center;
  border-right: solid 1px #d9d4d4;
  cursor: pointer;
}
.view-staff-option-active {
  border-bottom: solid 2px #4c3cce;
  background-color: #f5f3ff;
}
.view-staff-top-options-buttons {
  display: flex;
  gap: 10px;
  margin-right: 20px;
}
.view-staff-top-options-buttons > div {
  border: solid 1px #d9d4d4;
  padding: 5px 30px;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
}
.view-staff-top-options-buttons > div:last-child {
  color: #d01717;
  font-weight: 500;
  border-color: #d01717;
}
.view-staff-attendance-table-head,
.view-staff-attendance-table-data-body {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: solid 1px #d9d4d4;
}

.view-staff-attendance-table-data-body:hover {
  background-color: #f5f5f5;
}
.view-staff-attendance-table-data {
  max-height: calc(100vh - 300px);
  overflow: auto;
}

.view-staff-attendance-table-data::-webkit-scrollbar {
  width: 8px;
}

.view-staff-attendance-table-data::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 10px;
}

.view-staff-attendance-table-head {
  border-top: solid 1px #d9d4d4;
  color: #687e96;
  background-color: #f5f3ff;
  font-weight: 600;
}
.view-staff-attendance-table-data-body > div:last-child,
.view-staff-attendance-table-head > div:last-child {
  min-width: 220px;
}

/* ---------------------------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------- */

.app-staff-attendance-options {
  display: flex;
  gap: 10px;
}
.app-staff-attendance-options > div {
  border: solid 1px #d9d4d4;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  text-align: center;
  align-content: center;
  background-color: #f8f9fc;
  color: #687e96;
  font-weight: 500;
  cursor: pointer;
}

.app-staff-attendance-type {
  position: relative;
  background-color: transparent !important;
  border: none !important;
  width: 0px !important;
  margin: -4px;
}
.app-staff-attendance-type-options {
  position: absolute;
  /* position: relative; */
  text-align: start;
  background-color: white;
  margin-top: 5px;
  border: solid 1px #d9d4d4;
  border-radius: 5px;
  width: 110px;
}
.app-staff-attendance-type-options > div {
  padding: 10px;
  border-bottom: solid 1px #d9d4d4;
  color: #28313b;
  font-weight: 400;
}
.app-staff-attendance-type-options > div:hover {
  background-color: #f5f5f5;
}

.app-staff-attendance-type-options > div:last-child {
  border: none;
}

.app-staff-attendance-present {
  /* color: #0ebe2a !important;
  background-color: #ebf7ed !important;
  border-color: #0ebe2a !important; */

  color: #fff !important;
  background-color: #0ebe2a !important;
  border-color: #0ebe2a !important;
}

.app-staff-attendance-absent {
  /* color: #d01717 !important;
  background-color: #ffe8e8 !important;
  border-color: #d01717 !important; */
  color: #fff !important;
  background-color: #d01717 !important;
  border-color: #d01717 !important;
}

.app-staff-attendance-paid-leave {
  /* color: #488dff !important;
  background-color: #ddeaff !important;
  border-color: #488dff !important; */
  color: #fff !important;
  background-color: #488dff !important;
  border-color: #488dff !important;
}

.app-staff-attendance-week-off {
  /* color: #28313b !important;
  background-color: #f2f2f2 !important;
  border-color: #28313b !important; */

  color: #fff !important;
  background-color: #28313b !important;
  border-color: #28313b !important;
}

.app-staff-attendance-half-day {
  /* color: #e9c715 !important;
  background-color: #fff8e6 !important;
  border-color: #e9c715 !important; */
  color: #fff !important;
  background-color: #e9c715 !important;
  border-color: #e9c715 !important;
}

/* data ---------------------- */

.app-staff-page-details-data-row-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 30px 20px;
  gap: 30px;
}
.app-staff-page-details-data-col-heading {
  color: #687e96;
  font-weight: 500;
  margin-bottom: 5px;
}

/* ---------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------- */

.app-staff-page-payroll-heading {
  color: #687e96;
  background-color: #f8f9fc;
  padding: 10px;
  font-size: 15px;
}
.app-staff-page-payroll-heading > b {
  font-weight: 600;
}

.app-staff-page-payroll-table-head,
.app-staff-page-payroll-table-data-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 30px;
  border-top: solid 1px #d9d4d4;
}

.app-staff-page-payroll-table-head {
  padding: 8px 20px !important;
  font-weight: 600;
}

/* ---------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------- */

.app-staff-page-details-data-row-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 30px 20px;
  gap: 30px;
}

/* ---------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------- */

.app-staff-page-transaction-data {
  padding: 0 20px 20px;
}

/* ---------------------------------------------------------------------------------- */

.create-staff-amount-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.create-staff-amount-heading > span {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
}
.create-staff-amount-heading > span > i {
  font-size: 15px;
}

.create-staff-amount-heading-active {
  color: #488dff;
  font-weight: 500;
}
.create-staff-pending-amount {
  color: #d01717;
}

.create-staff-pending-amount > b {
  font-size: 500;
}
/* ---------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------- */

.app-staff-small-page-transaction-data,
.app-staff-page-details-buttons{
  display: none;
}


@media only screen and (max-width: 1000px) {
  .app-staff-page-content-section-1 {
    display: flex;
    overflow: auto;
    padding: 70px 15px 20px;
  }
  .app-staff-page-content-section-1-col-img {
    height: 150px;
  }
  .app-staff-page-content-section-1-col-text {
    width: 220px;
    padding: 10px;
  }

  .app-staff-page-heading {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 17px;
    font-weight: 500;
  }

  .app-staff-page-content-section-2 > div {
    text-align: center;
  }

  .app-staff-page-content-section-2-text-1 {
    font-size: 18px;
  }

  .app-staff-page-data {
    padding: 60px 15px 10px;
  }
  .app-staff-page-data-table-top-heading > span {
    display: none;
  }

  .app-staff-page-data-table-top {
    gap: 15px;
    justify-content: space-between;
    text-align: center;
  }

  .app-staff-page-data-table-head > :nth-child(2),
  .app-staff-page-data-table-body-data > :nth-child(2) {
    display: none;
  }

  .app-staff-page-data-table-head,
  .app-staff-page-data-table-body-data,
  .app-staff-page-data-table-end {
    grid-template-columns: 1fr 1fr;
  }

  .view-staff-options > div {
    width: max-content;
    padding: 8px 10px;
  }

  .view-staff-attendance-table-head,
  .view-staff-attendance-table-data-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 8px 10px;
  }

  .view-staff-attendance-table-data-body > div:last-child,
  .view-staff-attendance-table-head > div:last-child {
    min-width: max-content;
  }

  .app-staff-attendance-type-options{
    width: max-content;
  }

  .app-staff-attendance-type-options > div{
    padding: 5px;
  }
  .view-staff-col-data .app-staff-page-data{
    padding: 10px 15px 10px;
  }
  .view-staff-attendance-table-data{
    max-height: none;
    height: calc(100vh - 260px);
    padding-bottom: 75px;
  }

  .view-staff-top-options-buttons{
    display: none;
  }
  
  .app-staff-page-details-data-row-3{
    grid-template-columns: 1fr 1fr;
    padding: 20px;
  }

  .app-staff-page-details-buttons{
    display: flex;
    padding: 15px 20px;
    gap: 20px;
    align-items: center;
  }

  .app-staff-page-details-buttons>div{
    border: solid 1px #4c3cce;
    padding: 5px 30px;
    border-radius: 5px;
    font-size: 13px;
    cursor: pointer;
    font-weight: 500;
    color: #fff;
    background-color: #4c3cce;
  }

  .app-staff-page-details-buttons>div:nth-child(2){
    background-color: #d01717;
    border-color: #d01717;
  }
  
  .app-staff-page-payroll-table-data-body{
    padding: 8px 20px;
  }
  .app-staff-page-payroll-table-head{
    padding: 8px 10px !important;

  }
  
  .app-staff-page-transaction-data{
    display: none;
  }

  .app-staff-small-page-transaction-data{
    display: block;
    padding: 10px 15px 90px;
    height: calc(100vh - 100px);
    overflow: auto;
  }
  
  
}
