
.header-numbers {
    padding: 40px 0;
}
.header-numbers>div {
    margin: auto;
    max-width: 1270px;
    width: calc(100% - 40px);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 50px;
  }

  .header-numbers-col {
    text-align: center;
    box-shadow: 4px 4px 6px 0 #ffffff80,-4px -4px 6px 0 #ffffff36,inset -4px -4px 6px 0 #3e3e3e33,inset 4px 4px 6px 2px hsla(0,0%,43%,.201);
    border-radius: 30px;
    padding: 30px;
  }
  .header-numbers-col:hover {
    cursor: pointer;
  background-color: #e9faff;
  }

  .header-numbers-col-inc {
    font-size: 1.7rem;
    font-weight: 700;
    /* color: rgb(104, 49, 49); */
  }
  .header-numbers-col-inc > span {
    color: #174555;
    font-size: 2rem;
  }
  .header-numbers-col-txt {
    font-size: 1.1rem;
    font-weight: 400;
    color: rgb(50, 50, 50);
  }
  
@media only screen and (max-width: 700px) {
    
  .header-numbers {
    padding: 20px 5px 30px;
  }
  .header-numbers>div {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 10px;
  }
  .header-numbers-col-inc {
    font-size: 1rem;
  }

  .header-numbers-col-inc span {
    font-size: 1.2rem;
  }

  .header-numbers-col-txt {
    font-size: 0.78rem;
    line-height: 1.4;
  }
}
