
.our-benefit-main{
    width: 80%;
    margin: auto;
}

.our-benefit-heading{
    font-size: 2.2rem;
    text-align: center;
    font-weight: 700;
    color: #314259;
    margin-bottom: 40px;
}

.our-benefit-content{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.our-benefit-content-col-1{
    text-align: end;
}
.our-benefit-content-col-row{
    display: flex;
    gap: 10px;
    margin-bottom: 50px;
}

.our-benefit-content-heading{
    font-size: 1.1rem;
    font-weight: 600;
}
    .our-benefit-content-text{
    color: #314259;
    font-size: .9rem;
    margin: 10px 0;
}

.our-benefit-content-button{
    color: var(--web-main);
    margin-top: 10px;
    cursor: pointer;
}


.our-benefit-content-col-2{
    width: 350px;
    margin: auto;
}
.our-benefit-content-col-2>img{
    width: 100%;
    padding: 0 20px;
}


@media only screen and (max-width: 1000px) {
    
    .our-benefit-main{
        width: 100%;
        padding: 0 20px;
    }

    .our-benefit-heading{
        font-size: 1.5rem;
        margin-bottom: 20px;
    }
    
.our-benefit-content{
    grid-template-columns: repeat(1, 1fr);
}

.our-benefit-content-col-1>
.our-benefit-content-col-row{
    flex-direction: row-reverse;
    text-align: start;
}
.our-benefit-content-col-2{
    order: -1;
}

.our-benefit-content-col-row{
    margin-bottom: 30px;
}
    
}

