.timer-popup-alert {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: white;
  background-color: var(--main);
  color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
    z-index: 5;
    
}

.timer-popup-alert-bar {
  background-color: var(--main);
  background: white;

  margin: 2px;
  height: 4px;
  transition: width 0.1s linear;
}

.timer-popup-alert-close {
  text-align: end;
  font-size: 1.6rem;
  color: var(--main);
  margin: -20px -10px;
}
.timer-popup-alert-close > i {
  background-color: white;
  border-radius: 50%;
}

.timer-popup-alert-text {
  padding: 10px 20px 15px;
  font-weight: 500;
  min-width: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.timer-popup-alert-text >i{
  font-size: 1.8rem;
  /* background-color: white; */
  border-radius: 50%;
  display: flex;
  align-items: center;

}

.timer-popup-alert-text >.fa-triangle-exclamation,
.timer-popup-alert-text >.fa-circle-exclamation{
  color: red;
}
.timer-popup-alert-text >.fa-circle-check{
  color: #25d366;
  /* border: solid 1px; */
}