.register-page{
    background-color: var(--bg-light);
    min-height: 100vh;
    /* padding: 10px; */
}

.register-page-main{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.register-page-content{
    background-color: var(--white);
    padding: 40px;
    margin: 10px;
    width: 450px;

}

.register-page-head-logo>img{
    width: 200px;
}

.register-page-head-heading-1{
    font-size: 1.2rem;
    font-weight: 600;
}

.register-page-head-error{
    color: red;
    margin: -10px 0;
    font-size: 1rem;
    font-weight: 500;
}

.register-page-form > form{
    display: grid;
    gap: 20px;
    margin: 20px 0;
}
.register-page-form > form >input{
    border: solid 1px #cecece;
    padding: 10px;
    font-size: 1rem;
}
/* .register-page-form > form > a{
    text-align: end;
} */

.register-page-form > form > button{
    background-color: var(--main);
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.register-page-form-end{
    display: flex;
    justify-content: space-between;
}

