
.app-cash-bank-top-button-1{
    display: flex;
    align-items: center;
    gap: 5px;
}

.app-cash-bank-top-button-1 i{
    color: #687e96 !important;
}
.create-expense-page-main-top-button-save,
.app-expense-page-top-button-create,
.app-staff-page-button-add,
.app-cash-bank-top-button-2{
    background-color: #4c3cce;
    background-color: #174555;
    color: white !important;
    border-color: #4c3cce !important;
    border-color: #174555 !important;
    font-weight: 500;
}

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::; */

.app-cash-bank-content{
    display: grid;
    grid-template-columns: 2fr 5fr;
}

.app-cash-bank-content-menu{
    border-right: solid 1px #d9d4d4;
    height: calc(100vh - 67px);
    overflow: auto;
}

.app-cash-bank-content-menu::-webkit-scrollbar{
    width: 8px;
}

.app-cash-bank-content-menu::-webkit-scrollbar-thumb{
    background-color: #999999;
    border-radius: 10px;
}

.app-cash-bank-content-menu-col>div{
    display: flex;
    justify-content: space-between;
    padding: 25px 10px;
    border-bottom: solid 1px #d9d4d4;
    font-size: 16px;
    cursor: pointer;
}
.app-cash-bank-content-menu-col-active{
    background-color: #eeecfb;
}
.app-cash-bank-content-menu-col>div:last-child{
    font-weight: 500;
}

.app-cash-bank-content-menu-heading{
    padding: 10px;
    border-bottom: solid 1px #d9d4d4;
    background-color: #fbfbfd;
    color: #687e96;
    font-weight: 600;
}


/* ====================================================================== */

.app-cash-bank-content-heading{
    background-color: #eeecfb;
    color: #4c3cce;
    width: max-content;
    padding: 15px 20px;
    font-weight: 600;
    border-right: solid 1px #d9d4d4;
}

.app-cash-bank-content-options{
    border-top: solid 1px #d9d4d4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}
.app-cash-bank-content-options>div{
    border: solid 1px #d9d4d4;
    padding: 5px 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: 14px;
}
.app-cash-bank-content-options-filter select{
    border: none;
    outline: none;
}

.app-cash-bank-content-table{
    margin: 5px 15px;
    border: solid 1px #d9d4d4;
}
.app-cash-bank-content-table-end,
.app-cash-bank-content-table-head,
.app-cash-bank-content-table-body{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    font-size: 14px;
    gap: 2px;
    padding: 8px 10px;
}
.app-cash-bank-content-table-head{

    border-bottom: solid 1px #d9d4d4;
}

.app-cash-bank-content-table-body-hover:hover{
    cursor: pointer;
    background-color: #f3f3f3;
}

.app-cash-bank-content-table-end,
.app-cash-bank-content-table-head{
    background-color: #eeecfb;
    color: #687e96;
    font-weight: 600;
    text-transform: uppercase;
}

.app-cash-bank-content-table-data{
    max-height: calc(100vh - 280px);
    overflow: auto;
}
.app-cash-bank-content-table-data-bank{
    max-height: calc(100vh - 440px) ;
    overflow: auto;
}

.app-cash-bank-content-table-data::-webkit-scrollbar{
    width: 6px;
}
.app-cash-bank-content-table-data::-webkit-scrollbar-thumb{
    background-color: #999999;
    border-radius: 10px;
}

.app-cash-bank-content-table-body{
    border-bottom: solid 1px #d9d4d4;
    text-transform: capitalize;
}
.app-cash-bank-content-table-end{
    border-top: solid 1px #d9d4d4;;
    background-color: #ffff ;
}


.app-cash-bank-content-table-empty{
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    padding: 40px 0;

}
    

/* ------------------------------------------ */

.app-cash-bank-content-bank-data{
    border-top: solid 1px #d9d4d4;   
    display: flex;
    justify-content: space-between;
    padding: 15px;
}

.app-cash-bank-content-bank-details-col{
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    margin-bottom: 2px;
    font-size: 14px;
}

.app-cash-bank-content-bank-details-col>div{
    color: #687e96;
}


.app-cash-bank-content-bank-buttons>div{
    border: solid 1px #d9d4d4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-size: 13px;
    padding: 5px 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    cursor: pointer;
}





.app-cash-bank-button-add-account{
    display: flex;
    align-items: center;
    padding: 0 !important;
    background-color: #4c3cce;
    background-color: #174555;
    color: #fff !important;
    font-weight: 500;
    border-color: #4c3cce !important;
    border-color: #174555 !important;
}

.app-cash-bank-button-add-account>div:first-child{
    padding: 5px 20px;
}
.app-cash-bank-button-add-account .fa-angle-down{
    padding: 0 10px;
}
.app-cash-bank-button-add-account-drop{
    display: none;
}

.app-cash-bank-button-add-account-drop-list{
    display: none;
    position: absolute;
    color: #000;
    /* background-color: #999999; */
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    margin: 1px -105px;
}
.app-cash-bank-button-add-account-drop-list>div{
    padding: 10px 10px 5px;
}
.app-cash-bank-button-add-account-drop-list>div:hover{
    background-color: #9996;
}

.app-cash-bank-button-add-account-drop:hover
.app-cash-bank-button-add-account-drop-list{
    display: block;
}
.app-cash-bank-content-small-bank-details,
.app-cash-bank-content-small-total,
.app-cash-bank-content-small-head,
.app-cash-bank-content-small-top,
.app-cash-bank-content-small-data{
    display: none;
}

@media only screen and (max-width: 1000px) {
    .app-cash-bank-main{
        height: 100%;
    }
.app-cash-bank-top{
    z-index: 1;
}
    .app-cash-bank-top-heading{
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 18px;
    }

    .app-cash-bank-top-button-1{
        display: none;
    }
    
.app-cash-bank-button-add-account-drop{
    display: block;
}


.app-cash-bank-button-add-account>div:first-child{
    padding: 5px 10px;
    
    border-right: 1px solid #fff ;
}

.app-cash-bank-content{
    display: flex;
}

.app-cash-bank-content-menu{
    display: none;
}
.app-cash-bank-content-data{
    display: none;
}


.app-cash-bank-content-main{
    width: 100% !important;
    /* height: 100vh; */
    height: 100%;
    overflow: auto;
    padding: 47px 0 0;
    background-color: #fff;
}

.app-cash-bank-content-small{
    display: block;
}

.app-cash-bank-content-small-total,
.app-cash-bank-content-small-top,
.app-cash-bank-content-small-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}
.app-cash-bank-content-small-top{
    padding: 10px 20px;
    border-bottom: 1px solid #d9d4d4;
    background-color: #ebebeb;
    position: sticky;
    top: 0px;
}
.app-cash-bank-content-small-total{
    /* background-color: #dfe8eb; */
    font-weight: 600;
    font-size: 17px;
}
.app-cash-bank-content-small-total>span{
}

.app-cash-bank-content-small-top-name{
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 2px 10px;
    border-radius: 5px;
}
.app-cash-bank-content-small-top-name>select{
    appearance: none;
    background-color: transparent;
    color: #000;
    border: none;
    outline: none;
    font-size: 15px;
}
.app-cash-bank-content-small-head-heading,
.app-cash-bank-content-small-top-amount{
    font-weight: 600;
    font-size: 16px;
}
.app-cash-bank-content-small-top-amount>div,
.app-cash-bank-content-small-top-amount>span{
    color: #488dff ;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 2px;
}
.app-cash-bank-content-small-top-amount>div{
    color: #d01717;
}

.app-cash-bank-content-small-head-filter{
    display: flex;
    align-items: center;
    gap: 5px;
    border: solid 1px #d9d4d4;
    padding: 5px;
    border-radius: 5px;
}
.app-cash-bank-content-small-head-filter select{
    appearance: none;
    color: #000;
    background-color: #fff;
    border: none;
    outline: none;
}

.app-cash-bank-content-small-data{
    /* padding: 5px 15px 90px; */
    padding: 5px 15px ;
    /* max-height: calc(100vh - 140px); */
    overflow: auto;
    display: block;
}

.app-cash-bank-content-small-data-list{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    padding: 12px;
    margin-bottom: 20px;

}

.app-cash-bank-content-small-data-list-col-1-heading{
    font-weight: 600;
}
.app-cash-bank-content-small-data-list-col-1-heading>span{
    color: #888888;
}
.app-cash-bank-content-small-data-list-col-1-option{
    display: flex;
    align-items: center;
    gap: 10px;
    color: #555555;
    margin-top: 5px;
}
.app-cash-bank-content-small-data-list-col-1-option>span{
    color: #488dff;
    display: flex;
    align-items: center;
    font-weight: 600;
}

.app-cash-bank-content-small-data-list-row{
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 16px;
}
.app-cash-bank-content-small-data-list-row>div{
    color: #666;
    font-weight: 600;
    font-size: 14px;
}
.app-cash-bank-content-small-data-list-row>span{
    font-weight: 400;
    color: #000;
    font-size: 14px;
}

.app-cash-bank-content-small-data-list-col-2{
    text-align: end;
}
.app-cash-bank-content-small-data-list-balance{
    font-weight: 600;
}

.app-cash-bank-content-small-data-list-col-2-view{
    /* display: grid; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.app-cash-bank-content-small-data-list-col-2-botton{
    background-color: #4c3cce20;
    color: #4c3cce;
    padding: 2px 10px;
    border-radius: 5px;
    font-weight: 600;
    height: max-content;
}
.app-cash-bank-content-small-data-list-col-2-option{
    color: #d01717;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* align-items: end; */
    
    height: max-content;
}

.app-cash-bank-content-small-bank-details{
    display: block;
    padding: 10px 20px;
    border-bottom: 1px solid #d9d4d4;
}


}


