.why-invoician-component {
  margin: 100px 0;
}

.why-invoician-component-main {
  max-width: 1100px;
  margin: auto;
}

.why-invoician-component-heading {
  text-align: center;
  font-size: 2.1rem;
  font-weight: 600;
  margin-bottom: 40px;
}

.why-invoician-component-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px 10px;
}

.why-invoician-component-point {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
}
.why-invoician-component-point > i {
  color: #4bae4f;
  font-size: 1.5rem;
}

@media only screen and (max-width: 1000px) {
    
    .why-invoician-component{
        margin: 50px 0;
        padding: 0 20px;
    }

    .why-invoician-component-heading {
      margin-bottom: 20px;
      font-size: 1.6rem;
    }
    
    .why-invoician-component-content {
      grid-template-columns: repeat(1, 1fr);
    }

.why-invoician-component-point{
    gap: 8px;
    font-size: .9rem;
}
.why-invoician-component-point > i {
    font-size: 1.2rem;
}






  
}
