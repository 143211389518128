

.thermal-invoice{
    background-color: #dfdfdf;
    padding: 30px;
    font-size: 11px;
    /* font-family: "Oswald", serif; */
    /* font-weight: 300; */
    /* font-family: "PT Sans", serif; */
    font-family: serif;
    font-family: "Source Sans 3", sans-serif;
    /* font-family: "Dosis", serif; */
}

.thermal-invoice-main{
    background-color: #fff;
    width: 3in;
    margin: auto;
    padding: 30px 10px;
}


.thermal-invoice-header-logo{
    max-width: 40%;
    margin: auto;
    display: flex;
    justify-content: center;
}
.thermal-invoice-header-logo>img{
    /* max-width: 100%; */
    max-height: 80px;
    filter: grayscale(1);
}

.thermal-invoice-header-company-name{
    text-align: center;
    font-size: 15px;
    font-weight: 600;
}
.thermal-invoice-header-address{
    text-align: center;
}

.thermal-invoice-header-info{
    display: flex;
    gap: 1px 10px;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.thermal-invoice-header-info-col{
    display: flex;
    gap: 5px;
}


.thermal-invoice-heading{
    border-top: #000 dashed 1px;
    border-bottom: #000 dashed 1px;
    margin: 10px 0;
    text-align: center;
    /* font-weight: 600; */
    padding: 2px;
    font-size: 12px;
}

.thermal-invoice-details tr>td:nth-child(2){
    padding-left: 3px;
}

.thermal-invoice-items-heading{
    display: flex;
    justify-content: space-between;
    border-top: #000 dashed 1px;
    border-bottom: #000 dashed 1px;
    margin: 10px 0 0;
    padding: 3px;
}
.thermal-invoice-items > table{
    width: 100%;
    border-collapse: collapse;
    text-align: right;
}

.thermal-invoice-items > table>tr>td:first-child{
    text-align: left;
}

.thermal-invoice-items > table> tr:nth-child(1)>td{
    font-weight: 600;
    border-bottom: #000 solid 1px;
    padding: 5px 0 2px;
}

.thermal-invoice-items > table> tr>td{
    padding-left: 4px;
}
.thermal-invoice-items > table> tr>td:first-child{
    padding-left: 0px;
}

.thermal-invoice-items-end{
    border-top: #000 solid 1px;

}
.thermal-invoice-items-end-col{
    display: flex;
    justify-content: space-between;
}


