/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.app-small-navbar{
  display: none;
  
}

@media only screen and (max-width: 1000px) {
  .app-small-navbar{
    display: block;
  }

  .app-small-navbar-bars{
    font-size: 18px;
  }
  
.app-small-navbar-main{
  position: fixed;
  background-color: #0006;
  width: 100%;
  transition: left ease .3s;
  top: 0;
  left: -100%;
  overflow: auto;
  z-index: 2;
  font-size: 15px;
  /* height: max-content; */
  /* min-height: 100vh; */
  height: 100%;
}
.app-small-navbar-main-open{
  left: 0;
}
.app-navbar-menu-main{
  height: calc(100% - 154px);
  overflow: auto;

}
.app-small-navbar-menu{
  width: 80%;
  background-color: #ffffff;
  height: 100%;
  /* padding: 1px 0 ; */
  overflow: auto;
}

.app-navbar-setting{
  border-radius: 0;
}


/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */


}



/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
