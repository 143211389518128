.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  
}

.nav-drop,
.navbar a {
  text-decoration: none;
  color: #464646;
}

.nav-logo img {
  display: flex;
  width: 160px;
  align-items: center;
}

.nav-btn {
  display: none;
}

.nav-menu {
  display: flex;
  align-items: center;
  gap: 40px;
}

.nav-login-button {
  font-weight: 600;
  padding: 5px 30px;
  /* background-color: white;
  color: var(--main) !important; */
  border-radius: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* background-color: var(--web-main); */
  background-color: var(--main);
  color: #fff !important;
}

/* drop down ----------------------- */

.nav-drop span:hover {
  cursor: grab;
}

.nav-drop-content {
  display: none;
  position: absolute;
  padding: 10px 15px;
  width: auto;
  box-shadow: rgba(0, 0, 0, 0.205) 0px 2px 6px 0px;
  background-color: white;
  border-radius: 10px;
  z-index: 1;
}

.nav-drop:hover .nav-drop-content {
  display: block;
}

.nav-d-c-link {
  display: grid;
  gap: 10px;
  margin-right: 0;
}

/* drop down ----------------------- */

.nav-login-button-small {
  display: none;
}

@media only screen and (max-width: 600px) {
  /* ----------- navbar ---------------- */

  .nav-login-button {
    display: none;
  }
  .nav-login-button-small {
    display: block;
  }
  .nav-logo img {
    width: 120px;
    display: block;
  }

  .menu-btn {
    display: block;
    /* margin-right: 10px; */
    border: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: var(--main);
  }

  .navbar {
    height: auto;
    align-items: center;
    padding: 5px 10px;
  }

  .navbar .nav-menu {
    /* margin: 0px -10px; */
    padding: 0;
    position: fixed;
    bottom: 100vh;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    transition: 1s;
    left: 0;
  }

  .navbar .responsive_nav {
    transform: translateY(100vh);
    background-color: #ffe6e8;
    z-index: 2;
  }

  .nav-close-btn {
    display: block;
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 2rem;
    padding: 0;
    border: none;
    background-color: transparent;
    color: black;
  }
}
