.view-invoice-data-main {
  position: fixed;
  width: calc(100% - 290px);
  background-color: white;
  border-radius: 10px;
  height: calc(100vh - 20px);
  margin-bottom: 20px;
  z-index: 1;
}

.view-invoice-data-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #bbb;
}

.view-invoice-data-top-heading {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: 600;
}
.view-invoice-data-top-heading i {
  cursor: pointer;
}

.view-invoice-data-top-options {
  display: flex;
  align-items: center;
  gap: 10px;
}

.view-invoice-data-top-button-share-off,
.view-invoice-data-top-button-share,
.view-invoice-data-top-button-main,
.view-invoice-data-top-button-alert,
.view-invoice-data-top-button-2 {
  border: solid 2px;
  border-radius: 5px;
  padding: 4px 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  cursor: pointer;
}

.view-invoice-data-top-button-2 {
  border-color: #174555;
  color: #174555;
}

.view-invoice-data-top-button-download{
  color: var(--main);
  border: solid 2px var(--main);
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.view-invoice-data-top-button-download>div{
  padding: 4px 10px;
}
.view-invoice-data-top-button-download-options{
  display: none;
}

.view-invoice-data-top-button-download>span{
  padding: 4px 10px;
  background-color: var(--main);
  color: #fff;
}
.view-invoice-data-top-button-download>span:hover

.view-invoice-data-top-button-download-options{
  display: block;
  position: absolute;
  background-color: #fff;
  color: #000;
  margin-left: -10px;
  border: solid 1px #dadada;
}
.view-invoice-data-top-button-download-options>div{
  padding: 5px 20px;
}
.view-invoice-data-top-button-download-options>div:hover{
  background-color: #dadada;
}


.view-invoice-data-top-button-alert {
  border-color: #d01717;
  background-color: #d01717;
  color: #fff;
}

.view-invoice-data-top-button-main {
  border-color: #174555;
  background-color: #174555;
  color: #fff;
}

.view-invoice-data-top-button-share {
  border-color: #555;
  color: #555;
}

.view-invoice-data-top-button-share-off {
  border-color: #999;
  color: #999;
}

.view-invoice-data-top-button-share-drop-list {
  position: absolute;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  margin-top: 155px;
  margin-left: -10px;
  display: none;
}

.view-invoice-data-top-button-share:hover
  .view-invoice-data-top-button-share-drop-list {
  display: grid;
}

.view-invoice-data-top-button-share-drop-list > a {
  padding: 5px 15px;
  text-decoration: none;
  font-weight: 600;
}
.view-invoice-data-top-button-share-wa-small,
.view-invoice-data-top-button-share-wa{
  color: #2cc646;
}
.view-invoice-data-top-button-share-wa-small{display: none;}
.view-invoice-data-top-button-share-copy{
  color: #488dff;
}
.view-invoice-data-top-button-share-del{
  color: #d01717;
}

.view-invoice-data-top-button-share-copy-drop-list > a:hover {
  background-color: #2221;
}

.view-invoice-data-content {
  height: calc(100vh - 100px);
  padding: 0px 20px 0;
  width: 100%;
  overflow: auto;
}
.view-invoice-data-content-main {
  /* border-radius: 10px; */
  padding: 20px;
  background-color: var(--bg-light);
}

.view-invoice-data-content-main > div {
  margin: auto;
  width: 800px;
  background-color: #fff;
}

@media only screen and (max-width: 1000px) {
  .view-invoice-data-main {
    width: 100%;
    height: 100%;
    overflow: auto;
    top: 0;
  }

  .view-invoice-data-top-options {
    gap: 5px;
  }

  .view-invoice-data-top {
    padding: 10px;
  }

  .view-invoice-data-top-heading {
    gap: 5px;
    font-size: 18px;
  }

  .view-invoice-data-top-button-share-off > span,
  .view-invoice-data-top-button-share > span,
  .view-invoice-data-top-button-main > span,
  .view-invoice-data-top-button-alert > span,
  .view-invoice-data-top-button-2 > span {
    display: none;
  }

  .view-invoice-data-top-button-share-off,
  .view-invoice-data-top-button-share,
  .view-invoice-data-top-button-main,
  .view-invoice-data-top-button-alert,
  .view-invoice-data-top-button-2 {
    padding: 4px 8px;
  }

  .view-invoice-data-content {
    width: 100%;
    overflow: auto;
    padding: 0;
    height: 100%;
  }
  .view-invoice-data-content-main {
    overflow: auto;
    padding-bottom: 60px;
  }

.view-invoice-data-top-button-share-wa{
  display: none;
}
.view-invoice-data-top-button-share-wa-small{
  display: block;
}

.view-invoice-data-top-button-download>div:first-child>span{
  display: none;
}

.view-invoice-data-top-button-download i{
  display: flex;
}

.view-invoice-data-top-button-download > span,
.view-invoice-data-top-button-download > div{
  padding: 4px 6px;
}
  
}
