a.user-welcome-page{
    /* padding: 50px; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.user-welcome-page-main-head{
    text-align: center;
}

.user-welcome-page-main-head-logo>img{
    width: 200px;
    margin-bottom: 30px;
}

.user-welcome-page-main-heading-1{
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    font-family: poppins;
}
.user-welcome-page-main-heading-2{
    font-family: poppins;
    font-size: 1.1rem;
}

.user-welcome-page-main-form>form{
    max-width: 350px;
    margin: 30px auto;
    display: grid;
    gap: 20px;
}
.user-welcome-page-main-form-row{
    display: grid;
    /* margin: 30px 0; */
    gap: 5px;
    font-weight: 600;
}
.user-welcome-page-main-form-row>input{
    border: solid 1px #b8b8b8;
    padding: 10px;
    font-size: 1rem;
}
.user-welcome-page-main-form>form>button{
    background-color: var(--main);
    color: white;
    border: none;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
    /* width: 200px; */
    /* margin: auto; */
    font-size: 1.1rem;
}

/* --------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------- */

.welcome-page{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: white;
}

.welcome-page-main{
    width: 500px;
}

.welcome-page-content{
    max-height: calc(100vh - 80px);
    overflow: auto;
    padding: 10px;
}

.welcome-page-content::-webkit-scrollbar{
    width: 8px;
}

.welcome-page-content::-webkit-scrollbar-thumb{
    background-color: #999999;
    border-radius: 10px;
}

.welcome-page-top{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    
}
.welcome-page-top>div{
    border: solid 1px #d9d4d4;
    flex: 1;
}
.welcome-page-top-circle{
    width: 50px;
    height: 50px;
    max-width: 50px;
    max-height: 50px;
    border-radius: 50%;
}


.welcome-page-top-circle>i{
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: solid 1px #174555;
    color: #174555;
    background-color: #dee7eb;
    font-size: 20px;


}
.welcome-page-heading{
    font-size: 30px;
    font-weight: 600;
    line-height: 1.2;
    margin: 20px 0;
}

.welcome-page-col{
    display: grid;
    margin-bottom: 20px;
}
.welcome-page-col .setting-business-page-form-col-dropdown-select{
padding: 20px 5px 20px 15px;
}

.welcome-page-col label{
    font-weight: 500;
    margin-bottom: 8px;
}
.welcome-page-col label span{
    color: #c11111;
    font-weight: 600;
}

.welcome-page-col select,
.welcome-page-col input{
    border: solid 1px #d9d4d4;
    outline: none;
    padding: 10px;
    border-radius: 5px;
}


/* =-------------------------------- */

.welcome-page-button{
    width: 300px;
    margin: 10px auto;
}
.welcome-page-button>button{
    border-radius: 5px;
    font-size: 18px;
    color: white;
    padding: 10px;
    width: 100%;
    background-color: #174555;
    border: none;
    cursor: pointer;
}

.welcome-page-col-radio{
    border: solid 1px #d9d4d4;
    border-radius: 20px;
}
.welcome-page-col-radio-col{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: solid 1px #d9d4d4;
}
.welcome-page-col-radio-col input{
    height: 18px;
    width:  18px;
}

.welcome-page-col-radio>div:last-child{
    border: none;
}


.welcome-page-col-input{
    border: solid 1px #d9d4d4;
    outline: none;
    padding: 10px;
    border-radius: 5px;
    min-height: 40px;
cursor: pointer;
}



/* ------------------------------------------------------------------------------------------------------------------ */
/* address popup ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------ */

.welcome-page-address-popup{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #0005;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.welcome-page-address-popup-main{
    background-color: #fff;
    border-radius: 10px;
    width: 500px;
}

.welcome-page-address-popup-head{
    display: flex;  
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    padding: 10px;
    background-color: #9995;
    font-size: 18px;
    font-weight: 500;
}
.welcome-page-address-popup-head i{
    cursor: pointer;
    font-size: 20px;
}



.welcome-page-address-popup-form input,
.welcome-page-address-popup-form textarea{
    border: solid 1px #d9d4d4;
    outline: none;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 20px;
    /* width: max-content; */
}

.welcome-page-address-popup-form{
    padding: 20px;
}

.welcome-page-address-popup-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.welcome-page-address-popup-label>span{
    color: #c11111;
    font-weight: 700;
    font-size: 20px;
}


.welcome-page-address-popup-buttons{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
}

.welcome-page-address-popup-buttons>button,
.welcome-page-address-popup-buttons>div{
    font-size: 15px;
    border: solid 1px #0005;
    padding: 4px 40px;
    border-radius: 5px;
    cursor: pointer;
}

.welcome-page-address-popup-buttons>button{
    background-color: var(--main);
    color: #fff;
    border-color: var(--main);
}

@media only screen and (max-width: 600px) {
    
    
.welcome-page{
    position: relative;
    padding: 10px;
    align-items: normal;
}


.welcome-page-content{
    max-height: none;
    padding: 10px;
}

.welcome-page-heading{
    font-size: 20px;
    /* margin: 20px 0 30px; */
}


    
  }


