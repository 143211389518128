.app-page-top {
  background-color: white;
  display: flex;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.app-page-top-heading {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--main);
}

.app-page-top-col:nth-child(2) {
  display: flex;
  gap: 20px;
  align-items: center;
}

.app-page-top-search {
  background-color: white;
  display: flex;
  border: 1px solid #bbbbbb;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.2rem;
  width: 280px;
}

.app-page-top-search > input {
  border: none;
  font-size: 14px;
  width: 100%;
  /* max-width: 150px; */
}
.app-page-top-search > input:focus {
  outline: none;
}

.app-page-top-button-2,
.app-page-top-button {
  background-color: var(--main);
  color: white;
  font-weight: 500;
  padding: 5px 30px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 8px;
  text-decoration: none;
  border: solid var(--main) 2px;
}
.app-page-top-button-2 {
  background-color: transparent;
  padding: 2px 10px;
  color: var(--main);
  font-size: 1.2rem;
}

.app-page-top-button-sale {
  background-color: #dd0909;
  border-color: #dd0909;
}
.app-page-top-button-party {
  background-color: white;
  color: var(--main);
  font-weight: 600;
}

/* ======================================================================================================== */

.app-page-filter {
  display: grid;
  gap: 30px;
  margin: 20px 0;
}
.app-page-filter-2 {
  grid-template-columns: repeat(2, 1fr);
}
.app-page-filter-3 {
  grid-template-columns: repeat(3, 1fr);
}
.app-page-filter > a,
.app-page-filter > div {
  /* box-shadow: 4px 4px 6px 0 #ffffff80,-4px -4px 6px 0 #747d8836,inset -4px -4px 6px 0 #8a8a8a33,inset 4px 4px 6px 2px rgba(0,0,0,.201); */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: white;
  padding: 15px 20px;
  border-radius: 15px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  color: #000;
  text-decoration: none;
}
.app-page-filter > div:nth-child(2) {
  /* color: red; */
}

.app-page-filter-col-active {
  border: solid rgb(from var(--main) r g b / 65%);
  background-color: var(--main) !important;
  color: white;
}
.app-page-filter-col-active .app-page-filter-col-icon {
  background-color: white !important;
}
.app-page-filter-col-active .app-page-filter-col-text {
  color: white !important;
}
.app-page-filter-col-icon {
  font-size: 1.5rem;
  background: rgb(from var(--main) r g b / 15%);
  color: var(--main);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  text-align: center;
  align-content: center;
}
.app-page-filter-col-2 > .app-page-filter-col-icon {
  background: #00c24720;
  color: #00c247;
}
.app-page-filter-col-3 > .app-page-filter-col-icon {
  background: #dd090917;
  color: #e03535;
}

.app-page-filter-col-text {
  font-size: 1.5rem;
  display: grid;
  font-weight: 600;
}
.app-page-filter-col-text > span {
  font-size: 1.1rem;
  font-weight: 400;
  margin-top: -8px;
}
.app-page-filter-col-2 > div {
  color: #00c247;
}
.app-page-filter-col-3 > div {
  color: #dd0909;
}

/* ======================================================================================================== */

.app-page-table {
  border: solid var(--main) 2px;
  border-radius: 10px;
}

.app-page-table-party > .app-page-table-head,
.app-page-table-party > .app-page-table-body > .app-page-table-body-data {
  display: grid;
  grid-template-columns: 1.2fr 1fr 0.9fr 1fr 0.8fr;
  gap: 0px 2px;
}

.app-page-table-item > .app-page-table-head,
.app-page-table-item > .app-page-table-body > .app-page-table-body-data {
  display: grid;
  grid-template-columns: 1fr 0.6fr 0.6fr 0.6fr 0.6fr;
  gap: 0px 2px;
}

.app-page-table-manage-staff > .app-page-table-head,
.app-page-table-manage-staff
  > .app-page-table-body
  > .app-page-table-body-data {
  display: grid;
  grid-template-columns: 1.2fr 0.7fr 0.7fr 1fr;
  gap: 0px 2px;
}


.app-page-table-invoice > .app-page-table-head,
.app-page-table-invoice > .app-page-table-body > .app-page-table-body-data {
  display: grid;
  grid-template-columns: 1.5fr 1fr 0.7fr 1fr;
  grid-template-columns: 0.7fr 0.5fr 1.7fr 0.8fr 0.6fr;
  gap: 0px 2px;
}

.app-page-table-quotation > .app-page-table-head,
.app-page-table-quotation > .app-page-table-body > .app-page-table-body-data {
    display: grid;
    grid-template-columns: 0.7fr 0.5fr 1.7fr 0.8fr ;
    gap: 0px 2px;
}
.app-page-table-quotation > .app-page-table-body {
    max-height: calc(100vh - 147px);
}


.app-page-table > .app-page-table-body > .app-page-table-body-data:hover {
  background-color: rgb(from var(--main) r g b / 40%) !important;
  cursor: pointer;
}

.app-page-table-head > div:nth-child(1) {
  border-radius: 8px 0px 0 8px;
}
.app-page-table-head > div:last-child {
  border-radius: 0px 8px 8px 0;
}
.app-page-table-head > div {
  background-color: var(--main);
  color: white;
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: start;
  justify-content: center;
}
.app-page-table-body > .app-page-table-body-data > div {
  padding: 10px;
  border-left: solid rgb(from var(--main) r g b / 30%) 1px;
  border-top: solid rgb(from var(--main) r g b / 30%) 1px;
  align-content: center;
}
.app-page-table-body-data:nth-child(even) {
  background-color: rgb(from var(--main) r g b / 10%);
}

.app-page-table-body-data-partially,
.app-page-table-body-data-unpaid,
.app-page-table-body-data-paid {
  width: max-content;
  padding: 1px 10px;
  border-radius: 5px;
  font-weight: 600;
}

.app-page-table-body-data-paid {
  background-color: #e2ffed;
  color: #00c247;
}

.app-page-table-body-data-unpaid {
  background-color: #ffdfdf;
  color: #dd0909;
}


.app-page-table-body-data-partially {
  background-color: #ffecd8;
  color: #e47e0a;
}

.app-page-table-body {
  max-height: calc(100vh - 261px);
  overflow: auto;
  background-color: white;
  border-radius: 0 0 8px 8px;
}
.app-page-table-body::-webkit-scrollbar {
  width: 8px;
}
.app-page-table-body::-webkit-scrollbar-thumb {
  background: rgb(from var(--main) r g b / 85%);
  border-radius: 50px;
}

.app-page-table-body .fa-arrow-up {
  color: #e03535;
}

.app-page-table-body .fa-arrow-down {
  color: #00c247;
}

.app-page-table-body-disable {
  color: #ff000096;
  font-weight: 600;
  font-size: 0.8rem;
}

.app-page-table-blank {
  background-color: white;
  border-radius: 0 0 5px 8px;
  text-align: center;
  padding: 80px 0;
}
.app-page-table-blank > a {
  background-color: rgb(from var(--main) r g b / 100%);
  color: white;
  text-decoration: none;
  padding: 8px 30px;
  border-radius: 5px;
  font-size: 1.2rem;
}
.app-page-table-blank > span {
  font-size: 1.5rem;
  font-weight: 600;
}

/* ======================================================================================================== */

.app-page-table-head-filter {
  cursor: pointer;
}
.app-page-table-head-filter-active {
  color: rgb(161, 161, 161);
}
.app-page-table-head-filter > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}
.app-page-table-head-filter > div > i {
  /* font-size: 1.2rem; */
  height: 1px;
}

.app-page-table-body-data-red {
  color: red;
  font-weight: 600;
}
.app-page-table-body-data-green {
  font-weight: 600;
  color: green;
}
.app-page-table-body-data-edit {
  text-align: center;
  font-size: 1.2rem;
}
.app-page-table-body-data-edit:hover {
  background-color: rgb(from var(--main) r g b / 65%);
  color: white;
}

/* ======================================================================================================== */
.view-app-gst-page
.gst-calc-content {
  margin: 0 !important;
  /* background-color: #00c247; */
  border: none;
  box-shadow: none;
  min-height: calc(100vh - 135px);
  overflow: auto;
}

.view-app-gst-page
.gst-calc-content > div{
  padding: 10px 30px;
}


.app-page-content-calculator {
  margin: 10px 0;
  max-height: calc(100vh - 95px);
  overflow: auto;
  width: 100%;
  /* width: calc( 100% - 50px ); */
  border-radius: 20px;
  /* background-color: #00c247; */
}
.view-app-gst-page .gst-calc-heading {
  display: none !important;
}
.view-app-gst-page .view-invoice-page-top{
  z-index: 1;
}

.app-invoice-small-page-margin-top{
  margin-top: 70px;
}

.app-invoice-small-page-content{
  display: none;
}


@media only screen and (max-width: 1000px) {

  .app-page-top{
    padding: 10px;
    border-radius: 0px;
    margin: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .app-page-top-heading{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 20px;
  }

  .app-page-top-search{
    display: none;
  }

  .app-page-top-button{
    padding: 0;
    font-size: 14px;
    padding: 3px 20px;
  }

  .app-page-filter-3{
    display: flex;
    overflow: auto;
    gap: 15px;
    padding: 70px 15px 10px;
    margin: 0;
  }

  .app-page-filter-col-icon{
    height: 35px;
    width: 35px;
    font-size: 18px;
  }

  .app-page-filter > a, .app-page-filter > div{
    padding: 8px 8px;
    min-width: 170px;
    max-width: max-content !important;
    gap: 5px;
    border-radius: 10px;
  }
  .app-page-filter-col-text{
    font-size: 16px;
  }
  .app-page-filter-col-text > span{
    font-size: 16px;
  }
  

  /* -------------------------------------------------------------------- */
  /* -------------------------------------------------------------------- */

  .app-page-table{
    display: none;
  }
  
  .app-invoice-small-page-content{
    display: block;
    padding: 0 15px 20px;
  }
  
  .app-page-main{
    height: auto;
    overflow: auto;
  }
  
  .app-page-table-body-data-paid,
  .app-page-table-body-data-unpaid,
  .app-page-table-body-data-partially{
    font-size: 12px;
    
  }
  
  .app-invoice-small-page-content-options{
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
  }

  .app-invoice-small-page-content-options>
  .app-page-top-search{
    display: flex;
    border: none;
    width: 70%;
    gap: 5px;
  }
  .app-invoice-small-page-content-options>
  .app-page-top-search i{
    font-size: 15px;
  }

  .app-invoice-small-page-content-options>
   select{
    appearance: none; /* Removes default styling */
    background-color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    color: #000;
  } 
  
  /* -------------------------------------------------------------------- */
  /* -------------------------------------------------------------------- */
  /* -------------------------------------------------------------------- */
  
  
  
}





