
.app-setting-navbar-invoician{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    /* background-color: var(--main); */
    /* color: white; */
}


.app-setting-navbar-invoician-logo>img{
    width: 120px;    
    margin-top: 5px;
    /* filter: brightness(0) invert(1); */
}


.app-setting-navbar-invoician-tag>span{
    background-color: var(--main);
    color: white;
    padding: 0px 10px;
    height: auto;
    border-radius: 7px;
    font-size: .9rem;
    font-weight: 600;
}

.app-setting-navbar-back{
    background-color: var(--main);
    color: white;
    /* margin: 0px 30px 20px; */
    text-align: center;
    padding: 10px;
    display: flex;
    gap: 5px;
    justify-content: center;
    /* border-radius: 20px; */
    text-decoration: none;
    margin-bottom: 20px;
    font-size: 1.1rem;
    font-weight: 500;
}


.app-setting-navbar-back>i{
    display: flex;
    margin-top: 1.5px;
    margin-left: -10px;
}

/* -------------------------------------------------------------------------------------------- */

.app-setting-navbar-company{
    padding: 8px;
}
.app-setting-navbar-company-details{
    display: flex;
    align-items: center;
    padding: 4px 8px ;
    justify-content: space-between;
    cursor: pointer;
}
.app-setting-navbar-company:hover
.app-setting-navbar-company-details{
    background-color: var(--bg-light);
    border-radius: 8px;
}

.app-setting-navbar-company-name{
    font-size: 15px;
    font-weight: 600;
}


.app-setting-navbar-company-list{
    display: none;
}
.app-setting-navbar-company:hover
.app-setting-navbar-company-list{
    display: block;
    position: absolute;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    border: 1px solid #cecece;
}


.app-setting-navbar-company-list>div{
    display: flex;
    justify-content: space-between;
    width: 220px;
    cursor: pointer;
    padding: 8px;
    font-weight: 500;
    font-size: 14px;
}
.app-setting-navbar-company-list>div:hover{
    background-color: #dddddd;
}


