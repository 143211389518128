.start-invoician-today {
  background-color: #edeff7;
  color: #292d35;
}

.start-invoician-today-main {
  max-width: 1140px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;
}

.start-invoician-today-heading {
  font-size: 3rem;
  line-height: 1.1;
  font-weight: 600;
  margin-top: 60px;
}

.start-invoician-today-form {
  gap: 8px;
  display: flex;
  align-items: center;
  background-color: #fff;
  /* width: max-content; */
  padding: 5px;
  padding-left: 20px;
  border-radius: 30px;
  margin: 30px 0 40px;
}

.start-invoician-today-form span {
  font-weight: 500;
}
.start-invoician-today-form input {
  padding: 5px 0;
  border: none;
  font-size: 1rem;
  flex: 1;
  outline: none;
  width: 100%;

}

.start-invoician-today-form button {
  background-color: #4c3cce;
  color: #fff;
  border: none;
  padding: 8px 30px;
  border-radius: 30px;
  font-size: 1rem;
}

.start-invoician-today-heading-1 {
  font-size: 1.2rem;
  font-weight: 600;
  /* margin-top: 40px; */
  color: #667085;
}

.start-invoician-today-buttons {
  display: flex;
  gap: 30px;
  margin: 20px 0 40px;
}

.start-invoician-today-buttons > img {
  width: 150px;
}

.start-invoician-today-image > img {
  display: flex;
  width: 90%;
}

@media only screen and (max-width: 1000px) {
  .start-invoician-today-main {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }

  .start-invoician-today-heading {
    font-size: 2rem;
    margin-top: 50px;
  }

.start-invoician-today-form{
    margin: 20px 0;
}

.start-invoician-today-form button {
    padding: 5px 20px;
    font-size: .9rem;
}

.start-invoician-today-buttons{
    gap: 20px;
}
.start-invoician-today-buttons > img {
    width: 35vw;
}


  
}
