
.app-feature-main{
    width: 80%;
    margin: 100px auto;
}

.app-feature-heading{
    text-align: center;
    font-size: 2.7rem;
    font-weight: 700;
    color: #314259;
    margin-bottom: 20px;
}

.app-feature-content{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.app-feature-content>div{
    padding: 20px;
    padding-top: 0;
    height: 600px;
    overflow: auto;
}

.app-feature-content>div::-webkit-scrollbar{
    width: 0;
}

.app-feature-menu::-webkit-scrollbar{
    width: 8px !important;
    height: 4px;
}

.app-feature-menu::-webkit-scrollbar-thumb{
    background-color: #9996;
    border-radius: 10px;
}

.app-feature-menu-option{
    margin: 15px 0 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 25px;
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    gap: 20px;
    cursor: pointer;
    border-radius: 10px;
    font-weight: 700;
    color: #314259;
}
.app-feature-menu-option:hover{
    background-color: #9992;
}
.app-feature-menu-option i{
    font-size: 1.5rem;
    border: var(--web-main) 2px solid;
    border-radius: 50%;
    color: var(--web-main);
    /* padding: 10px; */
    height: 50px;
    width:  50px;
    text-align: center;
    align-content: center;
}


.app-feature-data-heading{
    font-size: 1.3rem;
    padding: 25px 0 10px;
    font-weight: 700;
    color: #314259;
}
.app-feature-data-text{
    display: grid;
    gap: 20px;
}


@media only screen and (max-width: 1000px) {
.app-feature-main{
    width: 100%;
    margin: 60px 0;
}

.app-feature-heading{
    font-size: 1.6rem;
    margin: 0;
}

.app-feature-content{
    grid-template-columns: 1fr;
}

.app-feature-content>div{
    height: auto;
}

.app-feature-menu{
    display: flex;
    gap: 20px;
    padding: 10px 20px !important;
}
.app-feature-menu-option{
    gap: 10px;
    padding: 10px 15px;
    margin: 0;
}

.app-feature-menu-option i{
    height: 40px;
    width:  40px;
    font-size: 1.2rem;

}

.app-feature-menu-text{
    font-size: 1rem;
    width: max-content;
}

.app-feature-data-heading{
    padding: 10px 0;
    font-size: 1.2rem;
}
.app-feature-data-text{
    font-size: .9rem;
}

}




