.invoice-theme-main{
    background: transparent;
}

.invoice-theme-form{
    overflow: auto;
    max-height: calc(100vh - 95px);
    /* background-color: white; */
    padding: 20px;
}
.invoice-theme-1{
    background: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* width: 793px; */
    width: 800px;
    margin: auto;
    /* border: solid; */
    /* height: 1122px; */
}

.invoice-theme-1-create{
    width: 100%;
}
