.price-page-main{
    text-align: center;
    max-width: 1200px;
    margin: 30px auto;
}

.price-page-head-heading{
    font-family: "Roboto Slab";
    font-size: 2.8rem;
    font-weight: 600;
    text-align: center;
    color: #174555;
}
.price-page-head-heading>span{
    font-family: "Roboto Slab";
    font-weight: 900;
}

.price-page-price{
    display: flex;
    justify-content: center;
    padding: 40px;
    align-items: center;
}

.price-page-price>div{
    /* border: solid rgb(from var(--main) r g b / 35%); */
    border-radius: 30px;
    padding: 30px 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    min-width: 350px;
}
.price-page-price>div:nth-child(2){
    padding: 65px 30px;
    background-color: var(--main);
    color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    min-width: 380px;
}

.price-page-price-col-heading{
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--main);
}
.price-page-price>div:nth-child(2)>
    .price-page-price-col-heading{
        color: white;
    }
.price-page-price-col-subheading{
    margin: 10px 0;
}


.price-page-price-col-price{
    font-size: 1.5rem;    
    color: var(--main);
    font-weight: 500;
}
.price-page-price-col-price s{
    font-size: 2rem;    
    color: #ff0000;
}
.price-page-price-col-price>span{
    font-size: 1rem;
}
.price-page-price>div:nth-child(2)>.price-page-price-col-price{
    color: white;
}
.price-page-price-col-button{
    background-color: var(--main);
    width: max-content;
    margin: 10px auto 20px;
    padding: 10px 50px;
    font-size: 1.4rem;
    color: white;
    border-radius: 12px;
    cursor: pointer;
}
.price-page-price>div:nth-child(2)>
.price-page-price-col-button{
    color: var(--main);
    background-color: white;
    font-weight: 500;
}

.price-page-price-col-list-point{
    text-align: start;
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 10px 0;
}
.price-page-price-col-list-point i{
    color: var(--main);
    font-size: 1.3rem;
}
.price-page-price-col-list-point .fa-xmark,
.price-page-price-col-list-point .fa-circle-xmark{
    color: #ff0000;
}
.price-page-price-col-list-point .fa-check,
.price-page-price-col-list-point .fa-circle-check{
    color: #00c040;
}
.price-page-price>div:nth-child(2)>div>.price-page-price-col-list-point>i{
    /* color: white; */
    /* border: solid 1px #ffffff; */
    /* background-color: white; */
    border-radius: 50%;
}

@media only screen and (max-width: 720px) {

/* .price-page-price{
    overflow: auto;
    justify-content: start;


} */





.price-page-head-heading{
    font-size: 2rem;
}

.price-page-price{
    padding: 20px;
    align-items: center;
    overflow: auto;
    justify-content: start;
}


.price-page-price>div{
    /* border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    /* padding: 30px 20px; */
    min-width: 300px;
}
.price-page-price>div:nth-child(2){
    /* padding: 65px 30px;
    background-color: var(--main);
    color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    min-width: 330px;
}

.price-page-price-col-heading{
    font-size: 1.5rem;
    /* font-weight: 500;
    color: var(--main); */
}
.price-page-price>div:nth-child(2)>
    .price-page-price-col-heading{
        color: white;
    }
.price-page-price-col-subheading{
    margin: 10px 0;
}


.price-page-price-col-price{
    font-size: 1.5rem;
}
.price-page-price-col-price s{
    font-size: 1.8rem;
}
/* .price-page-price-col-price>span{
    font-size: 1rem;
} */
/* .price-page-price>div:nth-child(2)>.price-page-price-col-price{
    color: white;
} */
.price-page-price-col-button{
    background-color: var(--main);
    width: max-content;
    margin: 10px auto 20px;
    padding: 6px 40px;
    font-size: 1.2rem;
    color: white;
    border-radius: 12px;
    cursor: pointer;
}
.price-page-price>div:nth-child(2)>
.price-page-price-col-button{
    color: var(--main);
    background-color: white;
    font-weight: 500;
}

.price-page-price-col-list-point{
    text-align: start;
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 10px 0;

    
    
    
    font-size: .9rem;
}
.price-page-price-col-list-point i{
    color: var(--main);
    font-size: 1.5rem;
}
.price-page-price-col-list-point .fa-circle-xmark{
    color: #ff0000;
}
.price-page-price-col-list-point .fa-circle-check{
    color: #00c040;
}
.price-page-price>div:nth-child(2)>div>.price-page-price-col-list-point>i{
    /* color: white; */
    /* border: solid 1px #ffffff; */
    background-color: white;
    border-radius: 50%;
}
 
 
 
 
    
}