.business-setting-form-grid>a{
    color: var(--main);
    font-weight: 500;
    font-size: 1.1rem;
}

.business-setting-form-heading {
    font-size: 1.1rem;
    font-weight: 500;
}
.business-setting-form-heading b{
    color: var(--main);
}