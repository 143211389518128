
/* ====================================================================================================== */
/* ====================================================================================================== */

.calculator-blank{
  height: 400px;
}

.calculator-component{
  margin-top: -390px;
}

.web-header .navbar-main{
  box-shadow: rgba(255, 255, 255, 0.2) 0px 1px 5px 0px;

}



/* ====================================================================================================== */
/* ====================================================================================================== */



.gst-calc-main {
  max-width: 1100px;
  margin: 20px auto ;
}
.gst-calc-heading {
  font-size: 2.5rem; 
  font-family: "Roboto Slab";
  font-weight: 500;
  font-weight: 800;
  text-align: center;
  color: white;
  color: #174555;
  
}

.gst-calc-content {
  display: grid;
  grid-template-columns: 6fr 7fr;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;
  margin: 20px 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  align-items: center;
  align-items: space-between;
  background-color: white;
  color: black;
  gap: 20px;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* box-shadow: 4px 4px 6px 0 #ffffff80,-4px -4px 6px 0 #747d8836,inset -4px -4px 6px 0 #8a8a8a33,inset 4px 4px 6px 2px rgba(0,0,0,.201); */
}
.gst-calc-content > div {
  padding: 40px;
  background-color: transparent;
}

.gst-calc-content-col-1 {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.gst-calc-content-col-1-col {
  display: grid;
  grid-template-columns: 2fr 4fr;
  align-items: center;
}

.gst-calc-content-col-1-label {
  font-size: 1.2rem;
  font-weight: 500;
}

.gst-calc-content-col-1-input {
  display: flex;
  align-items: center;
  border: solid #cfcfcf 1px;
  border-radius: 5px;
}
.gst-calc-content-col-1-range {
    margin-top: -45px;
}
.gst-calc-content-col-1-range > div{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-size: .8rem;
    font-weight: 500;
}
.gst-calc-content-col-1-range > div i{
    font-size: .7rem;
}
.gst-calc-content-col-1-range > div>span:nth-child(2){
    text-align: center;
}
.gst-calc-content-col-1-range > div>span:last-child{
    text-align: end;
}
.gst-calc-content-col-1-range > input {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  outline: none;
}

.gst-calc-content-col-1-range > input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    border: 2px solid var(--main);
}



.gst-calc-content-col-1-input > input {
  width: 100%;
  border: solid #adadad 1px;
  border: none;
  padding: 8px 10px;
  font-size: 1rem;
  background-color: transparent;
}
.gst-calc-content-col-1-input > span {
  align-content: center;
  padding: 8px 15px;
  background-color: #f3f3f3;
  border-radius: 10px 0 0 10px;
  font-size: 1rem;
  font-weight: 600;
}
.gst-calc-content-col-1-input > select{
  border: none;
  background-color: #d8d8d8;
  padding: 8px 15px;
  font-weight: 500;
  font-size: 1rem;
}
.gst-calc-content-col-1-input > span i {
  font-size: 0.8rem;
}

.gst-calc-content-col-1-buttons {
  display: flex;
  gap: 50px;
}
.gst-calc-content-col-1-btn {
  font-size: 1.1rem;
  color: var(--main);
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  color: blue;
  color: var(--main);
}
.gst-calc-content-col-1-btn-red {
  color: #ff0000 !important;
}

/* ----------------------------------------------------------------------------------- */

.gst-calc-content-col-2-graph {
  text-align: center;
  position: relative;
}

.gst-calc-content-col-2-value {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3px;
  color: var(--main);
}
.gst-calc-content-col-2-value > div {
  font-size: 1.2rem;
  font-weight: 500;
  color: black;
}

/* ----------------------------------------------------------------------------------- */

.gst-calc-content-col-2-graph-lable {
  margin: 10px;
  display: flex;
  justify-content: space-evenly;
}
.gst-calc-content-col-2-graph-lable > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.gst-calc-content-col-2-graph-lable > div > div {
  /* border: 1px red solid; */
  width: 20px;
  height: 20px;
}

.gst-calc-content-col-2-graph-cost {
  background-color: #0420b9;
  background-color: #174555;
}
.gst-calc-content-col-2-graph-gst {
  background-color: #ff5959;
  background-color: #ff0000;
}
.gst-calc-content-col-2-graph-profit {
  background-color: #1bbe52;
  background-color: #1bbe52;
}
.gst-calc-content-col-2-graph-cess {
  background-color: #ffd748;
  background-color: #ffc800;
}

/* ------------------------------------- */

.gst-calc-content-col-1-button {
  border: solid var(--main) 2px;
  color: var(--main);
  padding: 8px 40px;
  font-weight: 600;
  border-radius: 5px;
  text-align: center;
  width: 180px;
  cursor: pointer;
  margin-bottom: 30px;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* ------------------------------------- */

.gst-calc-content-col-2-calcultion {
  border-radius: 8px;
  /* border: solid #e9e9e9 1px; */
  margin-top: 30px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 1px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.gst-calc-content-col-2-calcultion-value {
  display: flex;
  padding: 15px 10px;
  justify-content: space-around;
  align-items: center;
}
.gst-calc-content-col-2-calcultion-value > div {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
}
.gst-calc-content-col-2-calcultion-value > div > span:nth-child(1) {
  font-size: 0.9rem;
}
.gst-calc-content-col-2-calcultion-value > div > span:nth-child(2) {
  color: var(--main);
  font-size: 0.9rem;
  font-weight: 500;
}

.gst-calc-content-col-2-calcultion-total {
  text-align: center;
  padding: 10px;
  font-size: 1.1rem;
  font-weight: 500;
  background-color: #17455520;
}


@media only screen and (max-width: 720px) {

  
.gst-calc-main {
  margin: 0px auto 50px;
}
  
  .gst-calc-heading{
    font-size: 1.8rem;
  }
  
.gst-calc-content {
  display: flex;
  flex-direction: column-reverse;
  /* flex-direction: column; */
  margin: 20px 10px;
}
.gst-calc-content >div:first-child{
  /* border-bottom: solid 4px rgba(197, 197, 197, 0.63); */
  border-top: solid 4px rgba(197, 197, 197, 0.63);
}
.gst-calc-content >div{
  padding: 20px;
}
.gst-calc-content-col-2-graph-lable{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  /* text-align: center; */
}
.gst-calc-content-col-2-calcultion-value{
  display: grid;

}

.gst-calc-content-col-2-calcultion-total{
  padding: 10px 30px;
}


}

