.how-generate-invoice-online{
    width: 90%;
    margin: 80px auto;
}



.how-generate-invoice-online-heading{
    font-size: 2.2rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
}

.how-generate-invoice-online-menu{
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.how-generate-invoice-online-menu>div{
    color: #71748e;
    border: solid 1px #eee6e6 ;
    border-radius: 15px;
    padding: 5px 15px;
    font-size: .9rem;
    font-weight: 500;
    cursor: pointer;
}

.how-generate-invoice-online-menu-active{
    border-color: var(--web-main) !important;
    color: #000 !important;
    background-color: var(--web-bg-light);
}

.how-generate-invoice-online-details-col{
    display: flex;
    width: 70%;
    margin: 30px auto;
    align-items: center;
    gap: 20px;
}
.how-generate-invoice-online-details-col>img{
    width: 45%;
}

@media only screen and (max-width: 1000px) {

    .how-generate-invoice-online{
        width: 100%;
        padding: 0 20px;
        margin: 40px 0;
    }
    .how-generate-invoice-online-heading{
        font-size: 1.5rem;
        margin-bottom: 20px;
    }
    
    .how-generate-invoice-online-menu{
        width: 100%;
        overflow: auto;
    }
    .how-generate-invoice-online-menu>div{
        width: max-content;
        min-width: max-content;
        /* font-size: .3rem; */
    }

.how-generate-invoice-online-details-col{
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin: 20px 0;
}
    
    .how-generate-invoice-online-details-col>img{
        width: 60%;
    
}
}


    
    