.app-reports {
  background-color: #fff;
  border-radius: 10px;
}

.app-reports-head {
  padding: 10px 20px;
  font-size: 19px;
  border-bottom: 1px solid #eee;
  font-weight: 500;
}

.app-reports-content-top {
    padding: 20px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.app-reports-content-filter {
  display: flex;
  align-items: center;
  gap: 10px;
}


.app-reports-content-download,
.app-reports-content-filter > div {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 0 5px;
}

.app-reports-content-filter select,
.app-reports-content-filter input {
  border: none;
  outline: none;
  padding: 5px;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
}

.app-reports-content-filter-search {
  width: 200px;
}

.app-reports-content-download {
  padding: 5px 30px;
  cursor: pointer;
  color: #fff;
  background-color: var(--main);
}

.app-reports-content-main{
    height: calc(100vh - 130px);
    overflow: auto;
}
.app-reports-content-main::-webkit-scrollbar{
    width: 8px;
}
.app-reports-content-main::-webkit-scrollbar-thumb{
    background-color: var(--main);
    border-radius: 10px;
}

.app-reports-content-data {
  padding: 20px;
}

.app-reports-content-data-details {
  border: 1px solid #d9d4d4 ;
  color: #28313b;
}

.app-reports-content-data-top{
    padding: 12px 15px;
    font-size: 16px;
    font-weight: 600;
}

.app-reports-content-data-top>span{
    color: #76797c;
}

.app-reports-content-data-details>table{
    width: 100%;
    border-collapse: collapse;
}
.app-reports-content-data-details>table>tr>td{
    border-top: 1px #d9d4d4  solid;
    /* border-left: 1px #d9d4d4  solid; */
    /* background-color: aqua; */
    font-size: 13px;
    padding: 10px 8px;
}

.app-reports-content-data-details>table>tr:first-child{
    background-color: #f8f8fa;
    font-weight: 600;
    text-transform: uppercase;
}

.app-reports-content-data-paid,
.app-reports-content-data-unpaid{
    background-color: #ff000015;
    color: #ff0000;
    font-weight: 500;
    padding: 2px 10px;
    text-align: center;
    border-radius: 5px;
}

.app-reports-content-data-paid{
    color: #0ebe2a;
    background-color: #0ebe2a15;
}

.app-reports-content-data-empty{
    text-align: center;
    padding: 60px;
}


