
.invoice-type-main{
    max-width: 1200px;
    margin: 80px auto;
}

.invoice-type-heading{
    font-size: 2.5rem;
    font-weight: 700;
}
.invoice-type-heading>span:nth-child(1){
    animation: colorChange 19s infinite;
}

.invoice-type-text{
    font-size: 1.1rem;
}

.invoice-type-options{
    display: flex;
    border: solid 2px #d6d6d6;
    justify-content: start;
    width: max-content;
    border-radius: 40px;
    padding: 8px;
    gap: 10px;
    margin: 40px 10px;
}

.invoice-type-options>div{
    padding: 12px 20px;
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 30px;
    color: #636363;
    cursor: pointer;
    /* background-color: #174555; */
}

.invoice-type-option-active{
    background-color: #174555;
    color: white !important;
}


/* .invoice-type-content>img{
    width: 100%;
} */



.invoice-type-content{
    margin: 50px 0;
    height: 550px;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    gap: 30px;
}

.invoice-type-content::-webkit-scrollbar{
    display: none;
}


.invoice-type-content-heading{
    display: flex;
    justify-content: space-between;
    padding: 0 0 10px;
    font-size: 1rem;
}

.invoice-type-content-heading>div{
    font-weight: 500;
}

.invoice-type-content-col{
    margin-top: -30px;
    transition: 1s;
}

.invoice-type-content-col>img{
    border: 1px solid #d6d6d6;
    width: 500px;
    border-radius: 10px;
    transition: 1s;
}

.invoice-type-content-col:hover {
    margin-top: 0;
}

.invoice-type-content-col:hover img{
    width: 600px;
    cursor: pointer;
}




@keyframes colorChange {
    0% {
        color: #078ebb;
    }
    20% {
        color: #d803d8;
    }
    40% {
        color: #ff7b00;
    }
    60% {
        color: #00d660;
    }
    80% {
        color: #7e0eff;
    }
    100% {
        color: #c5b911;
    }
}





@media only screen and (max-width: 700px) {
    
.invoice-type-main{
    max-width: 1200px;
    margin: 80px auto;
}

.invoice-type-heading{
    font-size: 1.8rem;
    line-height: 1.2;
    margin: 0 20px ;
    display: grid;
}

.invoice-type-heading>span:nth-child(1){
    animation: colorChange 19s infinite;
}

.invoice-type-text{
    font-size: .9rem;
    margin: 10px 20px ;
}

.invoice-type-options{
    padding: 5px;
    gap: 0px;
    margin: 20px 10px;
}

.invoice-type-options>div{
    padding: 5px 15px;
    font-size: .9rem;
    border-radius: 30px;
}

.invoice-type-option-active{
    background-color: #174555;
    color: white !important;
}

.invoice-type-content{
    margin: 30px 0;
    padding: 0 20px;
    height: 300px;
    gap: 10px;
}

.invoice-type-content-col>img{
    width: 280px;
}

.invoice-type-content-heading{
    font-size: .85rem;
}

.invoice-type-content-col:hover img{
    width: 330px;
}

    
}


