.shared-invoice-page{
    background-color: #d3d3d3;
}
.shared-invoice-page-main-head{
    background-color: #fff;
    border-bottom: solid 1px #d3d3d3;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
}
.shared-invoice-page-main-head-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 900px;
    margin: 15px auto;
}

.shared-invoice-page-main-heading{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 20px;
}
.shared-invoice-page-main-heading>div{
    font-weight: 600;
}


.shared-invoice-page-main-button{
    background-color: #174555;
    color: white;
    padding: 5px 30px;
    cursor: pointer;
    border-radius: 10px;
}

/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */


.shared-invoice-page-content{
    /* height: 400px; */
    padding: 60px 0;
    overflow: auto;    
}
.shared-invoice-page-content-main{
    width: 800px;
    margin: 20px auto;
    background-color: #fff;
}

/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
.shared-invoice-page-main-end{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #174555;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    flex-wrap: wrap;
}

.shared-invoice-page-main-end>div>a{
    font-weight: 600;
    color: #fff;
}
.shared-invoice-page-main-end>a{
    background-color: #fff;
    padding: 5px 20px;
    height: max-content;
    margin: 0 20px;
    color: #174555;
    font-size: 15px;
    text-decoration: none;
    font-weight: 600;
    border-radius: 5px;
}

@media only screen and (max-width: 1000px) {
    
    .shared-invoice-page-main-head-content{
        margin: 0;
        padding: 10px;
    }
    
    .shared-invoice-page-content-main{
        margin: 10px;
    }
    .shared-invoice-page-main-heading{
        font-size: 15px;
    }

    .shared-invoice-page-main-end{
        font-size: 14px;
        padding: 10px 0px;
    }
    .shared-invoice-page-main-end>a{
        padding: 2px 10px;
        margin: 0 0 0 5px;
        font-size: 13px;
    }

.shared-invoice-page-main-button{
    padding: 3px 15px;
    font-size: 14px;
    border-radius: 5px;
}
    
    
}



