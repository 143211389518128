.view-invoice-page-main {
  background-color: #fff;
  font-size: 15px;
  border-radius: 10px;
}

.view-invoice-page-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px;
  border-bottom: solid 1px #e4e4e4;
  /* z-index: 1; */
}

.view-invoice-page-top-heading {
  font-size: 20px;
  font-weight: 600;
}

.view-invoice-page-top-options {
  display: flex;
  align-items: center;
  gap: 20px;
}
.view-invoice-page-search {
  display: flex;
  align-items: center;
  border: 1px solid #bbb;
  padding: 0 10px;
  border-radius: 5px;
  width: 250px;
}
.view-invoice-page-search > i {
  font-size: 18px;
}
.view-invoice-page-search > .fa-xmark-circle {
  cursor: pointer;
}
.view-invoice-page-search > input {
  border: none;
  outline: none;
  padding: 6px 10px;
  flex: 1;
  width: 100%;
}
.view-invoice-page-top-button-main {
  background-color: #174555;
  color: #fff;
  padding: 6px 30px;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}

.view-invoice-page-content {
  padding-bottom: 20px;
}
.view-invoice-page-filter {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.view-invoice-page-filter > a,
.view-invoice-page-filter > div {
  text-decoration: none;
  color: #000;
  border: solid 1px #bbb;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.view-invoice-page-filter .view-invoice-page-filter-heading {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
}
.view-invoice-page-filter .view-invoice-page-filter-value {
  font-size: 20px;
  font-weight: 500;
}
.view-invoice-page-filter-col-1 .view-invoice-page-filter-heading {
  color: #488dff;
}
.view-invoice-page-filter-col-2 .view-invoice-page-filter-heading {
  color: #2cc646;
}
.view-invoice-page-filter-col-3 .view-invoice-page-filter-heading {
  color: #d01717;
}

.view-invoice-page-filter-col-1{
  border-color: #488dff !important ;
}

.view-invoice-page-filter-col-1-active{
  background-color: #488dff30;
}

.view-invoice-page-filter-col-2 {
  border-color: #2cc646 !important;
}
.view-invoice-page-filter-col-2-active {
  background-color: #2cc64630;
}

.view-invoice-page-filter-col-3 {
  border-color: #d01717 !important;
}
.view-invoice-page-filter-col-3-active {
  background-color: #d0171730;
}

.view-invoice-page-table-parties,
.view-invoice-page-table {
  border: solid 1px #bbb;
  margin: 0 20px;
  border-radius: 8px;
}

.view-invoice-page-table-items,
.view-invoice-page-table-4 {
  border: solid 1px #bbb;
  margin: 20px 20px 0;
  border-radius: 8px;
}

.view-invoice-page-table>.view-invoice-page-table-head,
.view-invoice-page-table>div>.view-invoice-page-table-body {
  display: grid;
  grid-template-columns: 0.8fr 0.7fr 2fr 0.8fr 0.8fr;
  border-bottom: 1px solid #bbb;
}

.view-invoice-page-table-4>.view-invoice-page-table-head,
.view-invoice-page-table-4>div>.view-invoice-page-table-body {
  display: grid;
  grid-template-columns: 0.8fr 0.7fr 1.8fr 0.9fr;
  border-bottom: 1px solid #bbb;
}

.view-invoice-page-table-parties>.view-invoice-page-table-head,
.view-invoice-page-table-parties>div>.view-invoice-page-table-body {
  display: grid;
  grid-template-columns: 1.6fr 1fr 1fr  0.9fr 1.1fr;
  border-bottom: 1px solid #bbb;
}

.view-invoice-page-table-items>.view-invoice-page-table-head,
.view-invoice-page-table-items>div>.view-invoice-page-table-body {
  display: grid;
  grid-template-columns: 1.6fr .9fr .9fr 1.1fr 1.1fr .6fr;
  border-bottom: 1px solid #bbb;
}

.view-invoice-page-table-head > div,
.view-invoice-page-table-body > div {
    padding: 10px;
    border-right: 1px solid #bbb;
}
.view-invoice-page-table-head > div:last-child,
.view-invoice-page-table-body > div:last-child{
    border-right: none;
}

.view-invoice-page-table-head {
  background-color: #17455520;
  border-radius: 5px 5px 0 0;
  font-weight: 600;
}

.view-invoice-page-table-body:hover {
    cursor: pointer;
    background-color: #17455510;
}

.view-invoice-page
.fa-arrow-down{
  color: #2cc646;
}

.view-invoice-page
.fa-arrow-up{
  color: #d01717;
}

.view-invoice-page-table-parties>.view-invoice-page-table-data,
.view-invoice-page-table>.view-invoice-page-table-data {
  height: calc(100vh - 260px);
  overflow: auto;
}

.view-invoice-page-table-items>.view-invoice-page-table-data,
.view-invoice-page-table-4>.view-invoice-page-table-data {
  height: calc(100vh - 165px);
  overflow: auto;
}

.view-invoice-page-table-data::-webkit-scrollbar {
  width: 9px;
  cursor: grab;
}
.view-invoice-page-table-data::-webkit-scrollbar-track{
    background-color: #17455520;
}
.view-invoice-page-table-data::-webkit-scrollbar-thumb {
  cursor: grabbing;
  border-radius: 10px;
  background-color: #174555;
}

.view-invoice-page-tag-partially,
.view-invoice-page-tag-unpaid,
.view-invoice-page-tag-paid{
    padding: 0px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
}

.view-invoice-page-tag-paid{
    color: #2cc646;
    background-color: #2cc64625;
}

.view-invoice-page-tag-unpaid{
    color: #d01717;
    background-color: #d0171720;
}

.view-invoice-page-tag-partially{
    color: #e47e0a;
    background-color: #e47e0a20;
}

.view-invoice-page-table-empty{
    color: #888;
    font-weight: 500;
    text-align: center;
    margin: 30px;
}


.view-invoice-page-list {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .view-invoice-page-top {
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
  }

  .view-invoice-page-top .view-invoice-page-search {
    display: none;
  }

  .view-invoice-page-top-heading {
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .view-invoice-page-top-button-main {
    font-size: 14px;
    padding: 5px 20px;
  }

  .view-invoice-page-content {
    padding-top: 50px;
  }
  .view-invoice-page-filter > div {
    font-size: 16px;
    flex-direction: column-reverse;
    gap: 0;
  }

  .view-invoice-page-filter-col-2 {
    grid-area: b;
  }

  .view-invoice-page-filter-col-3 {
    grid-area: c;
  }
  .view-invoice-page-filter {
    gap: 15px;
    grid-template-areas: "a a" "b c";
    grid-template-columns: repeat(2, 1fr);
    margin: 15px;
    padding: 0;
  }

  .view-invoice-page-filter-col-1 {
    grid-area: a;
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15px !important;
  }

  .view-invoice-page-filter .view-invoice-page-filter-value {
    font-size: 16px;
  }

  .view-invoice-page-table-items,
  .view-invoice-page-table-parties,
  .view-invoice-page-table-4,
  .view-invoice-page-table {
    display: none;
  }

  .view-invoice-page-list {
    display: block;
    margin: 15px;
  }

  .view-invoice-page-list-options{
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
    
    
    
    .view-invoice-page-list-options
    .view-invoice-page-search{
        width: 100%;
        padding: 0 8px;
    }

    .view-invoice-page-search > i{
        font-size: 15px;
    }
    .view-invoice-page-search > input{
        padding: 8px 10px;
    }
    
    
    .view-invoice-page-list-options>
    select{
        appearance: none;
        padding: 0 10px;
        border: #bbb 1px solid;
        border-radius: 5px;
        color: #000;
        background-color: #fff;
    }

    .view-invoice-page-list-data-col{
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        margin-top: 15px;
        border-radius: 5px;
        padding: 15px;
        display: grid;
        gap: 5px;
    }
    .view-invoice-page-list-data-row{
        display: flex;
        justify-content: space-between;
    }

    .view-invoice-page-list-data-name{
    flex: 1;
  }
    .view-invoice-page-list-data-name,
    .view-invoice-page-list-data-amount{
        font-size: 16px;
        font-weight: 600;
      }
      .view-invoice-page-list-data-number{
        color: #777;
        font-weight: 500;
        flex: 1;

    }
    
  
}
