
.all-in-one-software-main{
    max-width: 1200px;
    margin: 100px auto;
}

.all-in-one-software-heading{
    font-size: 2.5rem;
    text-align: center;
    font-weight: 600;
}
.all-in-one-software-heading>span{
    color: var(--main);
    font-weight: 700;
}

.all-in-one-software-content-options{
    display: flex;
    justify-content: space-between;
    border: solid 1px #e0e2e7;
    background-color: #f6f7f7;
    padding: 8px;
    border-radius: 50px;
    gap: 20px;
    margin: 20px 80px;
    font-size: 1.1rem;
}
.all-in-one-software-content-options>div{
    padding: 10px;
    border-radius: 50px;
    color: #667085;
    flex: 1;
    text-align: center;
    cursor: pointer;
}
.all-in-one-software-content-options-active{
    background-color: #fff;
    color: var(--main) !important;
    font-weight: 600;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}

.all-in-one-software-content-details-col{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
}

.all-in-one-software-content-details-col>div{
    border: solid 1px #e0e2e7;
    border-radius: 10px;
    
}
.all-in-one-software-content-details-col>div>div{
    height: 220px;
    padding: 10px;
    margin: auto;
    text-align: center;
    align-items: center;
    /* background-color: #f6f7f7; */
    border-bottom: #e0e2e7 1px solid;
}
.all-in-one-software-content-details-col img{
    max-height: 100%;
    max-width: 90%;    
}
.all-in-one-software-content-details-col>div>a{
    text-decoration: none;
    padding: 10px;
    display: block;
    /* color: #667085; */
    background-color: #e0e2e7;
    color: #000;
    font-size: .9rem;
    font-weight: 500;
    
}


@media only screen and (max-width: 1000px) {


.all-in-one-software-main{
    padding: 20px;
}

.all-in-one-software-heading{
    font-size: 1.5rem;
}


.all-in-one-software-content-options{
    padding: 5px;
    border-radius: 50px;
    gap: 0px;
    font-size: .8rem;
    margin: 20px -10px;
}
    
.all-in-one-software-content-options>div{
    flex: none;
    width: max-content;
    padding: 5px 10px;
}
    
.all-in-one-software-content-details-col{
    grid-template-columns: 1fr;
}
    
.all-in-one-software-content-details-col>div>div{
    height: auto;
}

.all-in-one-software-content-details-col img{
    max-height: none;
    max-width: none;    

    width: 100%;
    
}
    
    
}



