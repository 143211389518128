/* --------------------------------------------------------------- */

.downloading-component{
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  background-color: rgba(167, 167, 167, 0.699);
  font-weight: 700;
}



/* --------------------------------------------------------------- */


.loading-component{
  /* position: fixed; */
  z-index: 8;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  background-color: rgba(255, 255, 255, 0.664);
  font-weight: 700;
  flex-direction: column;
}
.loading-component>div{
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-link{
  margin-top: 10px;
  font-size: 1.1rem;
  font-weight: 400;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
  
  .loader {
    width: 88px;
    height: 12px;
    --_g: no-repeat radial-gradient(farthest-side,#000000 94%,#ffffff00);
    background:
      var(--_g) 25% 0,
      var(--_g) 75% 0;
    background-size: 12px 12px;
    position: relative;
    animation: l24-0 1s linear infinite;
  }
  .loader:before {
    content: "";
    position: absolute;
    height: 12px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #000000;
    inset: 0;
    margin: auto;
    animation: l24-1 1s cubic-bezier(0.5,300,0.5,-300) infinite;
  }
  @keyframes l24-0 {
    0%,24%  {background-position: 25% 0,75% 0}
    40%     {background-position: 25% 0,85% 0}
    50%,72% {background-position: 25% 0,75% 0}
    90%     {background-position: 15% 0,75% 0}
    100%    {background-position: 25% 0,75% 0}
  }
  @keyframes l24-1 {
    100% {transform:translate(0.1px)}
  }
  
  
  /* button loader ==================================================================================== */

  /* HTML: <div class="loader"></div> */
.button-loader {
  width: 25px;
  padding: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--main);
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}

  
.loading-button{
  /* width: 300px; */
  background: rgb(from var(--main) r g b / 50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
 
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  border: none;
  padding: 10px 20px;
  cursor: no-drop;


}

