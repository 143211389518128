
.invoice-generate-invoice{
    background-color: #fdf2f5;
}

.invoice-generate-invoice-main{
    width: 85%;
    margin: auto;
    padding: 80px 0;
}

.invoice-generate-invoice-heading{
    font-size: 2.2rem;
    font-weight: 600;
}
.invoice-generate-invoice-heading>span{
    color: var(--web-main);
}


.invoice-generate-invoice-menu{
    display: flex;
    align-items: center;
    margin: 30px 0 10px;
}

.invoice-generate-invoice-menu-col{
    background-color: #d1d3d4;
    width: 110px;
    height: 60px;
    text-align: center;
    align-content: center;
    font-size: 1.4rem;
    color: #808285;
    border-radius: 5px;
}

.invoice-generate-invoice-menu-col-active{
    background-color: #ed1a3b;
    color: #fff;
}

.invoice-generate-invoice-menu-line{
    border-top: 1px solid #444;
    width: 100px;
}

.invoice-generate-invoice-menu-label{
    display: flex;
    /* margin-bottom: 40px; */
}

.invoice-generate-invoice-menu-label-col{
    width: 110px;
    text-align: center;
    margin-bottom: 40px;
}

.invoice-generate-invoice-menu-label-line{
    width: 100px;
}


.invoice-generate-invoice-form{
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 30px;
}
.invoice-generate-invoice-form>div{
    background-color: #fff;
}

.invoice-generate-invoice-form-col-1{
    padding: 30px;
}

.invoice-generate-invoice-form textarea,
.invoice-generate-invoice-form input{
    border: solid 1px #888;
    border-radius: 5px;
    padding: 5px ;
    margin: 10px 0;
    font-size: .9rem;
}

.invoice-generate-invoice-form-logo>input{
    border: none;
    padding: 10px 15px;
    background-color: #fdf2f5;
    width: 100%;

}

.invoice-generate-invoice-form-heading-1{
    font-size: 2rem;
    color: #676767;
    font-weight: 600;
}

.invoice-generate-invoice-form-heading-2{
    font-size: 1.2rem;
    color: #676767;
    font-weight: 600;
    margin: 15px 0 0px;
}

.invoice-generate-invoice-form-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;
    align-items: center;
}

.invoice-generate-invoice-form-row>div{
    display: grid;
}

.invoice-generate-invoice-form-date{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
}

.invoice-generate-invoice-form-tabel-button{
    background-color: #f3a33a;
    padding: 8px 20px;
    width: max-content;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
}
.invoice-generate-invoice-form-tabel>table{
    width: 100%;
}

.invoice-generate-invoice-form-tabel>table>tr:first-child{
    background-color: #c0c0c0;
    color: #fff;
    font-weight: 500;
}

.invoice-generate-invoice-form-tabel>table td{
    padding: 5px 10px 5px 5px;
}
.invoice-generate-invoice-form-tabel>table td input{
    width: 100%;
}


.invoice-generate-invoice-form-tabel-no{
    width: 5%;
}

.invoice-generate-invoice-form-tabel-item{
    width: 25%;
}
.invoice-generate-invoice-form-tabel-quantity{
    width: 10%;
}
.invoice-generate-invoice-form-tabel-unit{
    width: 15%;
}
.invoice-generate-invoice-form-tabel-rate{
    width: 20%;
}
.invoice-generate-invoice-form-tabel-amount{
    width: 20%;
}
.invoice-generate-invoice-form-tabel-close{
    width: 5%;
}
.invoice-generate-invoice-form-tabel-close i{
    font-size: 1.3rem;
    cursor: pointer;
}

.invoice-generate-invoice-form-table-end{
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}
.invoice-generate-invoice-form-table-total{
    border: 1px solid #676767;
    width: max-content;
    display: flex;
    font-size: 1.1rem;
    gap: 10px;
    font-weight: 600;
    padding: 5px 20px;
    border-radius: 5px;
    align-items: center;
}

.invoice-generate-invoice-form-table-total>span{
    color: #444;
    min-width: 150px;
    text-align: end;
    cursor: no-drop;
}

.invoice-generate-invoice-form-table-end input{
    margin: -10px;
    /* margin-right: -20px; */
    width: 180px;
    border: none;
    outline: none;
    text-align: right;
    font-size: 1rem;
    padding: 0;
}


.invoice-generate-invoice-form-end{
    display: grid;
}
.invoice-generate-invoice-form-end>label{
    margin: 20px 0 5px;
    font-size: 1.1rem;
    color: #444;
}


.invoice-generate-invoice-form-footer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 500;
    font-size: .9rem;
    gap: 5px;
    margin: 20px 0;
}
.invoice-generate-invoice-form-footer>img{
    width: 100px;
}

.invoice-generate-invoice-button>div,
.invoice-generate-invoice-button>button{
    background-color: var(--web-main);
    color: #fff;
    border: none;
    padding: 5px 30px;
    border-radius: 8px;
    margin: 30px 0;
    font-size: 1.1rem;
    cursor: pointer;
    width: max-content;
}



/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.invoice-generate-invoice-form-preview{
    padding: 20px;
}
.invoice-generate-invoice-form-preview-main{
    border: solid 1px #000 ;
    padding: 30px;
}

.invoice-generate-invoice-form-preview-company{
    font-size: .85rem;
}


.invoice-generate-invoice-form-preview-company-name{
    font-size: 1.3rem;
    font-weight: 600;
}

.invoice-generate-invoice-form-preview-heading{
    /* color: #948fe3; */
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
    margin: 10px 0;
}

.invoice-generate-invoice-form-preview-details{
    display: flex;
    justify-content: space-between;
}

.invoice-generate-invoice-form-preview-details-address{
    font-size: .9rem;
}

.invoice-generate-invoice-form-preview-details-address-heading{
    font-weight: 600;
    font-size: 1rem;
}
.invoice-generate-invoice-form-preview-details-address-name{
    /* font-size: 1rem; */
    font-weight: 600;
    color: #636b6f;
}

.invoice-generate-invoice-form-preview-details-options{
    text-align: end;
    font-weight: 600;
    font-size: .9rem;
}


.invoice-generate-invoice-form-preview-table >table{
    width: 100%;
    margin-top: 20px;
    font-size: .85rem;
  border-collapse: collapse;
  color: #636b6f;

}
.invoice-generate-invoice-form-preview-table >table>tr:first-child{
    /* background-color: #948fe3; */
    color: white;
    font-weight: 600;
}
.invoice-generate-invoice-form-preview-table >table>tr>td{
    padding: 8px 5px;
}

.invoice-generate-invoice-form-preview-table >table>tr:last-child{
    border-bottom: solid 1px #777;
    border-top: solid 1px #777;
    color: #636b6f;
    font-weight: 600;
    font-size: .95rem;
}
.invoice-generate-invoice-form-preview-end{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin: 20px 0;
    color: #636b6f;
    font-size: .85rem;
}

.invoice-generate-invoice-form-preview-end-details-heading{
    font-size: .9rem;
    font-weight: 600;
    padding: 15px 0 5px;
}

.invoice-generate-invoice-form-preview-end-details-text{
    background-color: #f7f7f7;
    padding: 5px 2px;
}

.invoice-generate-invoice-form-preview-end-details-text>pre{
    font-family: poppins;
    white-space: pre-wrap;
}
 
.invoice-generate-invoice-form-preview-end-total{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.invoice-generate-invoice-form-preview-end-total-value>div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    font-size: .95rem;
    font-weight: 600;
}

.invoice-generate-invoice-form-preview-end-total-gst{
    background-color: #f7f7f7;
}
.invoice-generate-invoice-form-preview-end-total-total{
    background-color: #948fe3;
    color: #fff;
}


.invoice-generate-invoice-form-preview-end-total-logo{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 5px;
    font-size: .8rem;
    font-weight: 500;
    margin-top: 40px;
}
.invoice-generate-invoice-form-preview-end-total-logo>img{
    width: 70px;
}

.invoice-generate-invoice-form-col-2{
    padding: 20px;
}

.invoice-generate-invoice-form-col-2-heading-1{
    text-align: center;
    margin: 20px 10px;
}
.invoice-generate-invoice-form-col-2-color{
    width: 200px;
    /* border: #000 solid; */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px 10px;
    margin: auto;
    margin-bottom: 50px;
}

.invoice-generate-invoice-form-col-2-color>div{
    background-color: #444;
    height: 40px;
    border-radius: 8px;
    cursor: pointer;
}
.invoice-generate-invoice-form-col-2-color-active{
    border: #000 solid 3px;

}
.invoice-generate-invoice-form-col-2-color>div:nth-child(1){
    background-color: #ed1a3b;
}

.invoice-generate-invoice-form-col-2-color>div:nth-child(2){
    background-color: #9eb7a2;
}

.invoice-generate-invoice-form-col-2-color>div:nth-child(3){
    background-color: #c7beb4;
}

.invoice-generate-invoice-form-col-2-color>div:nth-child(4){
    background-color: #337357;
}

.invoice-generate-invoice-form-col-2-color>div:nth-child(5){
    background-color: #003c97;
}

.invoice-generate-invoice-form-col-2-color>div:nth-child(6){
    background-color: #96b5d8;
}

.invoice-generate-invoice-form-col-2-color>div:nth-child(7){
    background-color: #fc6736;
}

.invoice-generate-invoice-form-col-2-color>div:nth-child(8){
    background-color: #ffd23f;
}


.invoice-generate-invoice-form-col-2-button-2,
.invoice-generate-invoice-form-col-2-button{
    color: #fff;
    width: 150px;
    margin: auto;
    padding: 10px 60px;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    font-size: 2rem;
}

.invoice-generate-invoice-form-col-2-button>a{
    background-color: #ed1a3b;
    width: 100px;
    color: #fff;
    padding: 5px 55px;
    border-radius: 5px;
}

.invoice-generate-invoice-form-col-2-button-2{
    cursor: pointer;
    background-color: #ed1a3b;
    font-size: 1rem;
    padding: 15px;
}

.invoice-generate-invoice-form-col-2-heading-2{
    color: #ed1a3b;
    font-size: 1.1rem;
    text-align: center;
}

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */



/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */





@media only screen and (max-width: 1000px) {

    
.invoice-generate-invoice-main{
    width: 100%;
    margin: auto;
    padding: 50px 10px;
}
    
.invoice-generate-invoice-heading{
    font-size: 1.4rem;
}
.invoice-generate-invoice-menu{
    margin-top: 20px;
    
}

.invoice-generate-invoice-menu-label-col,
.invoice-generate-invoice-menu-col{
    width: 85px;
    height: 45px;
    font-size: 1.1rem;
}

.invoice-generate-invoice-menu-label-line,
.invoice-generate-invoice-menu-line{
    width: 80px;

}

.invoice-generate-invoice-menu-label-col{
    width: 90px;
    font-size: .9rem;
    margin-bottom: 0px;

}

.invoice-generate-invoice-form{
    grid-template-columns: 1fr;
    gap: 20px;
    width: 100%;
    overflow: auto;
}

.invoice-generate-invoice-form-col-1{
    padding: 15px;
}

.invoice-generate-invoice-form-heading-1{
    font-size: 1.7rem;
}


.invoice-generate-invoice-form-row{
    grid-template-columns: 1fr;
    gap: 0px;

}

.invoice-generate-invoice-form-date{
    display: grid;
    grid-template-columns: 3fr 8fr;
}

    .invoice-generate-invoice-form-tabel{
        width: calc(100vw - 35px);
        /* width: 100% !important; */
        overflow: scroll;
        margin: 0 -10px;
    }
    .invoice-generate-invoice-form-tabel>table{
        width: 600px;
    }
    
    
    .invoice-generate-invoice-form-table-total{
        /* display: none; */
        font-size: .9rem;
    }

    .invoice-generate-invoice-form-end>label{
        font-size: 1rem;
        margin: 20px 0 0;
    }

    .invoice-generate-invoice-form-col-2{
        /* display: none; */
    }
    
    .invoice-generate-invoice-form-col-2-color{
        margin-bottom: 30px;
    }

    .invoice-generate-invoice-form-col-2-button>a{
        font-size: 1.3rem;
        
    }
    .invoice-generate-invoice-button>div, .invoice-generate-invoice-button>button{
        margin: 20px 0;
    }

    .invoice-generate-invoice-form-preview{
        width: 790px;
    }
    
    
    
    

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
}

