
.business-setting-head{
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: solid 1px #cacaca; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
    margin: 10px;
    border-radius: 5px;
}
.business-setting-head-heading{
    font-size: 1.8rem;
    color: var(--main);
    font-weight: 600;
}
.business-setting-head>div{
    display: flex;
    align-items: center;
    gap: 10px;
}

.business-setting-head-error{
    color: red;
}

.business-setting-head-button-cancle,
.business-setting-head-button-disable-cancle,
.business-setting-head-button-done,
.business-setting-head-button-disable,
.business-setting-head-button{
    background-color: var(--main);
    color: white;
    font-weight: 500;
    /* display: flex; */
    padding: 8px 40px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    border: solid 3px var(--main);
    font-size: 1rem;
}
.business-setting-head-button-cancle{
    /* background-color: red ; */
}

.business-setting-head-button-disable{
    background: rgb(from var(--main) r g b / 50%);
    cursor: no-drop;
    border-color: rgb(from var(--main) r g b / 20%) !important;

}
.business-setting-head-button-disable-cancle{
    background-color: transparent;
    cursor: no-drop;
    border-color: #ff000050;
    color: #ff000050;
}

.business-setting-head-button-cancle{
    background-color: transparent;
    cursor: pointer;
    border-color: red;
    color: red;
}

    .business-setting-head-button-done{
    background: #25d366;
    border-color: #25d366;
    
    display: flex;
    align-items: center;
    gap: 5px;
}
.business-setting-head-button-disable>i{
    font-size: 1.2rem;
}


/* ======================================================================== */
.business-setting-form{
    box-shadow: #0000003d 0px 3px 8px;
    background-color: white;  
    max-height: calc( 100vh - 120px);
    border-radius: 10px;
    overflow: auto;
    padding: 20px;
    margin: auto;
    margin: 15px 10px ;
    display: grid;
    gap: 30px;
}

.business-setting-form-grid{
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 30px;
    align-content: start;
    
}

/* .business-setting-form::-webkit-scrollbar{
    width: 8px;
}
.business-setting-form:hover::-webkit-scrollbar-thumb{
    background-color: var(--main);
    border-radius: 20px;
} */

.business-setting-form-company-name{
    font-size: 2rem;
    font-weight: 700;
    color: var(--main);
    align-content: center;
    text-decoration: underline;
}

.business-setting-form-logo{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}
.business-setting-form-logo-text{
    width:  80px;
    height: 80px;
    background-color: var(--main);
    border-radius: 50%;
    color: white;
    font-size: 3.2rem;
    font-weight: 600;
    text-align: center;
    align-content: center;
}
.business-setting-form-logo-buttons{
    display: grid;
    gap: 10px;
}
.business-setting-form-logo-edit{
    text-align: center;
    font-weight: 600;
    padding: 2px 8px;
    border: solid 2px var(--main);
    color: white;
    color: var(--main);
    background-color: var(--main);
    background-color: transparent;
    border-radius: 5px;
}

.business-setting-form-logo-red{
    font-weight: 600;
    text-align: center;
    padding: 2px 8px;
    border: solid 2px red;
    color: red;
    /* background-color: var(--main); */
    border-radius: 5px;
}

.business-setting-form-logo img{
    /* width: 100%; */
    min-width: 60px;
    min-height: 60px;
    max-height: 120px;
    max-width: 250px;
    height: auto;
    width: auto;
    /* border: #25d366 solid 1px; */
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.business-setting-form-col{
    display: grid;
}
.business-setting-form-col>label{
    font-size: .9rem;
    font-weight: 500;
    color: #555555;
}

.business-setting-form-col>input{
    padding: 10px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
    border: solid 1px #d1d1d1;
    margin-bottom: 5px;
}


/* ============================================================================================================== */

.invoice-setting-form-choose-color-col{
    display: flex;
    gap: 15px;
    align-items: center;
}
.invoice-setting-form-choose-color-activated{
    border: #002fff solid  !important;
    padding: 5px !important;
    width:  65px;
    height: 65px;
}
.invoice-setting-form-choose-color-activated>div{
    width: 100%;
    height: 100%;
}
.invoice-setting-form-choose-color{
    display: flex;
    /* gap: 5px; */
    /* flex-wrap: wrap; */
    /* justify-content: center; */
}
.invoice-setting-form-choose-color>div{
    width:  45px;
    height: 45px;
    cursor: pointer;
    padding: 4px;
}
.invoice-setting-form-choose-color>div>div{
    border: solid 1px;
    width: 100%;
    height: 100%;
}



.invoice-setting-form-choose-color>div:nth-child(1)>div{
    border-color: #c11111;
    background-color: #c11111;
    border-radius: 15px 0 0 15px ;
}
.invoice-setting-form-choose-color>div:nth-child(2)>div{
    border-color: #0b6a9f;
    background-color: #0b6a9f;
}

.invoice-setting-form-choose-color>div:nth-child(3)>div{
    border-color: #5b57ae;
    background-color: #5b57ae;
}

.invoice-setting-form-choose-color>div:nth-child(4)>div{
    border-color: #840bb2;
    background-color: #840bb2;
}

.invoice-setting-form-choose-color>div:nth-child(5)>div{
    border-color: #326a00;
    background-color: #326a00;
}

.invoice-setting-form-choose-color>div:nth-child(6)>div{
    border-color: #1c3036;
    background-color: #1c3036;
}

.invoice-setting-form-choose-color>div:nth-child(7)>div{
    border-color: #bf6200;
    background-color: #bf6200;
}

.invoice-setting-form-choose-color>div:nth-child(8)>div{
    border-color: #cd9d23;
    background-color: #cd9d23;
}


.invoice-setting-form-choose-color>div:last-child>div{
    border-color: #000000;
    background-color: #000000;
    border-radius: 0 15px 15px 0 ;
}

/* ============================================================================================================== */

.invoice-setting-form-choose-theme{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    margin: 10px;
}
.invoice-setting-form-choose-theme>img{
    /* height: 200px; */
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;
}
.invoice-setting-form-choose-theme-activated{
    border: #002fff solid 4px;
}

/* ============================================================================================================== */

.business-setting-form-grid-3{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin: 5px 20px;
}

.invoice-setting-form-invoice-option-heading{
    font-size: 1.3rem;
    font-weight: 600;
}

.invoice-setting-form-invoice-option-col{
    margin: 5px;
    display: flex;
    gap: 20px;
    align-items: center;
}
.invoice-setting-form-invoice-option-col>div{
    /* color: #25d366; */
    /* font-weight: 500; */
    font-size: 1.1rem;
    cursor: pointer;
}
    .invoice-setting-form-invoice-option-enable{
        color: #25d366;
}

    .invoice-setting-form-invoice-option-disable{
        color: #ff0000;
}


/* ============================================================================================================== */

.business-setting-form-label-heading{
    font-size: 1.2rem;
    font-weight: 600;
    margin: 20px 0 10px;
    display: flex;
    gap: 50px;
}
.business-setting-form-label-heading>div{
    color: red;
    cursor: pointer;
}
.business-setting-form-terms-create{
    display: flex;
    align-items: center;
    
}
.business-setting-form-terms-create>input{
    flex: 1;
    font-size: 1rem;
    padding: 10px;
}
.business-setting-form-terms-create>div{
    background-color: var(--main);
    color: white;
    padding: 12px 20px;
    font-weight: 500;
}



.business-setting-form-terms-points>div{
    display: flex;
    margin: 0 10px 10px;
    align-items: center;

}
.business-setting-form-terms-points>div>input{
    flex: 1;
    border: none;
    font-size: 1rem;
    /* padding: 20px 10px 2px 10px; */
    padding: 5px ;
    margin: 0px 10px;
border-bottom: #a5a5a5 solid 2px;
}
.business-setting-form-terms-points>div>div{
    background-color: var(--main);
    color: white;
    padding: 8px 20px;
    font-weight: 500; 
    cursor: pointer;  
}


.business-setting-form-terms-add{
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: underline;
    color: blue;
    cursor: pointer;
    width: 160px;
}



/* ============================================================================================================== */
.business-setting-form-grid-4{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
}



/* ============================================================================================================== */

.business-setting-form-custom-field{
    background-color: rgb(from var(--main) r g b / 10%);
    margin: 20px -20px;
    padding: 20px;
}
.business-setting-form-custom-field>div{
    display: grid;
    gap: 10px;
}
.business-setting-form-col-head{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.business-setting-form-col-head>div,
.business-setting-form-col-head>span:nth-child(1){
    color: var(--main);
    font-size: 1.3rem;
    font-weight: 600;
}
.business-setting-form-col-head>div{
    cursor: pointer;
}
.business-setting-form-col-head>span:nth-child(2){
    color: red;
    cursor: pointer;
    font-weight: 500;
}


/* ============================================================================================================== */
.business-setting-form-color-theme-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.business-setting-form-color-theme-head>span{
    font-size: .9rem;
    font-weight: 500;
    color: #555555;
}
.business-setting-form-color-theme-head>span:last-child{
    font-size: 1.1rem;
    color: blue;
    font-weight: 600;
    cursor: pointer;
}

    .business-setting-form-color-theme-head>span>input{
        height: 2px;
        width: 2px;
    }
/* ============================================================================================================== */

@media only screen and (max-width: 1000px) {
    .business-setting-head{
        padding: 10px;
    }

    .business-setting-head-heading{
        font-size: 16px;
        
    }
    
    .business-setting-head-button-cancle, .business-setting-head-button-disable-cancle, .business-setting-head-button-done, .business-setting-head-button-disable, .business-setting-head-button{
        font-size: 15px;
        padding: 3px 20px;
    }
    
    
    
    .business-setting-form-grid{
        grid-template-columns: repeat(1, 1fr);
    }
    
    
    
    
}


