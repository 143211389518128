
/* .invoice-theme-2{
    background-color: rgb(184, 184, 184);
    padding: 20px;
    }
    .invoice-theme-2>div{
        background-color: white;
        margin: auto;
        padding: 30px 40px 50px;
        width: 800px;
        } */
        
        
        /* -------------------------------------------------------------------------------- */
        .invoice-theme-2{
            padding: 40px;
            max-width: 850px;
        }
        
.invoice-theme-2-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0px;
}

.invoice-theme-2-img>img{
    max-width: 250px;
    max-height: 100px;
}

.invoice-theme-2-img-text{
    width:  70px;
    height: 70px;
    border-radius: 50%;
    background-color: var(--invoice);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 3rem;
    font-weight: 500;
}

.invoice-theme-2-text-proforma,
.invoice-theme-2-text{
    font-size: 2.8rem;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 600;
    color: var(--invoice);
}

.invoice-theme-2-text-proforma{
    font-size: 2.1rem !important;
}

/* -------------------------------------------------------------------------------- */

.invoice-theme-2-details{
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin: 40px 0 0px;
}

.invoice-theme-2-details-col-1{
    display: grid;
    gap: 5px;
    max-width: 550px;

}
.invoice-theme-2-details-col-1-heading{
    font-size: 1.3rem;
    font-weight: 600;
}
.invoice-theme-2-details-col-1-name{
    font-size: 1.2rem;
    font-weight: 500;
}
.invoice-theme-2-details-col-1-gst>b{
    font-weight: 500;
}


.invoice-theme-2-details-col-2{
    text-align: end;
    /* min-width: 250px; */
    width: max-content !important;
}
.invoice-theme-2-details-col-2 b{
    font-weight: 600;
}


/* -------------------------------------------------------------------------------- */

.invoice-theme-2-details-table{
    width: 100%;
}
.invoice-theme-2-details-table th,
.invoice-theme-2-details-table td{
    border-bottom: solid 1px black !important;
    text-align: center;
    padding: 10px 5px;
}
.invoice-theme-2-details-table th{
    border-top: solid 1px black !important;
    /* border-top:    solid 2px black !important; */
    /* border-bottom: solid 2px black !important; */
}


.invoice-theme-2-details-end{
    display: flex;
    gap: 50px;
    justify-content: space-between;
    padding: 15px 20px;
}

.invoice-theme-2-details-end-custom-fields th,
.invoice-theme-2-details-end-custom-fields td{
    padding: 5px;
    text-align: left;
}
.invoice-theme-2-details-end-custom-fields th{
    font-weight: 600;
}


.invoice-theme-2-details-end-total th,
.invoice-theme-2-details-end-total td{
    text-align: center;
}
.invoice-theme-2-details-end-total td{
    padding: 0px 0 5px 30px;
}
.invoice-theme-2-details-end-total th{
    font-weight: 600;
}
.invoice-theme-2-details-end-total tbody td,
.invoice-theme-2-details-end-total tbody th{
    font-size: 1.2rem;
    border-top: 1px solid black;
    font-weight: 600;
}
/* .invoice-theme-2-details-end-total tbody td, */


/* -------------------------------------------------------------------------------- */

.invoice-theme-2-thanks{
    font-size: 1.8rem;
    font-weight: 700;
    margin : 20px 30px 30px 10px;
    color: var(--invoice);
}


/* -------------------------------------------------------------------------------- */

.invoice-theme-2-end{
    display: flex;
    justify-content: space-between;
}
.invoice-theme-2-end-bank{
    width: max-content;
}
.invoice-theme-2-end-bank-heading{
    font-size: 1.1rem;
    font-weight: 700;
}

.invoice-theme-2-end-bank th,
.invoice-theme-2-end-bank tr{
    font-size: .9rem;
    text-align: start;
    padding: 2px 10px;
}
.invoice-theme-2-end-bank th{
    font-weight: 600;
}


.invoice-theme-2-end-company{
    margin-top: 40px;
    text-align: end;
    max-width: 300px;
    font-size: .9rem;
    align-content: end;
}
.invoice-theme-2-end-company-name{
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--invoice);
}

.invoice-theme-2-end-company-gst b{
    font-weight: 600;
}


/* -------------------------------------------------------------------------------- */

.invoice-theme-2-terms{
    margin-top: 40px ;
}

.invoice-theme-2-terms-heading{
    font-size: .95rem;
    font-weight: 700;
}
.invoice-theme-2-terms-points{
    font-size: .75rem;
}


/* -------------------------------------------------------------------------------- */


