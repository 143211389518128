.app-form {
  border-radius: 20px;
  min-height: calc(100vh - 20px);
  margin-bottom: 30px;
}

.app-form-main {
  /* background-color: white; */
  border-radius: 20px;
}

.app-form-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: white;
  border-radius: 10px 10px 0 0;
  border-bottom: solid 2px rgb(from var(--main) r g b / 20%);
}
.app-form-top-col-1 {
  display: flex;
  gap: 15px;
}
.app-form-top-col-back {
  color: var(--main);
  text-decoration: none;
  cursor: pointer;
}
.app-form-top-col-1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--main);
}

.app-form-top-col-2-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}
.app-form-top-col-2-buttons > button,
.app-form-top-col-2-buttons > div {
  border: solid var(--main) 2px;
  font-size: 1rem;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: transparent;
  color: var(--main);
  cursor: pointer;
}

.app-form-top-col-button-2 {
  display: flex;
  align-items: center;
  padding: 0 !important;
}

.app-form-top-col-button-2 > div {
  padding: 5px 10px;
}

.app-form-top-col-button-2-drop {
  background-color: #174555;
  color: white;
}
.app-form-top-col-button-2-drop-list {
  display: none;
}

.app-form-top-col-button-2-drop:hover .app-form-top-col-button-2-drop-list {
  display: block;
  position: absolute;
  color: black;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px -10px;
  /* left: 20px; */
}
.app-form-top-col-button-2-drop-list > div {
  padding: 5px 15px;
  font-weight: 400;
}
.app-form-top-col-button-2-drop-list > div:hover {
  background-color: #17455510;
}

.app-form-top-col-button-3 {
  color: red !important;
  color: white !important;
  background-color: red !important;
  border: solid red 2px !important;
}

.app-form-top-col-button-4 {
  color: red !important;
  /* background-color: red !important; */
  border: solid red 2px !important;
}
.app-form-top-col-2-buttons > button {
  background-color: var(--main);
  color: white;
}
.app-form-top-col-button-disable {
  border: none !important;
  padding: 7px 30px !important;
  cursor: no-drop !important;
  background: rgb(from var(--main) r g b / 60%) !important;
  color: white !important;
}

/* ============================================================================================================= */

.app-form-form {
  background-color: white;
  /* border: solid 1px; */
  max-height: calc(100vh - 92px);
  overflow: auto;
}

.app-form-form-head {
  background: rgb(from var(--main) r g b / 10%);
  font-size: 1.3rem;
  font-weight: 500;
  padding: 10px 15px;
  color: var(--main);
  /* margin: 10px 0; */
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 20px;
}
.app-form-form-head-link {
  cursor: pointer;
  color: blue;
}
.app-form-form-head > span {
  font-size: 1rem;
  color: red;
  cursor: pointer;
}
.app-form-form-body {
  padding: 10px 30px 30px;
}
.app-form-form-body-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px 30px;
}
.app-form-form-body-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px 30px;
}

.app-form-form-input-col {
  display: grid;
}
.app-form-form-input-col > label {
  font-size: 0.9rem;
  font-weight: 500;
  color: #777777;
}

.app-form-form-input-col-table {
  display: flex;
  width: 100%;
  gap: 30px;
  padding: 0 30px 30px;
}
.app-form-form-input-col-table > div {
  flex: 1;
}

/* .app-form-form-input-col-radio-options>span:nth-child(1), */
.app-form-form-input-col-radio-options > span,
.app-form-form-input-col > select,
.app-form-form-input-col > input {
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  border: solid 1px #dddddd;
  margin-bottom: 5px;
}
.app-form-form-input-col-radio-options > span {
  cursor: pointer;
}
.app-form-form-input-col-radio-options-active {
  border: blue 3px solid !important;
  border: var(--main) 3px solid !important;
  font-weight: 500;
  font-size: 1.2rem !important;
  color: var(--main);
  padding: 7px 12px !important;
}

/* ------------------------------------------------ */
.app-form-form-body-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 40px;
}
.app-form-form-body-col-2 {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  margin: 30px 0 10px;
  justify-content: space-between;
}
.app-form-form-body-col-2 input {
  /* background-color: red; */
  width: 100%;
}

.app-form-form-body-heading {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 20px;
  color: var(--main);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.app-form-form-body-heading-shipping-remove,
.app-form-form-body-heading > span > div {
  font-size: 0.9rem;
  color: #777777;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}
.app-form-form-body-heading-shipping-remove {
  color: red;
  text-decoration: underline;
}
.app-form-form-body-heading > span > div > i {
  font-size: 1.2rem;
}
.app-form-form-body-heading > span > .app-form-form-body-heading-shipping {
  color: #25d366;
}

.app-form-form-body-heading-button {
  color: blue;
  /* text-decoration: underline; */
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 10px;
  border: solid 2px;
  width: max-content;
  padding: 5px 20px;
}

.app-form-form-input-col-radio-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.app-form-form-input-col-radio-options > span {
  display: flex;
  /* gap: 80px; */
  align-items: center;
  font-size: 1.1rem;
  /* border: none; */
  gap: 10px;
}
.app-form-form-input-col-radio-options > span {
}
.app-form-form-input-col-radio-options input {
  width: 25px;
  height: 25px;
}

.app-form-form-input-col-balance-type > div {
  display: flex;
}
.app-form-form-input-col-balance-type > div > span,
.app-form-form-input-col-balance-type > div > select,
.app-form-form-input-col-balance-type > div > input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 5px 0 0 5px;
  border: solid 1px #dddddd;
  margin-bottom: 5px;
}
.app-form-form-input-col-balance-type > div > span,
.app-form-form-input-col-balance-type > div > select {
  width: auto;
  background-color: rgb(from var(--main) r g b / 10%);
  padding: 5px;
  border-radius: 0 5px 5px 0;
  font-weight: 500;
  color: #525252;
  align-content: center;
}

.app-form-form-input-col-balance-type > div > select > option {
  background-color: white;
}

/* ============================================================================================================= */
/* ============================================================================================================= */
/* ============================================================================================================= */

.app-form-form-input-col-text {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 5px 0px 10px;
}
.app-form-form-body-col-2-view {
  display: flex;
  gap: 100px;
  margin: 30px 0 10px;
}

@media only screen and (max-width: 1000px) {
  .app-form {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #e8eff9;
    z-index: 1;
    margin: 0;
    height: 100%;
}

.invoice-theme-form {
    max-height: none !important;
    /* height: 100vh; */
    height: calc(100vh - 55px);
    padding-bottom: 160px !important;
    
    /* transform: scale(0.5); */


  }

  .app-form-top {
    padding: 10px;
  }

  .app-form-top-col-1 {
    gap: 5px;
    font-size: 18px;
  }

  .app-form-top-col-2-buttons > button span,
  .app-form-top-col-2-buttons > div span {
    display: none;
  }

  .app-form-top-col-2-buttons > button,
  .app-form-top-col-2-buttons > div {
    padding: 3px 8px;
}

.app-form-top-col-button-2 > div {
    padding: 3px 8px;
}
.app-form-top-col-button-2 > div:nth-child(2) {
    padding: 3px 5px;
}
}

/* ============================================================================================================= */
