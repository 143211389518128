.alert-component {
    background-color: rgba(0, 0, 0, 0.678);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
  }
  
  .alert-component-content{
      background-color: white;
      padding: 20px;
      border-radius: 10px;
  }
  
  .alert-component-heading{
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: 15px;
  }
  
  .alert-component-options{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
  }
  .alert-component-options>span:nth-child(1){
      cursor: pointer;
      font-size: 1.2rem;
      font-weight: 600;
  }
  .alert-component-options>span:nth-child(1):hover{
      color: var(--main);
  }
  .alert-component-options>span:nth-child(2){
      cursor: pointer;
      background-color: var(--main);
      color: white;
      padding: 1px 20px;
      border-radius: 5px;
  }
  .alert-component-options>span:nth-child(2):hover{
      background-color: var(--main);
      
  }
  
  @media only screen and (max-width: 1000px) {

  .alert-component{
    height: 100%;
  }
  }
