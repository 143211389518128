.invoice-excel-theme-heading{
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  padding: 25px;
  /* margin-bottom: -40px; */
  font-size: 15px;
  padding-bottom: 10px;
  text-transform: capitalize;
  text-transform: uppercase;
}

.invoice-excel-theme-heading>div{
  font-size: 13px;
  color: #707070;
  border: solid 2px #aaaaaa;
  padding: 0 10px;
  
}

.invoice-excel-theme {
  /* border: solid 1px #000; */
  /* width: 800px; */
  background-color: white;
  margin: auto;
  padding: 25px;
  padding-top: 0;
}

.invoice-excel-theme-main {
  border: solid 1px #000;
  min-height: 900px;
  font-size: 14px;
}

.invoice-excel-theme-head {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px;
  color: #707070;
}

.invoice-excel-theme-head-company-name {
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.invoice-excel-theme-head-text {
    font-size: 40px;
    font-weight: 500;
    color: var(--invoice);
}
.invoice-excel-theme-head-company b{
    font-weight: 600;
}

.invoice-excel-theme-options {
  border-top: solid 1px #000;
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  display: flex;
}
.invoice-excel-theme-options > div {
  padding: 5px 10px;
  width: 100%;
  /* flex: 1; */
}

.invoice-excel-theme-options > div:nth-child(2) {
  border-left: solid 1px #000;
}

.invoice-excel-theme-options-tabel-row {
  /* display: grid; */
  /* grid-template-columns: 2fr 3fr; */
  display: flex; align-items: start; gap: 10px;
  font-weight: 600;
  margin: 5px 0;
}
.invoice-excel-theme-options-tabel-row > div:first-child {
  font-weight: 500;
  color: #707070;
  min-width: 100px;
}

.invoice-excel-theme-address-head {
  margin: -5px -10px;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: solid 1px #000;
}

.invoice-excel-theme-address-content {
  padding: 15px 0 10px;
}

.invoice-excel-theme-address-content-name {
  font-weight: 600;
  font-size: 16px;
}

.invoice-excel-theme-address-content-text{
    /* margin-top: 5px; */
}
.invoice-excel-theme-address-content-text b{
    /* color: #707070; */
    font-weight: 600;
}

.invoice-excel-theme-table {
  border-top: solid 1px #000;
}
.invoice-excel-theme-table > table {
  width: 100%;
  border-collapse: collapse;
}
.invoice-excel-theme-table > table > tr > td {
    border-top: #000 1px solid;
    border-left: #000 1px solid;
    padding: 10px 8px;
    vertical-align: top;
    word-break: break-all;
}

.invoice-excel-theme-table > table > tr:first-child > td {
    background-color: var(--invoice);
    color: white;
    font-weight: 500;
    border-top: none;
}
.invoice-excel-theme-table > table > tr > td:first-child {
    border-left: none;
    text-align: center;
}
.invoice-excel-theme-table > table > tr > td:last-child {
    border-right: none;
}
.invoice-excel-theme-table > table > tr:last-child > td {
    border-left: none;
    font-weight: 600;
}
.invoice-excel-theme-table > table > tr > td >span{
    color: #707070;
    font-size: 13px;
    
}
.invoice-excel-theme-table-left-border{
        border-left: #000 1px solid !important;
    }



/* .invoice-excel-theme-table-body,
.invoice-excel-theme-table-head {
   display: flex; 
} */

/* .invoice-excel-theme-table-sno {
}
.invoice-excel-theme-table-item {
}
.invoice-excel-theme-table-hsn {
}
.invoice-excel-theme-table-qty {
}
.invoice-excel-theme-table-rate {
}
.invoice-excel-theme-table-discount {
}
.invoice-excel-theme-table-tax {
}
.invoice-excel-theme-table-cess {
}
.invoice-excel-theme-table-amount {
} */

.invoice-excel-theme-end{
    border-top: #000 1px solid;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.invoice-excel-theme-end-heading{
    font-weight: 600;
    font-size: 15px;
    margin-top: 10px;
    /* margin-bottom: 15px; */
}
.invoice-excel-theme-end-text pre{
    font-size: 13px;
    white-space: pre-wrap;
    
    /* max-width: 100%; */
    /* overflow-wrap: break-word; */
    /* overflow-wrap: break-word; */
}

.invoice-excel-theme-end-col-1{
    padding: 0px 15px;
}

.invoice-excel-theme-end-col-2-amount{
    height: max-content;
    padding: 10px 20px  !important;
}

.invoice-excel-theme-end-col-row-total,
.invoice-excel-theme-end-col-row{
    display: flex;
    justify-content: space-between;
    gap: 30px;
    min-width: 250px;
    margin-bottom: 5px;
}

.invoice-excel-theme-end-col-2>.invoice-excel-theme-end-col-row:first-child{
    font-weight: 500;
    font-size: 15px;
}
.invoice-excel-theme-end-col-row-total{
    font-weight: 600;
    font-size: 17px;
    margin: 0 -15px 5px;
    padding: 5px 10px;
    border-top: 2px solid var(--invoice);
    border-bottom: 2px solid var(--invoice);
}
 
.invoice-excel-theme-signature{
    margin: 20px 0;
    text-align: end;
    padding: 10px;
}

.invoice-excel-theme-signature-empty{
    border: #000 solid 1px;
    height: 100px;
}

.invoice-excel-theme-signature-text{
    font-weight: 500;
}
.invoice-excel-theme-signature-text>div{
    font-weight: 600;
    font-size: 15px;
    margin-top: -2px;
}

.invoice-excel-theme-signature>img{
    margin-top: 30px;
    max-width: 130px;
    max-height: 110px;
}



/* ========================================================================= */
/* setting ========================================================================= */
/* ========================================================================= */

.invoice-excel-theme-active{
  border: 4px solid #0c8ce9 !important;
}



/* ========================================================================= */
/* ========================================================================= */
/* ========================================================================= */
/* ========================================================================= */



