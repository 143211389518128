.setting-business-page-head {
  border-bottom: solid 1px #d9d4d4;
  padding: 11px 15px;
}
.setting-business-page-head-col {
  display: flex;
  align-items: center;
  gap: 15px;
}

.setting-business-page-sub-heading {
  font-size: 12px;
  color: #687e96;
  line-height: 1;
}

.setting-business-page-head-col-button {
  background: #db631a;
  color: #ffff;
  padding: 5px 30px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

/***************************************************************************************************************************/

.setting-business-page-form {
  padding: 20px;
  height: calc(100vh - 80px);
  overflow: auto;
}

.setting-business-page-form::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.setting-business-page-form::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 20px;
}

.setting-business-page-form-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 15px;
}

.setting-business-page-form-section-1 {
  padding-right: 110px;
}

.setting-business-page-form-col {
  display: grid;
  padding-bottom: 20px;
  height: max-content;
}

.setting-business-page-form-col label {
  color: #687e96;
  margin-bottom: 8px;
}
.setting-business-page-form-col label b {
  color: #d01717;
}

.setting-business-page-form-col-dropdown-select,
.setting-business-page-form-col-select,
.setting-business-page-form-col select,
.setting-business-page-form-col textarea,
.setting-business-page-form-col input {
  border: solid 1px #d9d4d4;
  border-radius: 5px;
  padding: 6px 12px;
  outline: none;
}

.setting-business-page-form-note {
  background-color: #f8f9fc;
  font-size: 12px;
  text-align: center;
  padding: 8px;
  margin-bottom: 20px;
}

.setting-business-page-form-signature-col {
  border: dashed #488dff 1px;
  border-radius: 5px;
  cursor: pointer;
  color: #488dff;
  font-weight: 500;
  width: 200px;
  height: 100px;
  text-align: center;
  align-content: center;
}
.setting-business-page-form-signature-col>img{
  max-width: 100%;
  max-height: 100%;
}

.setting-business-page-form-signature-text{
  margin-top: 5px;
  color: #8f8f8f;
  font-size: 13px;
}
.setting-business-page-form-signature-text-link{
  color: #d01717;
  cursor: pointer;
  font-weight: 600;
  width: max-content;
}

.setting-business-page-form-col-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.setting-business-page-form-col-select {
  padding-right: 5px;
}
.setting-business-page-form-col-select > select {
  padding: 0;
  padding-left: 15px;
  background-color: transparent;
  border: none;
  margin-left: -15px;
  appearance: none; /* Remove the default dropdown arrow */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none;
}

.setting-business-page-form-col-dropdown{
    position: relative;
}
.setting-business-page-form-col-select-options{
    border: solid 1px #d9d4d4;
    border-radius: 5px;
    position: absolute;
    width: 100%;
    background-color: #ffff;
}
.setting-business-page-form-col-option{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: solid 1px #d9d4d4;
    cursor: pointer;
}
.setting-business-page-form-col-option:last-child{
    border: none;
}
.setting-business-page-form-col-option i{
    font-size: 20px;
    color: #d9d4d4;
}
.setting-business-page-form-col-option .fa-square-check{
    color: #488dff;
}

.setting-business-page-form-col-dropdown-select{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 33px;
    line-height: 1;
}

.setting-business-page-form-radio-options{
    display: flex;
    gap: 10px;
}
.setting-business-page-form-radio{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    border: solid 1px #d9d4d4;
    border-radius: 5px;
    min-height: 32px;
    width: 150px;
    cursor: pointer;
}
.setting-business-page-form-radio input{
    width:  18px;
    height: 18px;
}


/***************************************************************************************************************************/
.setting-business-page-form-company{
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.setting-business-page-form-company-logo-option{
  text-align: center;
  font-size: 13px;
  font-weight: 600;
}
.setting-business-page-form-company-logo-option>span{
  color: #488dff;
  cursor: pointer;
}
.setting-business-page-form-company-logo-option>div{
  color: #d01717;
  cursor: pointer;
}

.setting-business-page-form-company-logo-image{
  /* width: 120px;
  height: 120px; */
  /* border: solid 1px; */
  text-align: center;
}
.setting-business-page-form-company-logo-image>img{
  /* margin: auto; */
  max-width: 120px;
  max-height: 120px;
}

.setting-business-page-form-company > .setting-business-page-form-col{
  flex: 1;
}



/***************************************************************************************************************************/

.setting-business-page-form-signature-popup{
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000090;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.setting-business-page-form-signature-popup-main{
  background-color: #ffff;
  border-radius: 10px;
}
.setting-business-page-form-signature-popup-top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-bottom: solid 1px #d9d4d4;
}
.setting-business-page-form-signature-popup-top i{
  font-size: 20px;
  cursor: pointer;
}
.setting-business-page-form-signature-popup-main>
.setting-business-page-form-row{
  padding: 15px;

}

.setting-business-page-form-signature-popup-col{
  display: grid;
  gap: 20px;
  text-align: center;
  width: 160px;
  border: solid 1px #d9d4d4;
  padding: 20px;
  border-radius: 5px;
  font-size: 13px;
}
.setting-business-page-form-signature-popup-col:hover{
  cursor: pointer;
  border: solid 1px #4c3cce;
}
.setting-business-page-form-signature-popup-col:first-child>img{
  height: 35px;
}
.setting-business-page-form-signature-popup-col>img{
  margin: auto;
  height: 30px;
}

/***************************************************************************************************************************/
/* create new business **************************************************************************************************************************/
/***************************************************************************************************************************/

.setting-create-business{
  background-color: #fff;
  padding: 0 10px;
  border-radius: 10px;
}






/***************************************************************************************************************************/
/***************************************************************************************************************************/

@media only screen and (max-width: 1000px) {

  
.setting-business-page-form {
  padding: 20px;
  height: 100%;
  overflow: auto;
  padding-top: 70px;
}
  
  .setting-business-page-button-cancel,
  .setting-business-page-sub-heading{
    display: none;
  }

  .setting-business-page-head{
    padding: 10px;
    position: fixed;
    background-color: #fff;
    width: 100%;
    z-index: 1;
  }
  
  .setting-business-page-head-col{
    gap: 10px;
  }

  .setting-business-page-button-off{
    padding: 3px 20px;
  }
  
  .setting-business-page-form-row{
    grid-template-columns: repeat(1, 1fr);
  }
  
  .setting-business-page-form-section-1{
    padding: 0;
  }
  
}





