


.select-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-form-main {
  background-color: white;
  border-radius: 10px;
  height: calc(100vh - 50px);
}

.select-form-top {
  border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: rgb(from var(--main) r g b / 15%);
  min-width: 600px;
  justify-content: space-between;
  padding: 10px 20px;
}
.select-form-top-heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--main);
}

/* ------------------------------------------------------------------------------ */

.select-party-form-data-list-table {
  margin-top: 5px;
}
.select-party-form-data-list-table-body,
.select-party-form-data-list-table-head {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.select-party-form-data-list-table-body:hover {
  background-color: rgb(from var(--main) r g b / 45%) !important;
  cursor: pointer;
}
.select-form-data-list-table-body > div,
.select-form-data-list-table-head > div {
  padding: 10px 20px;
  /* text-align: center; */
}

.select-form-data-list-table-head {
  background-color: rgb(from var(--main) r g b / 100%);
  color: white;
  font-weight: 600;
  font-size: 1.1rem;
}

.select-form-data-list-table-list > div:nth-child(even) {
  background-color: rgb(from var(--main) r g b / 10%);
}

/* .select-item-form-data-list-table-body-selected:nth-child(even) {
  background-color: #ff000015 !important;
  } */
  .select-item-form-data-list-table-body-selected{
    border-bottom: solid 3px #ff000045;
  }
  
  .select-item-form-data-list-table-body-selected:nth-child(even){
    background-color: #ff000015 !important;
  }

.select-form-data-list-table-list {
  height: calc(100vh - 220px);
  overflow: auto;
}

/* ------------------------------------------------------------------------------ */

.select-form-end {
  border-radius: 10px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  /* background-color: rgb(from var(--main) r g b / 15%); */
  min-width: 600px;
  padding: 10px 20px;
  gap: 20px;
  margin-top: 5px;
}
.select-form-end > div {
  font-weight: 600;
  padding: 1px 30px;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
}

.select-form-end > div:nth-child(1) {
  border: solid 4px var(--main);
  background-color: var(--main);
  color: var(--main);
  color: white;
}

.select-form-end > div:nth-child(2) {
  color: red;
  border: solid 2px red;
}


/* ************************************************************************************************************ */

.select-item-form-data-list-table{
    min-width: 950px;
}


.select-item-form-data-list-table-head,
.select-item-form-data-list-table-body{
    display: grid;
    grid-template-columns: 1.7fr 1.2fr .5fr 1.2fr 1fr;
}

.select-item-data-list-table-head,
.select-item-data-list-table-body{
    display: grid;
    grid-template-columns: 1.7fr 1.2fr .5fr 1.2fr;
}

.select-item-form-data-list-table-head,
.select-item-form-data-list-table-body{
    text-align: center;
}
.select-item-form-data-list-table-head>div:nth-child(1),
.select-item-form-data-list-table-body>div:nth-child(1){
    text-align: start;
}

.select-item-form-data-list-table-body>div:last-child{
    padding: 15px;
  }
  .select-form-data-list-add{
    background-color: rgb(from var(--main) r g b / 10%);
    border: solid 1px rgb(from var(--main) r g b / 30%);
    color: var(--main);
    font-weight: 600;
    padding: 4px 30px;
    border-radius: 5px;
    cursor: pointer;
  }
  .select-form-data-list-remove{
  cursor: pointer;
    background-color: #ff000010;
    border: solid 1px #ff000030;
    color: #ff0000;
    font-weight: 600;
    padding: 4px 30px;
    border-radius: 5px;
}

.select-form-data-list-table-body>div:last-child{
  margin: auto;
}

.select-form-data-list-del{
  display: flex;
  cursor: pointer;
  color: #ff0000;
  font-size: 1.3rem;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.select-form-data-list-del>input{
  width: 60px;
  text-align: center;
  border: solid 2px #ffbdbd ;
  padding: 2px ;
  font-size: 1rem;
  border-radius: 5px;
}

.select-form-data-list-stock{
  font-weight: 600;
  font-size: 1.1rem;
}
.select-form-data-list-stock-disable{
  font-size: .8rem;
  opacity: .8;
}
.select-form-data-list-name{
  display: grid;
}
.select-form-data-list-name>div>span{
  background-color: #17455542;
  padding: 0px 10px;
  border-radius: 20px;
  font-size: .9rem;
  font-weight: 500;
}

/* -------------------------------------------------------------------------------- */

.app-form-popup{
  position: fixed;
  z-index: 3;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.459);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-form-popup>div{
  background-color: #ff0000 !important;
  width: 1000px;
}




