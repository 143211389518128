
.features{
    background-color: #17455515;
    background-color: #3c90ac20;
    padding: 50px 0;

}
.features-main{
    max-width: 1200px;
    margin: auto;
}
.features-heading{
    font-family: "Roboto Slab";
    font-size: 2.8rem;
    font-weight: 700;
    text-align: center;
    color: #174555;
    margin-bottom: 40px;
}
.features-content{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.features-content>div{
    padding: 30px 40px;
}

.features-content-col-icon{
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    background-color: #174555;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 20px;
}

.features-content-col-icon>img{
    max-width:  100%;
    max-height: 100%;
    padding: 12px;
    
    filter: drop-shadow(0px 100px 0 #ffffff);
  transform: translateY(-100px);

}

.features-content-col-heading{
    font-size: 1.2rem;
    font-weight: 600;
    margin: 10px 0;
}
.features-content-col-text{
    font-size: .9rem;
}


/* border --------------------------------------------------------------------------- */

/* .features-content-border-left{
    border-left: solid 2px #17455520;
} */

.features-content-border-right{
    border-right: solid 2px #58c0e225;
}

.features-content-border-bottom{
    border-bottom: solid 2px #58c0e225;
}

/* --------------------------------------------------------------------------- */

@media only screen and (max-width: 720px) {
    .features-content-border-right{
        border: none;
    }
    
    .features-content-border-bottom{
        border: none;
    }
    
    .features-content{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .features-content>div{
        display: flex;
        align-items: center;
        padding: 20px !important;
        gap: 10px;
        border-bottom: solid 2px #58c0e225;
        
    }
    .features-content>div:last-child{
        border: none;
    }
    
    
.features-content-col-icon{
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    background-color: #174555;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 20px;
}

.features-content-col-icon>img{
    max-width:  100%;
    max-height: 100%;
    padding: 12px;
    
    filter: drop-shadow(0px 100px 0 #ffffff) !important;
  transform: translateY(-100px) !important;

}
    
    
    .features-heading{
        font-size: 2rem;
        margin-bottom: 10px;
    }
    
        .features-content-col-blank{
            display: none !important;
        }
    
    
}


