
.why-invoician-perfect{
    background-color: #ed1a3a14;
}

.why-invoician-perfect-main{
    width: 85%;
    margin: auto;
    padding: 60px 0;
    text-align: center;
}

.why-invoician-perfect-heading{
    font-size: 1.9rem;
    font-weight: 600;
    margin-bottom: 40px;
}

.why-invoician-perfect-content{
    background-color: #fff;
    width: max-content;
    margin: auto;
    display: flex;
    border-radius: 30px;
    padding: 30px;
}

.why-invoician-perfect-content-image{
    height: 70px;
    padding: 20px 0;
}
.why-invoician-perfect-content-image>img{
    height: 100%;
}


.why-invoician-perfect-content-heading{
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.1;
    height: 80px;
    padding: 20px ;
}

.why-invoician-perfect-content-points>div{
    border-top: solid 1px #ed1a3a38 ;
    color: #314259;
    height: 40px;
    align-content: center;
    padding: 0 20px;

}

.why-invoician-perfect-content-col-1>
.why-invoician-perfect-content-points{
    text-align: start;
}

.why-invoician-perfect-content-col-3{
    border: solid 1px var(--web-main) ;
    border-radius: 10px;
    background-color: #ffc6ce14;
    margin: -1px;
}

.why-invoician-perfect-content-col-3>
.why-invoician-perfect-content-points>div:first-child{
    color: var(--web-main);
    font-weight: 600;
}

.why-invoician-perfect-content-points
.fa-circle-check {
    color: #4bae4f;
    font-size: 1.9rem;
}

.why-invoician-perfect-content-points
.fa-circle-xmark {
    color: var(--web-main);
    font-size: 1.9rem;
}


.why-invoician-perfect-image{
    display: none;
}


@media only screen and (max-width: 1000px) {
    
    
    .why-invoician-perfect-content{
        display: none;
    }

    
    
.why-invoician-perfect-main{
    width: 100%;
    padding: 40px 15px;
}

.why-invoician-perfect-heading{
    font-size: 1.3rem;
    margin-bottom: 20px;
}

.why-invoician-perfect-image{
    display: block;
    width: 100%;
    border-radius: 10px;
    
}
.why-invoician-perfect-image>img{
    width: 100%;
}
    
    
}



