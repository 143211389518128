.app-expense-page {
  font-size: 14px;
}

.app-expense-page-content-table {
  margin: 20px;
  border: solid 1px #d9d4d4;
  border-radius: 5px;
}
.app-expense-page-content-table-head,
.app-expense-page-content-table-data-body {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.app-expense-page-content-table-data-body > div,
.app-expense-page-content-table-head > div {
  background-color: #f0f1f3;
  font-weight: 600;
  padding: 10px 12px;
  border-right: solid 1px #d9d4d4;
}
.app-expense-page-content-table-data-body > div {
  font-weight: 400;
  background-color: transparent;
  border-top: solid 1px #d9d4d4;
}
.app-expense-page-content-table-data {
  max-height: calc(100vh - 150px);
  overflow: auto;
}
.app-expense-page-content-table-data-body-hover:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}
.app-expense-page-content-table-data-empty {
  border-top: solid 1px #d9d4d4;
  font-size: 20px;
  text-align: center;
  padding: 80px 40px;
}

.app-expense-page-content-table-data::-webkit-scrollbar {
  width: 8px;
}
.app-expense-page-content-table-data::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 10px;
}

/* ------------------------------------------------------------------------------------------------------------ */

.create-expense-page {
  font-size: 14px;
}
.create-expense-page input,
.create-expense-page select,
.create-expense-page textarea {
  border: solid 1px #d9d4d4;
  padding: 8px 12px;
  border-radius: 5px;
  height: max-content;
}
.create-expense-page label {
  color: #667085;
  font-weight: 500;
  margin-bottom: 5px;
}

.create-expense-page-main-top-heading {
  display: flex;
  align-items: center;
  gap: 20px;
}

.create-expense-page-content {
  /* max-height: calc(100vh - 70px); */
  overflow: auto;
}
.create-expense-page-content::-webkit-scrollbar {
  width: 8px;
}
.create-expense-page-content::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 10px;
}

.create-expense-page-content-section-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  background-color: #e8eff9;
  padding: 20px;
  margin: 0 10px;
}

.create-expense-page-content-section-1 > div {
  background-color: #fff;
  padding: 20px;
  /* display: grid; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 5px;
}

.create-expense-page-content-section-1-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.create-expense-page-content-section-1-col {
  display: grid;
  height: max-content;
}

.create-expense-page-content-section-1-gst {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: solid 1px #d9d4d4;
  border-radius: 5px;
}
.create-expense-page-content-section-1-gst input {
  height: 18px;
  width: 18px;
}

.create-expense-page-content-section-2 {
  padding: 20px;
  width: 80%;
}

.create-expense-page-content-section-2-add {
  font-size: 16px;
  font-weight: 600;
  color: #488dff;
  text-align: center;
  padding: 5px;
  border: dashed 2px;
  cursor: pointer;
}
.create-expense-page-total-amount {
  margin-top: 20px;
  padding-top: 10px;
  border-top: solid 1px #d9d4d4;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.create-expense-page-total-amount input {
  height: max-content;
  width: 250px;
}

/* ------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------ */

.create-expense-page-content-section-3 textarea,
.create-expense-page-content-section-3 select,
.create-expense-page-content-section-3 input {
  background-color: #e9ecf1;
  border: none;
  padding: 5px;
  border-radius: 5px;
  outline: none;
  font-size: 12px;
}

.create-expense-page-content-table {
  border: solid 1px #d9d4d4;
  margin-bottom: 10px;
  border-bottom: none;
  border-right: none;
  border-radius: 10px 10px 0 0;
}
.create-expense-page-content-table-body,
.create-expense-page-content-table-head {
  display: grid;
  grid-template-columns: 0.8fr 3fr 2fr 2fr 2fr 0.7fr;
}
.create-expense-page-content-table-head {
  border-radius: 10px;
  background-color: #f7f7f7;
  font-weight: 500;
}
.create-expense-page-content-table-head > div,
.create-expense-page-content-table-body > div {
  border-right: solid 1px #d9d4d4;
  border-bottom: solid 1px #d9d4d4;
  padding: 10px;
}

.create-expense-page-content-table-body input {
  width: 100%;
  background-color: #e9ecf1;
  border: none;
  padding: 5px;
  border-radius: 5px;
  outline: none;
  font-size: 12px;
}
.create-expense-page-content-table-body i {
  cursor: pointer;
}

/* ------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------ */

.view-expense-component-top-edit {
  font-size: 14px !important;
  cursor: pointer;
}
.view-expense-component-top-delete {
  font-size: 14px !important;
  color: #ff0000;
  border-color: #ff0000 !important;
  cursor: pointer;
}

.view-expense-content {
  margin: 20px;
}

.view-expense-content-details-data {
  border: solid 1px #d9d4d4;
  border-radius: 10px;
}
.view-expense-content-details-data > .app-staff-page-details-data-row-4 {
  padding: 20px;
}

.view-expense-content-table-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 15px;
  gap: 10px;
  font-size: 16px;
}
.view-expense-content-table-end-margin {
  margin-top: -15px;
}
.view-expense-content-table-end > div {
  padding: 10px;
  border: solid 1px #d9d4d4;
  min-width: 180px;
  border-top: none;
}

.view-expense-content-table-body,
.view-expense-content-table-head {
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
}
.view-party-page-transaction-data-small,
.app-expense-page-content-small {
  display: none;
}
/* ------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------ */

@media only screen and (max-width: 1000px) {
  .app-expense-page-top-heading {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    font-weight: 500;
  }
  .app-expense-page-top-button-create {
    padding: 3px 15px;
  }
  .app-expense-page-main {
    height: 100%;
    overflow: auto;
  }
  .app-expense-page-content-table {
    display: none;
  }

  .app-expense-page-content-small {
    display: block;
    padding: 45px 15px 30px;
  }

  .view-expense-component-top-edit {
    background-color: #174555;
    color: #fff;
    border-color: #174555 !important;
  }
  .view-expense-component-top-edit > span {
    display: none;
  }

  .app-staff-page-details-data-row-4 {
    grid-template-columns: repeat(2, 1fr);
  }

  .create-expense-page-main-top{
    z-index: 1;
  }


  .create-expense-page-content {
    position: fixed;
    top: 0;
    width: 100%;
    max-height: none;
    height: 100%;
    overflow: auto;
    padding: 45px 0 10px;
    background-color: #fff;
  }

  .create-expense-page-content-section-1 {
    grid-template-columns: repeat(1, 1fr);
    margin: 0;
  }

  .create-expense-page-content-section-2 {
    width: 100%;
  }

  .create-expense-page-content-table {
    display: none;
  }

  .create-expense-page-content-section-2 {
    padding: 0;
  }
  .create-expense-page-content .create-invoice-item-list-heading {
    background-color: #fff;
    padding: 10px;
  }
  .create-expense-page-content .invoice-small-page-select-item-col {
    margin: 15px;
  }

  .create-expense-page-content-section-2-add {
    margin: 15px;
  }
  .create-expense-page-total-amount {
    padding: 15px;
  }

 
  .view-expense-content-details-data > .app-staff-page-details-data-row-4 {
    padding: 10px;
    gap: 25px 15px;
  }
  .view-party-page-transaction-data-small{
    display: block;
    border: solid 1px #3333;
    margin: 15px 0;
    border-radius: 10px;
  }
  .view-party-page-transaction-data-small-col{
    border-top: solid 1px #3333;
    padding: 10px;
  }

  .view-party-page-transaction-data-small>
  .view-party-page-transaction-data-small-col:first-child{
    border-top: none;
  }
  .view-party-page-transaction-data-small-col-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .view-party-page-transaction-data-small-name{
    font-weight: 600;
  }
  .view-party-page-transaction-data-small-close,
  .view-party-page-transaction-data-small-open{
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #488dff;
  }
    
  .view-party-page-transaction-data-small-close{
    color: #ff0000;
  }
  
  .view-party-page-transaction-data-small-view-col{
    display: flex;
    gap: 5px;
  }
  .view-party-page-transaction-data-small-view-col>div{
    color: #888;
    font-weight: 600;
  }
  .view-expense-content-table-end{
    margin: 0 8px;
    font-size: 14px;
    
  }
  .view-expense-content-table-end > div{
    padding: 5px 10px;
    min-width: 150px;
  }
  .view-expense-content-table-end > div b{
    font-weight: 600;
  }
  .view-expense-content{
    height: 100%;
    overflow: auto;
    margin: 10px;
  }

  .create-expense-page-main{
    height: 100%;
  }
  
  .create-expense-page-main-top-heading{
    gap: 10px;
  }
  
}
