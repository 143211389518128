.create-invoice-page {
  background-color: #f0f1f3;
  /* padding: 10px; */
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 14px;
  color: #28313b;
}

.create-invoice-page-main {
  /* width: calc(100% - 250px); */
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.create-party-top,
.create-invoice-page-main-top {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}

.create-party-top-heading,
.create-invoice-page-main-top-heading {
  font-size: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.create-invoice-page-main-top-heading i {
  cursor: pointer;
}

.create-invoice-page-main-top-buttons {
  display: flex;
  gap: 10px;
}

.create-party-top-buttons > div,
.create-party-top-buttons > button,
.create-invoice-page-main-top-buttons > button,
.create-invoice-page-main-top-buttons > div {
  border: solid 1px;
  padding: 5px 40px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
}

.create-invoice-page-main-top-button-setting {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 4px 10px !important;
  border-color: #d9d4d4 !important;
}

.create-invoice-page-main-top-button-setting i {
  color: #9da7b7;
  font-size: 18px;
}

.create-party-top-buttons > button,
.create-invoice-page-main-top-buttons > button,
.create-invoice-page-main-top-button-save {
  color: white;
  background-color: #4c3cce;
  background-color: #174555;
  border-color: #4c3cce !important;
  border-color: #174555 !important;
}
.create-invoice-page-main-top-button-save {
  opacity: 0.3;
}

.create-invoice-page-main-top-button-save-new {
  border-color: #4c3cce !important;
  color: #4c3cce;
}

/* -------------------------------------------------------------------------------------------------------------------------- */

.create-invoice-page-content textarea,
.create-invoice-page-content input {
  background-color: #e9ecf1;
  border: none;
  padding: 5px;
  border-radius: 5px;
  outline: none;
  font-size: 12px;
}

.create-invoice-page-form {
  max-height: calc(100vh - 70px);
  overflow: auto;
}

.create-invoice-page-form::-webkit-scrollbar {
  width: 8px;
}
.create-invoice-page-form::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 5px;
}

.create-invoice-page-content {
  border: solid 1px #d9d4d4;
  margin: 10px 20px 20px;
  border-radius: 10px;
}

.create-invoice-page-head {
  display: flex;
  border-bottom: solid 1px #d9d4d4;
}

.create-invoice-page-head-add-party-option {
  color: #488dff;
  border: dashed 1px;
  width: max-content;
  padding: 50px 100px;
  cursor: pointer;
  margin: 20px;
  font-size: 16px;
  background-color: #fff;
  z-index: 1;
  position: relative;
}

.create-invoice-page-head-add-party {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 450px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  z-index: 2;
}

.create-invoice-page-head-add-party-search {
  padding: 10px;
}

.create-invoice-page-head-add-party-search > input {
  width: 100%;
  border: solid 1px #d9d4d4;
  background-color: white;
  padding: 8px;
  font-size: 14px;
}

.create-invoice-page-head-add-party-head,
.create-invoice-page-head-add-party-data-row {
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: solid 1px #d9d4d4;
  display: flex;
  align-items: center;
}
.create-invoice-page-head-add-party-data-row:hover {
  background-color: #edebfa;
  cursor: pointer;
}

.create-invoice-page-head-add-party-data {
  height: 130px;
  overflow: auto;
}
.create-invoice-page-head-add-party-data::-webkit-scrollbar {
  width: 7px;
}
.create-invoice-page-head-add-party-data::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 10px;
  cursor: grab;
}

.create-invoice-page-head-add-party-data-row > div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.create-invoice-page-head-add-party-data-empty {
  text-align: center;
  padding: 55px;
  margin-top: -130px;
}

.create-invoice-page-head-add-party-head {
  color: #787878;
  /* border: none; */
  padding: 5px 10px;
}

.create-invoice-page-head-add-party-create {
  border-top: solid 1px #d9d4d4;
  padding: 10px;
}

.create-invoice-page-head-add-party-create > div {
  border: #488dff dashed 1px;
  color: #488dff;
  padding: 8px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}

.create-invoice-page-head-add-party-data-row .fa-arrow-up {
  color: #d01717;
  font-size: 18px;
}
.create-invoice-page-head-add-party-data-row .fa-arrow-down {
  color: #0ebe2a;
  font-size: 18px;
}

/* ------------------ */
.create-invoice-page-head-address {
  width: 70%;
  position: relative;
}

.create-invoice-page-head-address > div {
  border-right: solid 1px #d9d4d4;
}

.create-invoice-page-head-address-head {
  border-bottom: solid 1px #d9d4d4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 20px;
  /* font-size: 16px; */
  /* font-weight: 500; */
  min-height: 45px;
}

.create-invoice-page-head-address-head > div {
  border: solid 1px #d9d4d4;
  padding: 2px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.create-invoice-page-head-address-content {
  padding: 15px 10px 15px 15px;
}
.create-invoice-page-head-address {
  display: flex;
}

.create-invoice-page-head-address > div {
  width: 100%;
}

.create-invoice-page-head-address-name {
  font-weight: 700;
}

.create-invoice-page-head-address-col {
  display: flex;
  gap: 5px;
  margin: 5px 0;
}

.create-invoice-page-head-address-col > span {
  color: #8d939b;
  font-size: 12px;
}

.create-invoice-page-head-options {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.create-invoice-page-head-options-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 250px;
}

.create-invoice-page-head-options-col {
  display: grid;
  gap: 5px;
}
.create-invoice-page-head-options-col > label {
  color: #8d939b;
  font-size: 12px;
}
.create-invoice-page-head-options-col > input {
  width: 100px;
}

.create-invoice-page-head-options-terms {
  width: 100px;
  display: flex;
  align-items: center;
  background-color: #e9ecf1;
  border: solid 1px #d9d4d4;
  border-radius: 5px;
}
.create-invoice-page-head-options-terms input {
  width: 100%;
  background-color: white;
  border-radius: 5px 0 0 5px;
}

.create-invoice-page-head-options-terms span {
  padding: 0 5px;
  font-size: 12px;
}

.create-invoice-page-head-options-due {
  border: dashed 1px #d9d4d4;
  border-radius: 5px;
  padding: 8px 10px;
}

.create-invoice-page-head-options-due-option {
  border: dashed 1px #488dff;
  color: #488dff;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.create-invoice-page-head-options-due-close {
  text-align: end;
  font-size: 22px;
  color: #9da7b7;
  margin: -20px;
  margin-bottom: -10px;
}
.create-invoice-page-head-options-due-close i {
  cursor: pointer;
}
/* -------------------------------------------------------------------------------------------------------------------------- */

.create-invoice-page-table > div {
  display: flex;
}

.create-invoice-page-table-sno {
  width: 4%;
}
.create-invoice-page-table-items {
  /* width : 7.13% */
  flex: 1;
}

.create-invoice-page-table-items div {
  font-size: 11px;
  color: #999999;
}

.create-invoice-page-table-hsn {
  width: 7.13%;
}
.create-invoice-page-table-qty {
  width: 7.13%;
}
.create-invoice-page-table-price {
  width: 9%;
}
.create-invoice-page-table-discount {
  width: 7.13%;
}
.create-invoice-page-table-tax {
  width: 9%;
  position: relative;
}
.create-invoice-page-table-total {
  width: 9%;
}
.create-invoice-page-table-add,
.create-invoice-page-table-del {
  width: 4%;
  font-size: 15px;
}

.create-invoice-page-table-add i {
  background-color: #9da7b7;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  align-content: center;
  cursor: pointer;
}
.create-invoice-page-table-del i {
  cursor: pointer;
}

.create-invoice-page-table {
  border-top: solid 1px #d9d4d4;
  border-left: solid 1px #d9d4d4;
  margin: 20px;
  font-size: 12px;
}

.create-invoice-page-table > div > div {
  border-bottom: solid 1px #d9d4d4;
  border-right: solid 1px #d9d4d4;
  padding: 6px;
}

.create-invoice-page-table-head {
  background-color: #f7f7f7;
  font-weight: 500;
}
.create-invoice-page-table-head > div {
  padding: 10px !important;
}
.create-invoice-page-table-body input {
  width: 100%;
}

.create-invoice-page-table-qty > input {
  max-width: 41px;
}

.create-invoice-page-table-discount > div:nth-child(1) {
  margin-bottom: 5px;
}

.create-invoice-page-table-total > div,
.create-invoice-page-table-discount > div {
  display: flex;
  align-items: center;
  background-color: #e9ecf1;

  border-radius: 5px;
  padding-left: 5px;
}

.create-invoice-page-table-tax-select {
  position: absolute;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 170px;
  z-index: 1;
}

.create-invoice-page-table-tax-option {
  padding: 5px 15px;
  cursor: pointer;
  border-bottom: solid 1px #d9d4d4;
}
.create-invoice-page-table-tax-option:hover {
  background-color: #f0f0f0;
}

/* ...................................... */

.create-invoice-page-table-add-item-option {
  border: dashed #488dff 1px;
  margin: 20px 30px;
  font-size: 16px;
  color: #488dff;
  text-align: center;
  padding: 10px;
  font-weight: 500;
  cursor: pointer;
  /* position: relative;
  background-color: #fff;
  z-index: 1; */
}

.create-invoice-page-table-sub-total {
  background-color: #f7f7f7;
  display: flex;
  justify-content: flex-end;

  border-top: solid 1px #d9d4d4;
  border-bottom: solid 1px #d9d4d4;
}
.create-invoice-page-table-sub-total > div {
  padding: 10px;
  font-weight: 500;
  padding-left: 5px;
}

.create-invoice-page-table-sub-total > div:nth-child(2) {
  width: 7.13%;
  border-left: solid 1px #d9d4d4;
}

.create-invoice-page-table-sub-total > div:nth-child(3) {
  width: 9%;
  border-left: solid 1px #d9d4d4;
}

.create-invoice-page-table-sub-total > div:nth-child(4) {
  width: 13%;
  border-left: solid 1px #d9d4d4;
}

/* -------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------- */

.create-invoice-page-end {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.create-invoice-page-end > div:first-child {
  border-right: solid 1px #d9d4d4;
}
/* .create-invoice-page-end-col-1{ */

.create-invoice-page-end-bank {
  padding: 0 20px;
  border-top: solid 1px #d9d4d4;
}
.create-invoice-page-end-bank-head {
  color: #488dff;
  font-weight: 500;
  margin-top: 10px;
}
.create-invoice-page-end-bank-col {
  display: flex;
  margin-bottom: 5px;
  gap: 5px;
}
.create-invoice-page-end-bank-col > div {
  color: #687e96;
}
.create-invoice-page-end-bank-col > span {
  font-weight: 600;
}

.create-invoice-page-end-bank th {
  text-align: start;
  font-weight: 600;
}
.create-invoice-page-end-bank table td {
  padding-right: 10px;
}

.create-invoice-page-end > div {
  padding: 20px 0;
}

.create-invoice-page-end-form {
  margin: 0px 20px 15px;
}

.create-invoice-page-end-link {
  color: #488dff;
  font-weight: 500;
  cursor: pointer;
  margin: 0px 20px 15px;
  width: max-content;
}

.create-invoice-page-end-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.create-invoice-page-end-head i {
  cursor: pointer;
}
.create-invoice-page-end-form textarea {
  width: 100%;
  margin: 5px 0 -5px;
}

.create-invoice-page-end-amount-row {
  display: flex;
  justify-content: space-between;
  margin: 5px 20px;
}

.create-invoice-page-end-amount-discount {
  padding: 10px 0;
}

.create-invoice-page-end-amount-discount-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin: 0 20px;
}

.create-invoice-page-end-amount-discount-col {
  display: flex;
  gap: 5px;
  align-items: center;
}
.create-invoice-page-end-amount-discount-input {
  background-color: #e9ecf1;
  padding: 0 5px;
  border-radius: 5px;
}
.create-invoice-page-end-amount-discount-input > input {
  width: 100px;
}
.create-invoice-page-end-amount-discount-col
  .create-invoice-page-end-amount-discount-input:first-child
  > input {
  width: 40px;
}

.create-invoice-page-end-amount-discount select {
  padding: 5px;
  border: solid 1px #d9d4d4;
  border-radius: 5px;
}

.create-invoice-page-end-amount-discount i {
  cursor: pointer;
}
.create-invoice-page-end-hr {
  border-top: solid 1px #d9d4d4;
  margin: 15px 0;
}

.create-invoice-page-end-option {
  display: flex;
  gap: 8px;
  margin: 0 20px;
  cursor: pointer;
  width: max-content;
}
.create-invoice-page-end-option i {
  font-size: 20px;
}

.create-invoice-page-end-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  margin: 10px 20px;
  font-weight: 600;
}
.create-invoice-page-end-total > div:last-child {
  font-size: 20px;
}

.create-invoice-page-end-received {
  margin: 0 20px;
}

.create-invoice-page-end-received-input {
  display: flex;
  align-items: center;
}
.create-invoice-page-end-received-input > input {
  width: 120px;
}

.create-invoice-page-end-received-option {
  display: flex;
  justify-content: flex-end;
}

.create-invoice-page-end-received-option > div {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}

.create-invoice-page-end-received-option > div i {
  font-size: 20px;
}

.create-invoice-page-end-received-row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.create-invoice-page-end-received-row > select {
  padding: 8px;
  border-radius: 5px;
  border: solid 1px #d9d4d4;
  outline: none;
}

.create-invoice-page-end-received-col {
  display: flex;
  border: solid 1px #d9d4d4;
  background-color: #e9ecf1;
  padding-left: 10px;
  border-radius: 5px;
}

.create-invoice-page-end-received-col select {
  padding: 5px;
  border: none;
  border-left: solid 1px #d9d4d4;
}

.create-invoice-page-end-balance {
  display: flex;
  justify-content: space-between;
  color: #2cc646;
  font-weight: 500;
  margin: 0 20px;
}

.create-invoice-page-end-balance > div:last-child {
  font-size: 16px;
}

.create-invoice-page-end-signature {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin: 0 20px;
  gap: 5px;
}

.create-invoice-page-end-signature-option {
  color: #488dff;
  border: dashed 1px #488dff;
  font-size: 16px;
  font-weight: 500;
  padding: 30px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.create-invoice-page-end-signature-box {
  border: solid 1px #000000;
  width: 250px;
  height: 90px;
}

.create-invoice-page-end-signature-image {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.create-invoice-page-end-signature-image > img {
  margin-top: 30px;
  max-width: 150px;
  max-height: 110px;
}

/* add items -------------------------------------------------------------------------------------------------------------------------- */

.create-invoice-page-table-add-item {
  background-color: #00000090;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  /* display: none; */
}
.create-invoice-page-table-add-item-main {
  background-color: white;
  width: 1140px;
  border-radius: 10px;
}

.create-invoice-page-table-add-item-top {
  padding: 15px;
  border-bottom: solid 1px #d9d4d4;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-invoice-page-table-add-item-top-close {
  background-color: #d01717;
  color: white;
  padding: 3px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
}
.create-invoice-page-table-add-item-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.create-invoice-page-table-add-item-head-search {
  border: solid 1px #d9d4d4;
  border-radius: 5px;
  width: 36%;
  display: flex;
  align-items: center;
}
.create-invoice-page-table-add-item-head-search > i {
  padding: 0 5px 0 10px;
  font-size: 18px;
  color: #787878;
}
.create-invoice-page-table-add-item-head-search > input {
  background-color: transparent;
  flex: 1;
  font-size: 14px;
}

.create-invoice-page-table-add-item-head-create {
  border: solid #488dff 1px;
  color: #488dff;
  font-weight: 500;
  text-align: center;
  padding: 5px;
  width: 50%;
  border-radius: 5px;
  cursor: pointer;
}

.create-expense-page-table-add-item-table-body-row,
.create-expense-page-table-add-item-table-head,
.create-invoice-page-table-add-item-table-body-row,
.create-invoice-page-table-add-item-table-head {
  display: flex;
  border-bottom: solid 1px #d9d4d4;
}

.create-invoice-page-table-add-item-table-name {
  width: 30%;
}
.create-invoice-page-table-add-item-table-code {
  width: 18%;
}
.create-invoice-page-table-add-item-table-sale {
  width: 18%;
}
.create-invoice-page-table-add-item-table-purchase {
  width: 18%;
}
.create-invoice-page-table-add-item-table-stock {
  width: 18%;
}
.create-invoice-page-table-add-item-table-quantity {
  width: 15%;
}

.create-expense-page-table-add-item-table-head > div,
.create-invoice-page-table-add-item-table-head > div {
  padding: 15px 12px;
  font-size: 12px;
  color: #687e96;
}

.create-expense-page-table-add-item-table-body,
.create-invoice-page-table-add-item-table-body {
  height: 54vh;
  overflow: auto;
  border-top: solid 1px #d9d4d4;
  border-bottom: solid 1px #d9d4d4;
}

.create-expense-page-table-add-item-table-body::-webkit-scrollbar,
.create-invoice-page-table-add-item-table-body::-webkit-scrollbar {
  width: 8px;
}

.create-expense-page-table-add-item-table-body::-webkit-scrollbar-thumb,
.create-invoice-page-table-add-item-table-body::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 10px;
}

.create-expense-page-table-add-item-table-body-row > div,
.create-invoice-page-table-add-item-table-body-row > div {
  padding: 10px 12px;
}

.create-expense-page-table-add-item-table-name,
.create-invoice-page-table-add-item-table-name {
  display: flex;
  gap: 5px;
}

.create-expense-page-table-add-item-table-name i,
.create-invoice-page-table-add-item-table-name i {
  color: #999999;
  font-size: 20px;
  cursor: pointer;
}

.create-expense-page-table-add-item-table-name .fa-square-check,
.create-invoice-page-table-add-item-table-name .fa-square-check {
  color: #488dff;
}

.create-expense-page-table-add-item-table-add-button,
.create-invoice-page-table-add-item-table-quantity-del,
.create-invoice-page-table-add-item-table-quantity-add {
  color: #488dff;
  font-weight: 500;
  background-color: #488dff1a;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
}
.create-invoice-page-table-add-item-table-quantity-del {
  color: #d01717;
  background-color: #d0171720;
}

.create-expense-page-table-add-item-table-empty,
.create-invoice-page-table-add-item-table-empty {
  padding: 20px;
  text-align: center;
  font-size: 20px;
}

/* ......................................... */

.create-invoice-page-table-add-item-button {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}

.create-invoice-page-table-add-item-button > div {
  background-color: #4c3cce;
  color: white;
  border-radius: 5px;
  padding: 6px 60px;
  font-weight: 500;
  cursor: pointer;
}

.create-expense-page-table-add-item-table-stock,
.create-invoice-page-table-add-item-table-stock > span {
  color: #d01717;
  font-size: 12px;
}

.create-invoice-page-table-add-item-table-quantity-added {
  display: flex;
  align-items: center;
  border: solid 1px #d9d4d4;
  padding-left: 5px;
  border-radius: 5px;
  margin: 0 -5px;
}

.create-invoice-page-table-add-item-table-quantity-added i {
  color: #d01717;
  font-size: 22px;
  cursor: pointer;
}
.create-invoice-page-table-add-item-table-quantity-added input {
  background-color: transparent;
  width: 56px;
  padding: 2px;
  font-size: 14px;
  text-align: center;
}

.create-invoice-page-table-add-item-table-quantity-added div {
  background-color: #f0f0f0;
  border-left: solid 1px #d9d4d4;
  height: 38px;
  margin-left: 2px;
  flex: 1;
  align-items: center;
  align-content: center;
  text-align: center;
}

/* -------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------- */

.create-party-main {
  /* margin: 10px 10px 10px 260px; */
  /* border: solid 1px #d9d4d4; */
  background-color: white;
  border-radius: 10px;
}

.create-party-top {
  border-bottom: solid 1px #d9d4d4;
}

.create-party-form-content {
  height: calc(100vh - 72px);
  overflow: auto;
}

.create-party-form-content::-webkit-scrollbar {
  width: 8px;
}
.create-party-form-content::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 20px;
}

.create-party-form-heading {
  border-top: solid 1px #d9d4d4;
  border-bottom: solid 1px #d9d4d4;
  background-color: #ececec;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
}

.create-party-form {
  padding: 20px;
}

.create-party-form textarea,
.create-party-form select,
.create-party-form input {
  border: 1px solid #ced4da;
  padding: 6px 12px;
  border-radius: 5px;
  outline: none;
  resize: none;
  background-color: white;
  color: #000;
}
.create-party-form input::placeholder {
  color: #999999;
}

.create-party-form-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;
}

.create-party-form-col {
  display: grid;
  gap: 2px;
}
.create-party-form-col label {
  color: #687e96;
}
.create-party-form-col label > span {
  color: #d01717;
  font-weight: 600;
}

.create-party-form-col-balance {
  display: flex;
  border: 1px solid #ced4da;
  align-items: center;
  border-radius: 5px;
}
.create-party-form-col-balance span {
  padding-left: 10px;
}
.create-party-form-col-balance input {
  flex: 1;
  width: 100%;
  border: none;
}

.create-party-form-col-balance select {
  border: none;
  border-left: 1px solid #ced4da;
  border-radius: 0;
  background-color: #f8f9fc;
}

.create-party-form-col-balance div {
  border-left: 1px solid #ced4da;
  background-color: #f8f9fc;
  padding: 6px;
  color: #888888;
}
.create-party-form-col-address {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  color: #687e96;
  align-items: center;
}
.create-party-form-col-address div {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.create-party-form-col-address i {
  color: #28313b;

  font-size: 20px;
}
.create-party-form-col-address-input-disable,
.create-party-form-col-address-input {
  border: solid 1px #ced4da;
  border-radius: 5px;
  padding: 6px 12px;
  min-height: 100px;
  cursor: text;
}

.create-party-form-col-address-input-disable {
  background-color: #e9ecef;
  cursor: no-drop;
}

.create-party-form-col-address-input-disable > div,
.create-party-form-col-address-input > div {
  color: #687e96;
}

.create-party-form-hr {
  border-top: 1px solid #ced4da;
  margin: 30px 0 25px;
}

.create-party-row-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}

.create-expense-page-table-add-item-table-add {
  display: flex;
  align-items: center;
  gap: 10px;
}

.create-expense-page-table-add-item-table-option {
  position: relative;
}
.create-expense-page-table-add-item-table-option > i {
  border: solid 1px #d9d4d4;
  padding: 3px 10px;
  cursor: pointer;
}
.create-expense-page-table-add-item-table-option-list {
  position: absolute;
  background-color: #ffffff;
  margin-left: -50px;
  z-index: 1;
}
.create-expense-page-table-add-item-table-option-list > div {
  border: solid 1px #d9d4d4;
  padding: 6px 15px;
  font-weight: 600;
  font-size: 15px;
}
.create-expense-page-table-add-item-table-option-list > div:first-child {
  color: #4c3cce;
}
.create-expense-page-table-add-item-table-option-list > div:last-child {
  color: #d01717;
}
.create-expense-page-table-add-item-table-option-list > div:hover {
  background-color: #e9ecf1;
  cursor: pointer;
}

/* -------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------- */

.create-invoice-page-main-demo {
  position: fixed;
  background-color: #00000090;
  width: calc(100% - 285px);
  height: calc(100vh - 20px);
  border-radius: 10px;
  z-index: 1;
  overflow: auto;
  padding: 60px 40px;
}

.create-invoice-page-main-demo > div {
  display: flex;
  align-items: center;
  gap: 20px;
  color: white;
  font-weight: 500;
  font-size: 35px;
  /* background-color: #174555; */
  /* padding: 0 50px; */
  width: max-content;
}
.create-invoice-page-main-demo > div > div {
  background-color: #4c3cce;
  font-size: 18px;
  border-radius: 10px;
  padding: 5px 30px;
  cursor: pointer;
}

.create-invoice-page-table-add-item-option-demo {
  position: relative;
  background-color: #fff;
  z-index: 1;
}

.create-invoice-page-end-demo {
  position: relative;
  background-color: #fff;
  z-index: 1;
}

/* .create-invoice-page-end-signature, */
.invoice-small-page-select-item {
  display: none;
}

.create-invoice-item-list {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .create-invoice-page-main {
    position: fixed;
    top: 0;
    z-index: 3;
    height: 100%;
    overflow: auto;
    /* background-color: #000; */
  }

  .create-party-top,
  .create-invoice-page-main-top {
    padding: 10px;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    z-index: 1;
  }

  .create-invoice-page-table-add-item {
    padding: 10px;
    overflow: auto;
    flex-direction: column;
    justify-content: space-evenly;
    z-index: 2;
    height: 100%;
  }
  
  .create-expense-page-table-add-item-main,
  .create-invoice-page-table-add-item-main {
    /* width: auto !important; */
    width: 100% !important;
    border-radius: 10px;
  }

  .create-party-top-heading,
  .create-invoice-page-main-top-heading {
    font-size: 18px;
    gap: 5px;
  }

  .create-party-top-buttons > div,
  .create-party-top-buttons > button,
  .create-invoice-page-main-top-buttons > button,
  .create-invoice-page-main-top-buttons > div {
    padding: 5px 10px;
  }

  .create-invoice-page-form {
    padding: 50px 0;
    max-height: none;
    height: 100%;
  }

  .create-invoice-page-head {
    flex-direction: column-reverse;
  }

  .create-invoice-page-head-address {
    width: 100%;
    flex-direction: column;
  }
  .create-invoice-page-head-address-head {
    min-height: auto;
    padding: 5px 10px;
    background-color: #eeeeee;
  }
  .create-invoice-page-head-address-head > span {
    font-size: 16px;
    font-weight: 500;
  }
  .create-invoice-page-head-address-head > div {
    border: 1px solid #488dff;
    color: #488dff;
    padding: 1px 10px;
    background-color: #fff;
  }

  .create-invoice-page-head-address-name {
    font-weight: 600;
  }

  .create-invoice-page-head-address > div {
    border-right: none;
    /* border-bottom: solid 1px #d9d4d4; */
  }
  .create-invoice-page-head-address > div:nth-child(2) {
    margin-bottom: 40px;
  }
  .create-invoice-page-head-address-content {
    padding: 10px;
  }
  .create-invoice-page-head-options {
    border-bottom: solid 1px #d9d4d4;
  }

  .create-invoice-page-content {
    margin: 10px;
    /* margin-bottom: 30px; */
  }

  .create-invoice-page-head-options-due-option {
    border: dashed 1px #488dff;
    font-weight: 500;
  }

  .create-invoice-page-head-add-party-option {
    /* width: calc(100% - 40px); */
    padding: 8px 50px;
    text-align: center;
    border: dashed 1px #488dff;
    font-weight: 500;
    margin-bottom: 80px;
    z-index: 0;
  }

  .create-invoice-page-head-add-party {
    width: 100%;
  }

  .create-invoice-page-end > div {
    padding: 0px 0;
  }
  .create-invoice-page-end-amount-row {
    margin: 5px 10px;
  }
  .create-invoice-page-end-amount-discount {
    padding: 0;
  }

  .create-invoice-page-end-link {
    margin-left: 10px;
  }

  .create-invoice-page-end-amount-discount-row {
    flex-wrap: wrap;
    margin: 0 10px;
    gap: 0 10px;
  }

  .create-invoice-page-end-amount-discount select {
    border: none;
    padding: 0;
    font-weight: 500;
    font-size: 14px;
  }

  .create-invoice-page-end-received-row {
    flex-direction: column;
  }

  .create-invoice-page-end-received-input {
    flex: 1;
  }

  .create-invoice-page-end-received-option {
    justify-content: start;
  }

  .create-invoice-page-end-received-option > div {
    flex-direction: row-reverse;
  }

  .create-invoice-page-end-received-input > input {
    width: 100%;
  }

  .create-invoice-page-end-form {
    margin: 10px;
    margin-top: 0;
  }
  .create-invoice-page-end {
    display: flex;
    flex-direction: column-reverse;
  }

  /* -------------------------------------------------------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------------------------------------------------------- */

  /* .create-invoice-page-table-add-item-option, */
  .create-invoice-page-table-sub-total > div,
  .create-invoice-page-table {
    display: none;
  }

  .create-invoice-page-table-add-item-option {
    margin: 20px;
  }
  .create-invoice-page-table-add-item-top {
    position: sticky;
    top: -10px;
    background-color: #ffffff;
  }

  /* -------------------------------------------------------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------------------------------------------------------- */

  .create-invoice-page-table-add-item-head {
    padding: 10px;
    gap: 10px;
  }

  .create-invoice-page-table-add-item-head-search {
    width: 100%;
  }

  /* -------------------------------------------------------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------------------------------------------------------- */

  .invoice-small-page-select-item {
    display: block;
    min-height: 60vh;
  }

  .invoice-small-page-select-item-col {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 15px 10px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }

  .invoice-small-page-select-item-col-name {
    font-size: 16px;
    font-weight: 500;
  }

  .invoice-small-page-select-item-col-price {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .invoice-small-page-select-item-col-price > span {
    color: #488dff;
    display: flex;
    align-items: center;
    font-weight: 600;
    gap: 2px;
    font-size: 15px;
  }

  .invoice-small-page-select-item-col-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .invoice-small-page-select-item-col-add {
    /* border: solid 1px #488dff; */
    height: max-content;
    color: #488dff;
    font-weight: 500;
    font-size: 16px;
    background-color: #488dff30;
    padding: 3px 15px;
    border-radius: 10px;
  }

  .invoice-small-page-select-item-view-col {
    display: flex;
    gap: 10px;
    line-height: 1.2;
    margin-bottom: 3px;
    color: #999;
  }

  .invoice-small-page-select-item-view-col > div {
    font-weight: 600;
    color: #666;
  }

  .invoice-small-page-select-item-view-col > span {
    color: #000;
  }

  .invoice-small-page-select-item-col-close {
    color: #d01717;
    /* flex: 1; */
    /* align-content: end; */
    font-weight: 600;
    font-size: 16px;

    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: flex-end;
  }

  .invoice-small-page-select-item-col-delete {
    font-size: 18px;
    color: #d01717;
    /* padding: 3px 0px; */
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 2px;
  }
  .invoice-small-page-select-item-col-delete > input {
    width: 40px;
  }

  .invoice-small-page-select-item-empty {
    padding: 20px;
    font-size: 20px;
    text-align: center;
  }

  /* -------------------------------------------------------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------------------------------------------------------- */

  .create-invoice-item-list {
    display: block;
  }
  .create-invoice-item-list-heading {
    padding: 5px 10px;
    background-color: #eeeeee;
    font-weight: 500;
    font-size: 16px;
    border-bottom: solid 1px #d9d4d4;
  }

  .invoice-small-page-select-item-view-table tr > td:nth-child(2) {
    /* background-color: #000000; */
    width: 100%;
  }
  .invoice-small-page-select-item-view-table td {
    padding: 5px 0;
    vertical-align: top;
  }
  .invoice-small-page-select-item-view-table td textarea {
    width: 100%;
  }

  .create-invoice-item-list-discount {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
  }
  .create-invoice-item-list-discount > div {
    background-color: #e9ecf1;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding-left: 5px;
  }
  .create-invoice-item-list-discount > div:first-child input {
    width: 50px;
  }
  .create-invoice-item-list-discount > div:nth-child(2) input {
    width: 100px;
  }

  .create-invoice-item-list-tax {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
  }

  .create-invoice-item-list-tax select {
    background-color: #e9ecf1;
    border: 0;
    padding: 5px 2px;
    border-radius: 5px;
  }
  .create-invoice-item-list-total {
    background-color: #e9ecf1;
    width: max-content;
    border-radius: 5px;
    padding-left: 5px;
  }

  .create-invoice-page-end > div:first-child {
    border-top: solid 1px #d9d4d4;
    border-right: none;
    margin-top: 10px;
    padding-top: 10px;
  }

  /* -------------------------------------------------------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------------------------------------------------------- */

  .create-party-main{
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
  
  .create-invoice-page-table-add-item-table {
    display: none;
  }
  .create-expense-page-table-add-item
  .create-invoice-page-table-add-item-table {
    display: block !important;
  }
  

  .create-party-form-content {
    padding: 60px 0 30px;
    height: 100%;

  }

  .create-party-form {
    padding: 0;
  }

  .create-party-form-heading{
    margin: 5px 0 20px;
    padding: 5px 15px;
  }
  .create-party-form-hr{
    margin: 5px 0 20px;
  }

  .create-party-form-row {
    grid-template-columns: 1fr;
    gap: 0;
  }
  .create-party-form-col {
    padding: 0 15px 15px ;
  }
    
  .create-party-form-content select{
    appearance: none;
  }
  
  .create-party-row-2{
    grid-template-columns: 1fr;
    gap: 10px;
  }
    
}

/* -------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------- */
