
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */

.create-invoice{
    width: calc(100% - 300px);
    margin: auto;
}
.create-invoice input{
    border: #0000ff solid 1px;
    
}
.create-invoice table td{
    vertical-align: top;
}



.create-invoice-heading{
    font-size: 1.3rem;
    font-weight: 500;
    margin: 10px 0 30px 0;
    text-transform: uppercase;
}
.create-invoice-theme{
    padding: 25px;
}

/* address -------------------------------------------------------------------------------------- */

.create-invoice-company{
    display: flex;
    align-items: center;
    gap: 15px;
}

.create-invoice-company-logo>img{
    max-width: 300px;
    max-height: 100px;
    min-width: 80px;
}

.create-invoice-company-details-name{
    font-size: 1.8rem;
    font-weight: 600;
}
.create-invoice-company-details-text b{
    font-weight: 600;
}

/* invoice details -------------------------------------------------------------------------------------- */

.create-invoice-invoive-details-wb{
    /* background-color: rgb(from var(--invoice) r g b / 20%); */
    background-color: #17455520;
}
.create-invoice-invoive-details-wb,
.create-invoice-invoive-details{
    /* border-top: 5px solid var(--invoice); */
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    justify-content: space-around;
    padding: 15px 10px;
}

.create-invoice-invoive-details-col{
    display: flex;
    gap: 5px;
    align-items: center;
}
.create-invoice-invoive-details-col>input{
    width: auto;
}
.create-invoice-invoive-details-col-heading{
    font-weight: 600;
}

/* party details -------------------------------------------------------------------------------------- */

.create-invoice-party-details{
    margin: 10px ;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    
}

.create-invoice-party-details-col-1{
    font-size: .95rem;
}

.create-invoice-party-details-col-1-add{
    font-size: 1.2rem;
    color: var(--invoice);
    color: #0000ff;
    font-weight: 700;
    border: 2px dashed ;
    margin: 20px 40px;
    padding: 10px 20px;
    cursor: pointer;
}

.create-invoice-party-details-col-1-heading{
    font-size: 1.2rem;
    font-weight: 500;
    display: flex;
    gap: 50px;
    align-items: center;
}
.create-invoice-party-details-col-1-heading>div{
font-size: 1rem;
color: white;
text-decoration: underline ;
border-radius: 5px;
cursor: pointer;
color: blue;
font-weight: 500;
font-size: 1.1rem;
}
.create-invoice-party-details-col-1-name{
    font-size: 1.2rem;
    font-weight: 600;
    margin: 5px 0 2px;
}

.create-invoice-party-details-col-1-text b{
    font-weight: 600;
}

.create-invoice-party-details-col-2{
    gap: 15px;
    font-size: .95rem;
    line-height: 1.1rem;
    text-align: start;
}

.create-invoice-party-details-col-2 table{
    width: max-content;
}
.create-invoice-party-details-col-2 td{
    padding: 10px 4px;
}
.create-invoice-party-details-col-2 td:nth-child(1){
    font-weight: 600;
}
.create-invoice-party-details-col-2-col-colons,
.create-invoice-party-details-col-2-col-heading{
    font-weight: 600;
}
.create-invoice-party-details-col-2-col-data{
    padding-left: 10px;
}


/* items details -------------------------------------------------------------------------------------- */

.create-invoice-item-data{
    min-height: 300px;
}

.create-invoice-item-data-add{
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
    color: #0000ff;
    border: dashed 2px;
    padding: 8px;
    text-align: center;
    margin: 20px ;
}


.create-invoice-item-data-table{
    width: 100%;
    margin-top: 30px;
}
.create-invoice-item-data-table-wb>thead{
    background-color: rgb(from var(--invoice) r g b / 20%);
    background-color: rgb(from var(--main) r g b / 20%);
    
}
.create-invoice-item-data-table>thead>tr>th{
    padding: 10px;
    font-weight: 600;
    text-align: start;
}
.create-invoice-item-data-table-wb>tbody>tr:nth-child(even){
    background-color: rgb(from var(--invoice) r g b / 6%);
    background-color: rgb(from var(--main) r g b / 6%);
    
}
.create-invoice-item-data-table>tbody>tr>td{
    /* text-align: center; */
    padding: 10px;
}


/* ------------------------ */
.create-invoice-item-data-sub-total-wb{
    background-color: rgb(from var(--invoice) r g b / 20%);
    background-color: rgb(from var(--main) r g b / 20%);
}
.create-invoice-item-data-sub-total{
    display: flex;
    justify-content: space-between;
    padding: 10px 50px;
    border-top: 2px solid var(--invoice);
    border-top: 2px solid var(--main);
    font-weight: 600;
    font-size: 1.1rem;
    margin-top: 20px;
}


/* invoice end -------------------------------------------------------------------------------------- */


.create-invoice-end{
    display: grid;
    grid-template-columns: 5fr 3fr;
    padding: 15px 10px;
}

.create-invoice-end-col-1{
    max-width: 450px;
}

.create-invoice-end-col-1-bank-datails-heading{
    font-weight: 600;
    margin: 10px 0 -5px;
}

.create-invoice-end-col-1-bank-datails-data{
    font-size: .9rem;
}

.create-invoice-end-col-1-bank-datails-data td{
    padding-right: 10px;
}
.create-invoice-end-col-1-bank-datails-data td:nth-child(1){
    font-weight: 500;
}


/* ------------------- */

.create-invoice-end-col-1-tc-heading{
    font-weight: 600;
    /* margin-bottom: -2px; */
}
.create-invoice-end-col-1-tc-list{
    font-size: 0.8em;
}

/* col-2 ---------------------------*/



.create-invoice-end-col-2-amount-col{
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    align-items: center;
}
.create-invoice-end-col-2-amount-col i{
    font-size: 1.2rem;
}
.create-invoice-end-col-2-amount-col-blue{
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}
.create-invoice-end-col-2-amount-col-green{
    color: #00b944;
}

.create-invoice-end-col-2-amount-col input{
    width: 110px;
    margin-right: -10px;
    margin-top: 5px;
    text-align: end;
    
}

.create-invoice-end-col-2-amount-col-heading{
    font-weight: 500;
}

    .create-invoice-end-col-2-amount-col-total{
        border-top: 2px solid var(--invoice);
        border-top: 2px solid var(--main);
        border-bottom: 2px solid var(--invoice);
        border-bottom: 2px solid var(--main);
        padding: 5px;
    }

    .create-invoice-end-col-2-amount-text{
        text-align: end;
        text-transform: capitalize;
        font-weight: 600;
        font-size: .9rem;
    }
    .create-invoice-end-col-2-amount-text>div:nth-child(1){
        font-size: .9rem;
    }


    /* ------------------------------------------------------------------------------ */

    .create-invoice-item-data-table-remove{
        font-size: 1rem;
        padding: 10px !important;
        width: 20px;
        color: #0000ff;
    }
    
    .create-invoice-item-data-table-remove:hover{
        color: red;
        cursor: pointer;
    }
    
    .create-invoice-item-data-table-sno{
        width: 40px;
    }
    
    .create-invoice-item-data-table-qty{
        width: 80px;
        padding:  10px !important;
    }

    
    /* tax ----------------------------------------------------------------------------------- */
    
    .create-invoice-item-data-table-tax{
        width: 95px;
        /* padding: 20px 10px !important; */
    }

    .create-invoice-item-data-table-tax-select-value{
        border: #0000ff solid 1px;
        font-size: .85rem;
        padding: 2px;
    }

    .create-invoice-item-data-table-tax-select-option{
        position: absolute;
    }
    

    .create-invoice-item-data-table-tax-select-option-top{
        /* border-bottom: 18px solid #29ff2200;
        border-left: 18px solid #ec0404;
        border-top: 18px solid #0000;
        content: "";
        display: block;
        position: absolute; */
        /* right: -15px; */
        
        border-bottom: 10px solid #0000ff;
        border-left: 10px solid #00000000;
        /* border-top: 18px solid #000000c9; */
        border-right: 10px solid #00000000;

        width:  10px;
        height: 10px;
        margin-top: -5px;
        
    }
    .create-invoice-item-data-table-tax-select-option-options{
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border: solid 1px #0000ff;
    }
    .create-invoice-item-data-table-tax-select-option-options>div{
        font-size: .85rem;
        padding: 5px 20px;
        border-bottom: #bfbfff 1px solid;
        cursor: pointer;
    }
    .create-invoice-item-data-table-tax-select-option-options>div:hover{
        background-color: #e6e6ff;
    }
    
    .create-invoice-item-data-table-tax-amount{
        font-size: .9rem;
        font-weight: 500;
        margin-top: 3px;
    }
    

    
    /* tax end----------------------------------------------------------------------------------- */
    
    /* discount ----------------------------------------------------------------------------------- */

    .create-invoice-item-data-table-discount{
        width: 120px;
    }
    .create-invoice-item-data-table-discount>div{
        border: #0000ff solid 1px;
        font-size: .85rem;
        /* padding: 2px; */
        display: flex;
        align-items: center;
        /* padding: 2px; */
    }
    .create-invoice-item-data-table-discount>div:nth-child(2){
    margin-top: 5px;
    }
    .create-invoice-item-data-table-discount>div>div{
        /* padding: 2px; */
        /* font-size: .9rem; */
    }
    .create-invoice-item-data-table-discount>div>div{
        font-size: 1rem;
        padding: 0 2px;
        /* border-right: 1px #0000ff solid; */
        background-color: #e6e6ff;
        width: 18px;
        text-align: center;
        font-weight: 500;
    }
    .create-invoice-item-data-table-discount>div>input{
        /* background-color: #00b944; */
        width: 80px;
        padding: 2px;
        border: none;
        
    }   
    .create-invoice-item-data-table-discount>div>span{
        flex: 1;
        padding-left: 2px;

    }
    
    
    /* discount end----------------------------------------------------------------------------------- */
    
    

    .create-invoice-item-data-table-price{
        width: 120px;
        padding:  10px !important;
    }

    .create-invoice-item-data-table-total{
        width: 120px;
        padding: 10px !important;
    }


    .create-invoice-item-data-table-total>div,
    .create-invoice-item-data-table-tax>div,
    .create-invoice-item-data-table-price>div,
    .create-invoice-item-data-table-qty>div{
        /* border: solid 1px #c0c0c0; */
        /* padding: 2px;
        font-size: .9rem; */
    }
    
    
    .create-invoice-item-data-table-total input,
    .create-invoice-item-data-table-tax input,
    .create-invoice-item-data-table-price input,
    .create-invoice-item-data-table-qty input{
        padding: 2px;
        width: 100%;
    }
    

    









