
.header-features{
    background: url(../../../../media/images/in.jpg);
}

.header-features-main{
    display: flex;
    justify-content: center;
    gap: 150px;
    text-align: center;
    margin: 0px auto 50px;
    /* background-color: var(--web-bg-light); */
    background-color: #0000003f;
    background-color: #001820ea;


    padding: 50px 0;
    color: #cfcfcfe0;
}

.header-features-icon{
    /* color: var(--web-main); */
    color: #fff;
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.header-features-icon>i:nth-child(2){
    /* margin-bottom: -3px; */
    font-size: 1.5rem;
}

.header-features-heading{
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 500;
}
.header-features-heading>div{
    color: var(--web-main);
    color: #fff;
    font-weight: 600;
}


@media only screen and (max-width: 1000px) {
    
    .header-features-main{
        overflow: auto;
        justify-content: flex-start;
        margin: 0px;
        padding: 30px 20px 30px;
        gap: 30px;
    }

.header-features-icon{
    font-size: 1.8rem;
}
.header-features-heading{
    font-size: 1.1rem;
}
    
.header-features-text{
    font-size: .8rem;
    width: max-content;
}

}


