.app-transaction-page-main {
  background-color: #ffff;
  height: calc(100vh - 20px);
  border-radius: 10px;
}
.app-transaction-page-top {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  font-size: 20px;
  font-weight: 600;
  border-bottom: solid 1px #9999;
  /* margin-bottom: 20px; */
}

.app-transaction-page-heading{
    display: flex;
    align-items: center;
    gap: 10px;
}

.app-transaction-page-top-search {
  border: solid 1px #d9d4d4;
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  padding: 0 8px;
  border-radius: 5px;
  width: 250px;
}
.app-transaction-page-top-search input {
  border: none;
  padding: 5px 0;
  flex: 1;
  outline: none;
}

.app-transaction-page-table {
  border: solid 1px #d9d4d4;
  border-radius: 10px;
  margin:  15px;
  text-transform: capitalize;
  background-color: #ffff;
}
.app-transaction-page-table-head,
.app-transaction-page-table-body {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.app-transaction-page-table > div > div {
  border-right: solid 1px #d9d4d4;
  padding: 10px;
}
.app-transaction-page-table-head > div {
  background-color: #f0f1f3;
  font-weight: 500;
}
.app-transaction-page-table-head > div:last-child {
  border-right: none;
  background-color: #f0f1f3;
}

.app-transaction-page-table-body > div {
  border-bottom: solid 1px #d9d4d4;
}
.app-transaction-page-table-body {
  max-height: calc(100vh - 150px);
  overflow: auto;
}

.app-transaction-page-table-body::-webkit-scrollbar {
  width: 8px;
}
.app-transaction-page-table-body::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 10px;
}

.app-transaction-page-blank {
  border: solid 1px #d9d4d4;
  border-radius: 0 0 10px 10px;
  margin: 0 15px;
  text-align: center;
  padding: 100px 0;
}

.app-dashboard-small-list {
  display: none;
}

.app-dashboard-small-list-content{
    display: none;
}

@media only screen and (max-width: 1000px) {
    
    .app-transaction-page-main{
        height: auto;
        position: relative;
        overflow: auto;
    }
    .app-transaction-page-top{
        position: fixed;
        width: 100%;
        top: 0px;
        background-color: #fff;
        padding: 10px;
    }
    
    .app-transaction-page-table {
        display: none;
    }
    .app-dashboard-small-list-content{
        display: block;
    }
    
    .app-transaction-page-main
    .app-dashboard-small-list-content{
        padding: 50px 20px 0px;
    }
  .app-dashboard-small-list {
    display: block;
  }

  .app-dashboard-small-list-heading {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    align-items: center;
  }
  .app-dashboard-small-list-heading > div:nth-child(1) {
    font-size: 18px;
  }
  .app-dashboard-small-list-heading > div:nth-child(2) {
    color: #488dff;
  }

  .app-dashboard-small-list-invoice{
    text-transform: capitalize;
    color: #888;
    font-weight: 500;
  }

  .app-dashboard-small-list-col {
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 15px;
    display: grid;
    gap: 5px;
    margin-top: 15px;
    background-color: #fff;
  }

  .app-dashboard-small-list-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .app-dashboard-small-list-amount,
  .app-dashboard-small-list-name {
    font-size: 16px;
    font-weight: 600;
  }
  .app-dashboard-small-list-name >span{
    color: #666;
  }
  .app-dashboard-small-list-amount{
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .app-dashboard-small-list-empty{
    color: #666;
    font-size: 16px;
    font-weight: 500;
    padding: 10px ;

  }
  
  
}
