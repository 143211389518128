.qr-generator-header {
  background: url(../../../../media/images/website/bg.webp);
  background-size: cover;
  background-position: center;
}

.qr-generator-header-main {
  width: 80%;
  margin: auto;
  padding: 100px 0;
}

.qr-generator-header-heading {
  font-size: 3rem;
  font-weight: 500;
  color: #fff;
}

.qr-generator-header-content {
  margin: 30px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.qr-generator-header-content-details-options {
  display: flex;
  color: #959595;
  background-color: #dedede;
  justify-content: space-evenly;
  border-radius: 10px 10px 0 0;
  height: 35px;
}

.qr-generator-header-content-form {
  background-color: #f2f2f2;
}

.qr-generator-header-content-details-options > div {
  padding: 5px 10px;
  cursor: pointer;
  flex: 1;
  text-align: center;
}

.qr-generator-header-content-details-options-active {
  background-color: #f2f2f2;
  border-radius: 10px 10px 0 0;
  color: #007ee4;
  color: #495057;
}

.qr-generator-header-content-form {
  padding: 20px 30px;
  display: grid;
  align-content: start;
  height: calc(100% - 35px);
}

.qr-generator-header-content-code-blank {
  height: 35px;
}

.qr-generator-header-content-code-details {
  background-color: #fafafa;
  height: calc(100% - 35px);
}

.barcode-generator-header-content-form label,
.qr-generator-header-content-form label {
  color: #495057;
  font-size: 1rem;
  margin: 10px 0;
}

.barcode-generator-header-content-form > input,
.qr-generator-header-content-form > input {
  width: 80%;
  padding: 2px 10px;
  font-size: 1rem;
  background-color: #fff;
  border: #9996 1px solid;
  margin-bottom: 20px;
}

.barcode-generator-header-content-form > button,
.qr-generator-header-content-form > button {
  background-color: #007ee4;
  color: #fff;
  width: max-content;
  border-radius: 8px;
  padding: 5px 20px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.qr-generator-header-content-code-details {
  display: grid;
  justify-content: center;
  padding: 30px;
  gap: 20px;
}

.qr-generator-header-content-code-code{
    display: flex;
    padding: 10px;
    background-color: #fff;
    /* border: 5px solid #000; */
    /* margin: 10px; */
}


.qr-generator-header-content-code-code > svg {
  width: 190px;
  height: auto;
}

.qr-generator-header-content-code-button {
  background-color: #28a745;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  padding: 5px 20px;
  cursor: pointer;
  font-size: 1rem;
}

.qr-generator-header-content-code-buttons {
    display: flex;
    gap: 20px;
}
.qr-generator-header-content-code-buttons>div{
    border: #959595 solid 1px;
    flex: 1;
    text-align: center;
    padding: 4px 10px;
    font-size: 1rem;
    color: #495057;
    border-radius: 8px;
    cursor: pointer;
}

.barcode-generator-header-content{
  display: grid;
  grid-template-columns: 3fr 2fr;
}

.barcode-generator-header-content-form{
  background-color: #dedede;
  display: grid;
  align-content: flex-start;
  padding: 20px 20px 40px;
  padding: 60px;
  padding: 40px 40px 60px;
}

.barcode-generator-header-content-details{
  background-color: #fafafa;
  display: grid;
  padding: 40px 20px 60px;
  gap: 20px;
  align-content: flex-start;
  justify-content: center;
  text-align: center;
}

.barcode-generator-header-content-details-heading{
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: -15px;
}

.barcode-generator-header-content-details-code>svg{
  width: 100%;
  height: auto;

}

@media only screen and (max-width: 1000px) {

  .barcode-generator-header-content{
    grid-template-columns: 1fr;
  }
  

.qr-generator-header-main {
    width: 100%;
    padding: 40px 20px;
}
    
    
.qr-generator-header-heading {
    font-size: 1.6rem;
    font-weight: 600;
}
    
    
.qr-generator-header-content {
    grid-template-columns: 1fr;
    gap: 5px;
}
    
.qr-generator-header-content-code-blank {
    height: 0;
}

.qr-generator-header-content-form {
  padding: 20px 15px 30px;
}

.qr-generator-header-content-form > input {
    width: 100%;
}

.qr-generator-header-content-code-details {
    height: 100%;
}

.qr-generator-header-content-code-code{
/* width: 80%;
margin: 0 auto; */
}
.qr-generator-header-content-code-code > svg{
    /* margin: 0 auto; */
    width: 100%;
    height: auto;
}


    
}


