
.app-homepage-section-3{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 20px 0;
    gap: 20px;
}
.app-homepage-section-3>div{
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

.app-homepage-col-heading{
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--main);
}

.app-homepage-col-price{
    font-size: 1.8rem;
    font-weight: 600;
}


input{
    cursor: pointer;
}