.about-page-about-main{
    background: url(../../../../media/images/website/mobile-hand.jpg);
    padding: 100px;
    background-position: left;
    background-size: 60%;
    background-repeat: no-repeat;
}

.about-page-about-text{
    padding: 100px 0;
    padding-left: 40%;
    padding-right: 10%;
    color: #314259;
    color: #787878;
    font-size: 1.3rem;
    
}
.about-page-about-text>b{
    font-weight: 600;
}


@media only screen and (max-width: 1000px) {
.about-page-about-main{
    background: none ;
    padding: 0;
}
.about-page-about-text{
    padding: 80px 20px;
    font-size: 1rem;
}
    
    
}


