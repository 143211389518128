
/* .web-header-main .navbar{
    background-color: transparent;
    box-shadow: none;
} */


.web-header-main{
    /* background: radial-gradient(circle, #ffffff 0%, #d0e8ee 100%); */
    border-radius: 15px;
    max-width: 1600px;
    margin: auto;
    /* width: calc(100% - 30px); */
    /* padding: 20px; */
    /* padding-bottom: 40px; */
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
}


/* ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------- */

.web-header-content-main{
    display: grid;
    grid-template-columns: 8fr 5fr;
    /* max-width: 1140px; */
    /* width: calc(100% - 50px); */
    margin: auto;
    padding: 80px 100px ;
    align-items: center;
    background-color: #e4eded;
}

.web-header-content-col-1-heading-1{
    font-size: 2.5rem;
    font-weight: 600;
    color: #131313;
    line-height: 1.3;
}
.web-header-content-col-1-heading-2{
    margin: 30px 0;
}

.web-header-content-col-1-buttons{
    display: flex;
    gap: 30px;
}

.web-header-content-col-1-buttons a {
    color: white;
    font-size: 1rem;
    border: solid var(--main) 2px ;
    padding: 10px 30px;
    border-radius: 10px;
    font-weight: 500;
    text-decoration: none;
    background-color: var(--main);
    box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
}
.web-header-content-col-1-buttons>a:first-child{
    background-color: white;
    color: var(--main);
    border-color: white;
    font-weight: 600;
}


.web-header-content-col-2 {
    /* overflow: hidden; */
    width: 80%;
    margin: auto;
}
.web-header-content-col-2 img{
    width: 100%;
    border-radius: 10px;
}

/* ------------------------------------------------------------------------------------------------------------------- */




/* ------------------------------------------------------------------------------------------------------------------- */

.web-header-blank{
    height: 1400px;
}

.web-header-gst-calculator{
    margin-top: -1400px;
}

/* ------------------------------------------------------------------------------------------------------------------- */



@media only screen and (max-width: 720px) {

    .web-header-main{
        background: radial-gradient(circle, #ffffff 0%, #d0e8ee 100%);
        border-radius: 15px;
        max-width: 1500px;
        margin: 8px auto;
        width: calc(100% - 20px);
        padding: 10px;
    }
    
    
    /* ------------------------------------------------------------------------------------------------------------------- */
    /* ------------------------------------------------------------------------------------------------------------------- */
    /* ------------------------------------------------------------------------------------------------------------------- */
    
    .web-header-content-main{
        grid-template-columns: 1fr;
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        padding: 0px 0 20px ;
        align-items: center;
        text-align: center;
    }
    
    .web-header-content-col-1-heading-1{
        font-size: 1.5rem;
    }

    .web-header-content-col-1-heading-2{
        margin: 15px 0;
        font-size: .85rem;
    }
    
    .web-header-content-col-1-buttons{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    .web-header-content-col-1-buttons a {
        color: white;
        font-size: 1rem;
        border: solid var(--main) 2px ;
        padding: 8px;
        border-radius: 10px;
        font-weight: 500;
        text-decoration: none;
        background-color: var(--main);
        box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
    }
    .web-header-content-col-1-buttons>a:first-child{
        background-color: white;
        color: var(--main);
        border-color: white;
        font-weight: 600;
    }
    
    
    .web-header-content-col-2 {
        /* overflow: hidden; */
        width: 80%;
        margin: 10px auto ;
    }
    .web-header-content-col-2 img{
        width: 100%;
        border-radius: 10px;
    }
    
    /* ------------------------------------------------------------------------------------------------------------------- */
    
    
    
    
    /* ------------------------------------------------------------------------------------------------------------------- */
    
    .web-header-blank{
        height: 1400px;
    }
    
    .web-header-gst-calculator{
        margin-top: -1400px;
    }
    
    /* ------------------------------------------------------------------------------------------------------------------- */
    
    
    
    
    
    
}

