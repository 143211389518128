
.industry-slider-main{
    width: 80%;
    margin: auto;
}

.industry-slider-heading{
    font-size: 2.2rem;
    font-weight: 700;
    color: #314259;
    text-align: center;
}
.industry-slider-head-text{
    padding: 0 11%;
    text-align: justify;
}

.industry-slider-content{
    padding: 30px 0;
}

.industry-slider-slide{
    display: flex;
    gap: 20px;
    align-items: center;
}

.industry-slider-slide-image>img{
    width: 190px;
}
.industry-slider-slide-heading{
    font-size: 1.1rem;
    font-weight: 600;

    color: #314259;
    margin-bottom: 10px;
}


@media only screen and (max-width: 1000px) {
    .industry-slider-main{
        width: 100%;
        margin: 50px 0;
        padding: 0 15px;
    }

    .industry-slider-heading{
        font-size: 1.8rem;
    }
    .industry-slider-head-text{
     padding: 0;   
    }

    .slider-container{
        width: 95%;
        margin: auto;
    }
    
    .industry-slider-slide{
        flex-direction: column;
    }
    
.industry-slider-slide-image{
    width: 70%;
    margin: auto;
}
    
.industry-slider-slide-image>img{
    width: 100%;
}

    
    
}
