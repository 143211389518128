.success-popup{
    background-color: #1116;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.success-popup-main{
    background: #fff;
    background-position: right;
    border-radius: 10px;
    background-size: cover;
    padding: 20px;
    text-align: center;
    width: 800px;
    padding-bottom: 80px;
}

.success-popup-close{
    display: flex;
    justify-content: flex-end;
    font-size: 25px;
}
.success-popup-close i{
    cursor: pointer;
}

.success-popup-heading-1{
    color: #006f00;
    font-size: 50px;
    font-weight: 600;
    margin-top: 30px;
}

.success-popup-logo>img{
    width: 350px;
    margin: 20px 0;
}

.success-popup-text{
    font-size: 20px;
}

.success-popup-text b{
    font-weight: 600;
}


@media only screen and (max-width: 1000px) {

    .success-popup{
        padding: 10px;
    }
.success-popup-main{
    padding: 8px;
    padding-bottom: 50px;
}

.success-popup-heading-1{
    font-size: 28px;
}

.success-popup-logo>img{
    width: 60%;
    margin: 10px;
}
.success-popup-text{
    font-size: 16px;
}
    
    
}



    