.app-popup-form{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000005e;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-popup-form-main{
    background-color: white;
    min-width: 400px;
    border-radius: 15px;
}
.app-popup-form-top{
    border-radius: 15px 15px 0 0;
    background: rgb(from var(--main) r g b / 20%) !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px;
    gap: 20px;
}
.app-popup-form-top-heading{
    font-size: 1.3rem;
    font-weight: 500;
}
.app-popup-form-top-close{
    font-size: 1.8rem;
    /* margin-right: 10px; */
}


.app-popup-form-content{
    padding: 20px;
    display: grid;
    gap: 25px;
}
.app-popup-form-content-2{
display: grid;
grid-template-columns: repeat(2, 1fr);
gap: 20px;
}

.app-popup-form-col-heading{
    /* font-size: .9rem; */
    font-weight: 500;
    color: #777777;
}

.app-popup-form-col-text{
    font-size: 1.2rem;
    font-weight: 500;
}

.app-popup-form-col-2{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.app-popup-form-col-option{
    border: solid 3px #bbbbbb;
    border-radius: 5px;
    padding: 5px 10px;
    color: #444444;
    cursor: pointer;
}
.app-popup-form-col-option-active{
    color: var(--main);
    border: solid 3px var(--main);
    font-weight: 600;
}

.app-popup-form-col-input{
    /* width: 100%; */
    display: flex;
    border: solid 2px #bbbbbb;
    border-radius: 5px;
    
}
.app-popup-form-col-input>input{
    border-radius: 5px;
    border: none;
    width: 100%;
    padding: 5px 10px;
    font-size: 1.2rem;
}
.app-popup-form-col-input>span{
    background-color: rgb(from var(--main) r g b / 20%);
    padding: 5px 10px;
}

.app-popup-form-end{
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
    margin-bottom: 10px;
}

.app-popup-form-end>button,
.app-popup-form-end-button{
    background-color: transparent;
    border-radius: 5px;
    border: solid 2px var(--main);
    padding: 5px 15px;
    font-size: 1.1rem;
    color: var(--main);
    font-weight: 600;
    cursor: pointer;
    
}
.app-popup-form-end>button{
    background-color: var(--main);
    color: white;
}
