
.about-page .navbar {
  /* width: 80%; */
  /* margin: auto; */
}

/* ============================================================================================ */
/* AboutFeaturesComponent ============================================================================================ */
/* ============================================================================================ */

.about-features {
  background: linear-gradient(
    174deg,
    rgba(237, 26, 59, 1) 0%,
    rgba(241, 91, 67, 1) 88%
  );
  margin: 30px 0;
}

.about-features-main {
  width: 80%;
  margin: auto;
  padding: 80px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

.about-features-section-1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  text-align: center;
}

.about-features-col {
  background-color: #fff;
  align-content: center;
  padding: 20px 10px;
}

.about-features-section-1 > .about-features-col:nth-child(3),
.about-features-section-1 > .about-features-col:nth-child(1) {
  border-radius: 25px 0 0 25px;
}

.about-features-section-1 > .about-features-col:nth-child(2),
.about-features-section-1 > .about-features-col:nth-child(4) {
  border-radius: 0 25px 25px 0;
}

.about-features-section-2 {
  background-color: #fff;
  border-radius: 20px;
  padding: 80px 50px;
  font-size: 1.05rem;
  color: #314259;
}

.about-features-section-2 > b {
  font-weight: 600;
  text-transform: capitalize;
}

.about-features-col-icon {
  color: var(--web-main);
  font-size: 3rem;
}

.about-features-col-text {
  color: #314259;
}

.about-features-col-heading {
  font-size: 1.05rem;
  font-weight: 500;
}


/* ============================================================================================ */
/* AboutPageEffortlessly ============================================================================================ */
/* ============================================================================================ */


.about-effortlessly-main{
    width: 80%;
    margin: 80px auto;
}
.about-effortlessly-heading{
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
}


.about-effortlessly-content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    
}
.about-effortlessly-content-list>li{
    margin-bottom: 10px;
    color: #555;
}


@media only screen and (max-width: 1000px) {
  .about-features-main {
    grid-template-columns: 1fr;
    width: 100%;
    padding: 40px 20px;
  }

  .about-features-col-icon {
    font-size: 2rem;
  }

  .about-features-col-heading {
    font-size: 0.9rem;
  }

  .about-features-section-2 {
    padding: 40px 25px;
    font-size: 0.9rem;
  }

  
/* ============================================================================================ */
/* AboutPageEffortlessly ============================================================================================ */
/* ============================================================================================ */


.about-effortlessly-main{
    width: 100%;
    margin: 40px auto;
    padding: 0 20px;
}

.about-effortlessly-heading{
    font-size: 1.5rem;
    margin-bottom: 20px;
}


.about-effortlessly-content{
    grid-template-columns: 1fr;
}

.about-effortlessly-content-list>li{
    margin-bottom: 10px;
    color: #555;
    font-size: .9rem;
    margin-left: 20px;
}
  
.about-effortlessly-content-image{
    order: -1;
}
.about-effortlessly-content-image>img{
    width: 100%;
}
  
  
}
