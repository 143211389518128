.our-feature-main {
  width: 80%;
  margin: 100px auto;
}

.our-feature-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  padding: 20px 20px 50px;
}

.our-feature-img > img {
  width: 400px;
}

.our-feature-heading {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.3;
  margin: 30px 0 10px;
  color: #314259;
}
.our-feature-text{
  font-size: 1rem;
}

.our-feature-button {
  color: var(--web-main);
  font-size: 1.1rem;
}

@media only screen and (max-width: 1000px) {
  .our-feature-main {
    margin: 30px auto;
    width: 100%;
    padding: 0 20px;
  }

  .our-feature-row {
    grid-template-columns: 1fr;
    margin: 10px 0 50px ;
    padding: 0;
    gap: 10px;
  }

  .our-feature-img {
    width: 70%;
    margin: auto;
  }

  .our-feature-img > img {
    width: 100%;
  }

  .our-feature-heading {
    font-size: 1.3rem;
    margin: 0;
  }

  .our-feature-text{
      font-size: .9rem;
    }
    .our-feature-button{
        font-size: 1rem;

    }

  .our-feature-main > .our-feature-row:nth-child(4) .our-feature-col,
  .our-feature-main > .our-feature-row:nth-child(2) .our-feature-col {
    order: 2;
  }
}
