
.we-work-main{
    max-width: 1100px;
    margin: 80px auto;
}

.we-work-main-heading{
    font-family: "Roboto Slab";
    font-size: 2.8rem;
    font-weight: 700;
    text-align: center;
    color: #174555;
    margin-bottom: 40px;
}

.we-work-main-content>div{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}




.we-work-main-content-row-img>img{
    width: 100%;
    padding: 20px 70px;
    transform: rotate(-3deg);
    filter: drop-shadow(0 0 0.5rem #0d3b4b6e);

}


.we-work-main-content-row-col{
    display: flex;
    align-items: center;
    border-left: dashed 2px #17455570;
    gap: 30px;
}

.we-work-main-content-row-number{
    border: dashed 2px #17455570;
    background-color: white;
    color: #174555c9;
    font-weight: 600;
    height: 50px;
    min-width: 50px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    margin-left: -25px;
}

.we-work-main-content-row-col-heading{
    font-size: 1.4rem;
    color: #174555;
    font-weight: 600;
}
.we-work-main-content-row-col-text{
    color: #174555;
}

.we-work-main-content-row-2>div:first-child{
    order: 1;
}
.we-work-main-content-row-2{
    text-align: end;
}
.we-work-main-content-row-2>.we-work-main-content-row-col{
    flex-direction: row-reverse;
    border: none;
    border-right: dashed 2px #17455570;
    margin-right: -2px;
}
.we-work-main-content-row-2>.we-work-main-content-row-col>
.we-work-main-content-row-number{
    margin: 0;
    margin-right: -30px;
}

.we-work-main-content-row-2>
.we-work-main-content-row-img>img{
    transform: rotate(3deg);
    
}


@media only screen and (max-width: 720px) {
    
.we-work-main{
    margin: 50px 20px;
}

.we-work-main-heading{
    font-size: 2rem;
    margin-bottom: 20px;
}

.we-work-main-content>div{
    display: flex;
    flex-direction: column-reverse;
}




.we-work-main-content-row-img>img{
    width: 100%;
    padding: 10px 20px;
    transform: rotate(-3deg);
    filter: drop-shadow(0 0 0.5rem #0d3b4b6e);

}


.we-work-main-content-row-col{
    padding: 30px 0;
    margin: 0 30px;
    /* display: flex;
    align-items: center;
    border-left: dashed 2px #17455570; */
    gap: 20px;
}

.we-work-main-content-row-number{
    /* border: dashed 2px #17455570;
    background-color: white;
    color: #174555c9;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center; */
    border-radius: 10px;
    height: 45px;
    min-width: 45px;
    font-size: 1.2rem;
    margin-left: -25px;
}

.we-work-main-content-row-col-heading{
    font-size: 1.2rem;
    /* color: #174555;
    font-weight: 600; */
}
.we-work-main-content-row-col-text{
    font-size: .9rem;
}

.we-work-main-content-row-2>div:first-child{
    order: -1;
}
.we-work-main-content-row-2{
    text-align: start;
}
.we-work-main-content-row-2>.we-work-main-content-row-col{
    flex-direction: row;
    border: none;
    border-left: dashed 2px #17455570;
    /* margin-left: -20px; */
}
.we-work-main-content-row-2>.we-work-main-content-row-col>
.we-work-main-content-row-number{
    margin: 0;
    margin-left: -25px;
}

.we-work-main-content-row-2>
.we-work-main-content-row-img>img{
    transform: rotate(3deg);
    
}





}


