
.business-card-impact-main{
    max-width: 800px;
    margin: auto;
    padding: 80px 0;
}


.business-card-impact-heading{
    font-size: 2rem;
    color: var(--web-main);
    
}

.business-card-impact-content-text{
    margin-top: 40px;
}
    
.business-card-impact-content-text i{
    color: var(--web-main);
    font-size: 1.6rem;
    margin-right: 10px;
}


/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.business-card-unique-image{
    width: 400px;
    margin: 30px auto;
}

.business-card-unique-image>img{
    width: 100%;
}
.create-business-cards-component-image>img{
    width: 450px;
}
/* .create-business-cards-component-image , */




/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */



@media only screen and (max-width: 1000px) {
   
.business-card-impact-main{
    padding: 50px 20px;
}

.business-card-impact-heading{
    font-size: 1.4rem;
}

.business-card-impact-content-text{
    margin-top: 20px;
    font-size: .9rem;
}
    
.business-card-impact-content-text i{
    font-size: 1.4rem;
    margin-right: 5px;
}




/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
.business-card-unique-image{
    width: 80%;
}

.create-business-cards-component-image{
    width: 70%;
    margin: auto;
}
.create-business-cards-component-image>img{
    width: 100%;
}




/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */




    

}




