
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* WhatIsQrCodeComponent :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.what-qr {
  background-color: #f5f5f5;
}
.what-qr-main {
  max-width: 1140px;
  margin: auto;
  display: flex;
  align-items: center;
  padding: 40px 0 80px;
  gap: 60px;
}

.what-qr-image > img {
  width: 500px;
}

.what-qr-heading {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 30px;
  color: var(--web-main);
}

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* QrCodeBenefitComponent :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.qr-benefit-main {
  max-width: 800px;
  margin: 80px auto;
}

.qr-benefit-heading {
  font-size: 1.9rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}

.qr-benefit-content {
  display: flex;
  font-size: 1rem;
}

.qr-benefit-content-image > img {
  width: 200px;
  padding: 10px 30px;
}

.qr-benefit-text {
  margin-bottom: 20px;
}
.qr-benefit-button {
  background-color: var(--web-main);
  color: #fff;
  padding: 8px 30px;
  border-radius: 20px;
  text-decoration: none;
}

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* QrCodeFaqComponent :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.qr-code-faq {
  background-color: #f5f5f5;
  padding: 40px 0 80px;
  color: #314259;
}

.qr-code-faq-main {
  max-width: 800px;
  margin: auto;
}

.qr-code-faq-heading {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}

.qr-code-faq-question {
  display: flex;
  gap: 10px;
  align-items: start;
  font-size: 1.3rem;
  margin: 30px 0 5px;
}
.qr-code-faq-question i {
  font-size: 1.6rem;
  margin-top: 3px;
  color: var(--web-main);
}

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* QrCodeTypeComponent :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.qr-code-types {
  background-color: #e6e6e6;
  color: #314259;
}
.qr-code-types-main {
  max-width: 1140px;
  margin: auto;
  padding: 50px 0;
  /* text-align: center; */
}
.qr-code-types-heading {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}

.qr-code-types-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px 30px;
}

.qr-code-types-col {
  background-color: #fff;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 20px;
  padding: 60px 30px;
}
.qr-code-types-col-icon {
  background-color: #e6e6e6;
  color: var(--web-main);
  font-size: 3rem;
  width:  100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  align-content: center;
  margin: auto;
}

.qr-code-types-col-heading {
  font-size: 1.3rem;
  text-align: center;
  margin: 30px 0 10px;
  font-weight: 500;
}

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* QrCodeCustomersSayComponent :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.qr-code-customers-say {
  background-color: #f5f5f5;
  color: #314259;
}

.qr-code-customers-say-main {
  max-width: 600px;
  margin: auto;
  padding: 50px 0 80px;
}

.qr-code-customers-say-heading {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}

.qr-code-customers-say-content-section {
  background-color: #fff;
  border: 1px solid #cecece;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}

.qr-code-customers-say-content-rating {
    color: #ffd700;
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
}

.qr-code-customers-say-content-heading{
    font-weight: 600;
    margin: 15px 0 5px;
    font-size: 1.1rem;
}

.qr-code-customers-say-content-name{
    font-size: 1.1rem;
    margin-top: 20px;
    font-style: italic;
}




/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* QrCodeFreeGenerator :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.qr-code-free{
    background-color: #e6e6e6;
    color: #314259;
}

.qr-code-free-main{
    max-width: 800px;
    margin: auto;
    padding: 60px 0;
    text-align: justify;
  }
  
  .qr-code-free-heading{
    font-weight: 500;
    text-align: center;
    font-size: 1.9rem;
    margin-bottom: 30px;
    color: var(--web-main);
}
        
.qr-code-free-image{
    width: 250px;
    margin: 30px auto;
}

.qr-code-free-image>img{
    width: 100%;
}





/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

@media only screen and (max-width: 1000px) {
  .what-qr-main {
    flex-direction: column;
    padding: 20px;
    gap: 10px;
  }
  .what-qr-image {
    width: 80%;
    margin: auto;
  }
  .what-qr-image > img {
    width: 100%;
  }

  .what-qr-heading {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* QrCodeBenefitComponent :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

  .qr-benefit-main {
    margin: 40px auto 60px;
    padding: 0 30px;
  }

  .qr-benefit-heading {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }

  .qr-benefit-content {
    flex-direction: column;
    gap: 10px;
  }

  .qr-benefit-content-image > img {
    width: 100%;
    padding: 0 30%;
  }

  .qr-benefit-text {
    margin-bottom: 20px;
    font-size: 0.9rem;
  }
  .qr-benefit-button {
    background-color: var(--web-main);
    color: #fff;
    padding: 8px 30px;
    border-radius: 20px;
    text-decoration: none;
  }

  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* QrCodeFaqComponent :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

  .qr-code-faq-main {
    padding: 0 20px;
  }

  .qr-code-faq-heading {
    font-size: 1.6rem;
  }

  .qr-code-faq-question {
    gap: 5px;
    font-size: 1.1rem;
    margin: 20px 0 5px;
  }
  .qr-code-faq-question i {
    font-size: 1.3rem;
  }

  .qr-code-faq-answer {
    font-size: 0.9rem;
  }

  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* QrCodeTypeComponent :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

  .qr-code-types-main {
    padding: 50px 20px;
  }
  .qr-code-types-heading {
    font-size: 1.4rem;
    margin-bottom: 30px;
  }

  .qr-code-types-content {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .qr-code-types-col {
    padding: 50px 20px;
  }


  
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* QrCodeCustomersSayComponent :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.qr-code-customers-say-content{
    width: 85%;
    margin: auto;
}
  

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* QrCodeFreeGenerator :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.qr-code-free-main{
    padding: 40px 20px;
}
.qr-code-free-heading{
    font-size: 1.4rem;
    margin-bottom: 10px;
}

.qr-code-free-text{
    font-size: .9rem;

}

  
  
  
}
