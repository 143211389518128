.invoice-format-page-header{
    background: url(../../../../media/images/website/invoice-format-bg-image.png);
    background-position: center;
    background-size: cover;
}

.invoice-format-page-header-main{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
}

.invoice-format-page-header-content{
padding: 130px 50px;
color: #314259;
}

.invoice-format-page-header-heading{
    font-size: 2.3em;
    font-weight: 600;
}

.invoice-format-page-header-text{
    font-size: .95rem;
    padding: 40px 0;
}

.invoice-format-page-header-buttons{
    display: flex;
    align-items: center;
    gap: 50px;
}

.invoice-format-page-header-buttons>a{
    border: var(--web-main) 2px solid;
    text-decoration: none;
    padding: 10px 40px;
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--web-main);
    border-radius: 30px;
}
.invoice-format-page-header-buttons>a:first-child{
    background-color: var(--web-main);
    color: #fff;
}

.invoice-format-page-header-image{
    width: 450px;
}
.invoice-format-page-header-image>img{
    width: 100%;
}



