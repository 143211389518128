
/*  ================================================================== */

.app-navbar-main{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    /* border-radius: 20px !important; */
    height: calc(100vh - 20px);
    overflow: auto;
    
}

/* logo ================================================================== */
.app-navbar-invoician{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: var(--main);
    color: white;
}

.app-navbar-invoician-content{
    display: flex;
    gap: 5px;
}
.app-navbar-invoician-logo>img{
    width: 120px;    
    margin-top: 5px;
    filter: brightness(0) invert(1);
}
.app-navbar-invoician-tag>span{
    background-color: white;
    color: var(--main);
    padding: 0px 10px;
    height: auto;
    border-radius: 7px;
    font-size: .9rem;
    font-weight: 600;

}

.app-navbar-invoician-button{
    font-size: 1.3rem;
    cursor: pointer;
}


/* company ================================================================== */

.app-navbar-company{
    padding: 0px 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    align-content: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.app-navbar-company-details{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.app-navbar-add-company{
    text-decoration: none;
    background-color: var(--main);
    color: white;
    padding: 8px 20px;
    border-radius: 20px;
    flex: 1;
    font-weight: 600;
    text-align: center;
    margin: 15px 0;
}

.app-navbar-company-content{
    display: flex;
    gap: 5px;
    height: 70px;
    padding: 5px 0 ;
    /* overflow-x: scroll; */
    align-content: center;
}
.app-navbar-company-profile{
    align-content: center;
}
/* .app-navbar-company-content::-webkit-scrollbar{
    height: 7px;
    width: 7px;
}
.app-navbar-company-content:hover::-webkit-scrollbar-thumb{
    background-color: #174555;;
    cursor: grab;
    border-radius: 20px;
} */

.app-navbar-company-logo{
    align-content: center;
}

.app-navbar-company-logo>div{
    margin-top: -5px;
    width:  50px;
    height: 50px;
    background-color: var(--main);
    border-radius: 50%;
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    text-align: center;
    align-content: center;
}

.app-navbar-company-logo>img{
    max-width: 60px;
    display: flex;
    max-height: 60px;
    justify-content: center;
    align-items: center;
    margin: auto;
    /* margin-top: 8px; */
}


.app-navbar-company-name{
    font-weight: 700;
    font-size: 1.1rem;
    /* background-color: #495057; */
    max-width: 200px;
}

    

.app-navbar-company-number{
    font-size: .9rem;
}

.app-navbar-company-icon{
    font-size: 1.3rem;
    margin-right: 5px;
    cursor: pointer;
    color: var(--main);
}
.app-navbar-company-icon:hover{
    color: var(--main);

}
/* shortcut ================================================================== */

.app-navbar-shortcut{
    background-color: var(--main);
    background-color: #173544;
    color: white;
    margin: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: .95rem;
    font-size: 13px;
    border-radius: 30px;
}
.app-navbar-shortcut>a{
    padding: 8px 15px;
    border-radius: 30px 0 0 30px;
    color: white;
    text-decoration: none;
    flex: 1;
    text-align: center;
}
.app-navbar-shortcut>div:hover,
.app-navbar-shortcut>a:hover{
    background-color: #325a6e;
    transition: 0.1s ease-in;
}
.app-navbar-shortcut>div{
    padding: 8px 15px;
    border-radius: 0 30px 30px 0;
    cursor: pointer;
}

.app-navbar-shortcut-dropdown{
    display: grid;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 0 8px 8px;
    position: absolute;
    width: 216px;
    margin: 0px 12px;
    border-radius: 10px;
    border: solid 1px #d9d4d4;
    max-height: calc(100vh - 130px);
    overflow: auto;
}
.app-navbar-shortcut-dropdown::-webkit-scrollbar{
    width: 7px;
}
.app-navbar-shortcut-dropdown::-webkit-scrollbar-thumb{
    background-color: #999999;
    border-radius: 10px;
}
.app-navbar-shortcut-dropdown-heading{
    color: #667085;
    text-transform: uppercase;
    font-size: 12px;
    padding: 6px 0;
    border-top: solid 1px #d9d4d4;
    border-bottom: solid 1px #d9d4d4;
    margin-top: 6px;
    
}
/* .app-navbar-shortcut-dropdown-heading{ */
.app-navbar-shortcut-dropdown-heading:first-child{
    border-top: none;
    margin-top: 0px;
}
.app-navbar-shortcut-dropdown a{
    color: #667085;
    text-decoration: none;
    display: flex;
    gap: 5px;
    padding: 8px 12px;
    align-items: center;
    font-size: 14px;
}
.app-navbar-shortcut-dropdown a:hover{
    text-decoration: underline;
}


/* menu ================================================================== */

.app-navbar-menu{
    /* margin: 0px 5px 0 20px ; */
    max-height: calc(100vh - 180px);
    overflow: auto;
    /* padding-right: 10px; */
}

.app-navbar-menu::-webkit-scrollbar{
    width: 8px;
}
.app-navbar-menu:hover::-webkit-scrollbar-thumb{
    background-color: var(--main);
    margin-top: 10px;
    border-radius: 20px;
}
    
    
.app-navbar-menu-heading{
    /* margin: 10px 0 10px;  */
    padding: 8px 16px;
    font-weight: 600;
}
.app-navbar-menu-links{
    display: grid;
    /* gap: 25px; */
    padding-bottom: 10px;
}
.app-navbar-menu-links>a{
    color: #495057;
    text-decoration: none;
    padding: 8px 12px;
    margin: 2px 8px ;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.app-navbar-menu-links>a>div{
    display: flex;
    align-items: center;
    gap: 8px;
}
.app-navbar-menu-links>a:hover{
    background: rgb(from var(--main) r g b / 10%);
    border-radius: 30px;
}
.app-navbar-menu-links>a svg{
    fill: #495057;
    /* width: 10px; */
}

.app-navbar-menu-active{
    color: var(--main) !important;
    /* border: solid ; */
    /* border-radius: 30px; */
    /* background-color: rgba(var(--main), 1.8); */
    /* background-color: var(--main); */
    /* background-color: rgba(240, 240, 240, 0.8) */
    /* color: white !important; */
    background: rgb(from var(--main) r g b / 20%);
    /* border-left: 5px solid; */
    border-radius: 30px;
    font-weight: 600;
    /* padding-left: 7px !important; */

}
.app-navbar-menu-active svg{
    fill: var(--main) !important;
}
.app-navbar-menu-links>a>div>i{
    /* width: 40px; */
    text-align: center;
    font-size: 1.2rem;
    font-size: 14px;
    color: #495057;
}

.app-navbar-menu-select{
    background: rgb(from var(--main) r g b / 10%);
    margin:  0px !important;
    padding: 8px 20px !important;
}
.app-navbar-menu-select:hover{
    border-radius: 0 !important;
}

.app-navbar-menu-select-options{
    background: rgb(from var(--main) r g b / 10%);
    /* display: grid; */
}
.app-navbar-menu-select-options>a{
    text-decoration: none;
    padding: 8px 15px;
    display: flex;
    text-decoration: none;
    color: #495057;
    margin: 0 15px;
}
.app-navbar-menu-select-options>a:last-child{
    margin: 0 15px 10px;
    /* color: aqua !important; */
}

.app-navbar-menu-select-options-active{
    background-color: rgb(from var(--main) r g b / 20%);
    color: var(--main) !important;
    /* border-left: 5px solid; */
    /* border: 2px solid  rgb(from var(--main) r g b / 50%); */
    font-weight: 600;
    /* padding-left: 7px !important; */
    border-radius: 30px;
}


/* logout ================================================================== */

.app-navbar-logout{
    cursor: pointer;
    padding: 10px 40px;
    background-color: var(--main);
    border-radius: 0 0 10px 10px;
    color: white;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: center;
}

/* end ================================================================== */

.app-navbar-setting{
    text-decoration: none;
    cursor: pointer;
    padding: 10px 40px;
    background-color: var(--main);
    border-radius: 0 0 10px 10px;
    color: white;
    font-size: 1.1rem;
    font-weight: 500;
    /* text-align: center; */
    display: flex;
    justify-content: space-between;
}


/*  ================================================================== */
/*  ================================================================== */
/*  ================================================================== */
/*  ================================================================== */
/*  ================================================================== */


.app-navbar-top{
    display: flex;
    align-items: center;
    margin: 8px 8px;
    padding-left: 4px;
    border-radius: 8px;
    justify-content: space-between;
    text-decoration: none;
    color: black;
}

.app-navbar-top-company{
    display: flex;
    align-items: center;
    gap: 5px;
}
.app-navbar-top-company-icon{
    background-color: var(--main);
    color: white;
    /* font-size: 13px; */
    font-weight: 500;
    width: 30px;
    height: 30px;
    text-align: center;
    align-content: center;
    border-radius: 50%;
}

.app-navbar-top-company-logo>img{
    max-width: 40px;
    max-height: 40px;
}

.app-navbar-top-company-name{
    font-weight: 600;
    margin-bottom: -2px;
    font-size: 14px;
}

.app-navbar-top-setting{
    color: white;
    font-size: 18px;
    padding: 10px 8px;
    border-radius: 0 8px 8px 0;
}
.app-navbar-top:hover{
    background-color: #17455525;
}
.app-navbar-top:hover
.app-navbar-top-setting{
    background-color: #17455525;
    color: #174555;


}


.app-navbar-menu-alert{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    font-size: 12px;
    background-color: #e4bc67;
    margin-bottom: 8px;
}

.app-navbar-menu-alert-warn{
    background-color: #d94141;
    color: white;
    padding: 2px 8px;
    font-size: 11px;
    border-radius: 10px;

}


/*  ================================================================== */

@media only screen and (max-width: 1000px) {
.app-navbar-main{
    display: none;
}

.app-navbar-logout{
    border-radius: 0;
}

}



/*  ================================================================== */
/*  ================================================================== */
/*  ================================================================== */
/*  ================================================================== */
/*  ================================================================== */
/*  ================================================================== */
