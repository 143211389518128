.business-card-maker-header {
  background-color: #f5f5f5;
  background-color: #e6e6e6;
}

.business-card-maker-header-main {
  max-width: 1140px;
  margin: auto;
  padding: 40px 10px;
}

.business-card-maker-header-heading {
  font-size: 3rem;
  color: var(--web-main);
  font-weight: 700;
}

.business-card-maker-header-content {
  display: grid;
  grid-template-columns: 3fr 5fr;
  gap: 40px;
  color: #314259;
  padding: 40px 0;
}

.business-card-maker-header-content-form {
  display: grid;
}

.business-card-maker-header-content-form > input,
.business-card-maker-header-content-form > textarea {
  margin: 5px 0 20px;
  border: none;
  outline: none;
  background-color: #fff;
  padding: 5px;
}

.business-card-maker-header-content-form > label > span {
  color: var(--web-main);
  font-weight: 600;
  font-size: 1rem;
}

.business-card-maker-header-content-form > button {
  background-color: #28a745;
  width: max-content;
  margin: auto;
  color: #fff;
  border: none;
  padding: 5px 30px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.business-card-maker-header-content-details{
  width: 550px;
}


.business-card-maker-header-content-card {
  width: max-content;
}

.business-card-maker-header-content-card > div {
  background-color: #fff;
  width: 550px;
  height: 315px;
  background-size: cover;
  color: #000;
}

.business-card-maker-header-content-options {
  display: grid;
  padding: 30px 20px 40px;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  /* width: 550px; */
}

.business-card-maker-header-content-options > img {
  background-color: #fff;
  border-radius: 15px;
  width: 100%;
  cursor: pointer;
  border: 3px solid #666;
}

.business-card-maker-header-content-option-active {
  border-color: #00f !important;
}

.business-card-maker-header-content-buttons {
  display: flex;
  justify-content: center;
  /* width: 550px; */
  gap: 20px;
}
.business-card-maker-header-content-buttons > div {
  background-color: #dc3545;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  padding: 8px 60px;
  border-radius: 5px;
  cursor: pointer;
}
.business-card-maker-header-content-buttons > div:nth-child(2) {
  background-color: #007bff;
}

/* .business-card-maker-header-content-form */

.business-card-maker-header-content-card pre {
  white-space: pre-wrap;
  font-size: 15px;
  font-family: poppins;
}

/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* theme 1 ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.business-card-maker-theme-1 {
    background: url(../../../../media/images/website/BusinessCard/bg1.png);
}
.business-card-maker-theme-1>div{
    padding: 0 15px;
}

.business-card-maker-theme-1-logo{
    align-content: center;
    height: 170px;
    margin-top: 25px;
    /* border-right: 3px solid #c40010; */
    border-right: 3px solid #c7c7c7;
}

.business-card-maker-theme-1-logo > div{
    background-color: #c40010 !important;
}


.business-card-maker-theme-5-text > i,
.business-card-maker-theme-1-text > i {
    margin-top: 5px;
    color: #c40010;
  }

/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* theme 2 ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.business-card-maker-theme-2 {
  background: url(../../../../media/images/website/BusinessCard/bg2.jpg);
}

.business-card-maker-theme-5,
.business-card-maker-theme-1,
.business-card-maker-theme-3,
.business-card-maker-theme-2 {
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.business-card-maker-theme-2-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
}

.business-card-maker-theme-5-name,
.business-card-maker-theme-4-name,
.business-card-maker-theme-1-name,
.business-card-maker-theme-3-name,
.business-card-maker-theme-2-name {
  font-size: 1.4rem;
  font-weight: 600;
}

.business-card-maker-theme-5-logo > div,
.business-card-maker-theme-4-logo > div,
.business-card-maker-theme-1-logo > div,
.business-card-maker-theme-3-logo > div,
.business-card-maker-theme-2-logo > div {
  background-color: #222;
  /* width: max-content; */
  width: 80px;
  height: 80px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.business-card-maker-theme-5-logo > img,
.business-card-maker-theme-4-logo > img,
.business-card-maker-theme-1-logo > img,
.business-card-maker-theme-3-logo > img,
.business-card-maker-theme-2-logo > img {
  max-width: 150px !important;
  max-height: 110px !important;
}

.business-card-maker-theme-2-details {
  text-align: right;
  width: 65%;
  display: grid;
  align-content: start;
  /* gap: 10px; */
}
.business-card-maker-theme-2-details > div {
  margin-top: 10px;
}
.business-card-maker-theme-2-details > div:first-child {
  margin-top: 0;
}

/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* theme 3 ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.business-card-maker-theme-3 {
  background: url(../../../../media/images/website/BusinessCard/bg3.jpg);
  padding: 20px 30px;
}

.business-card-maker-theme-5-text,
.business-card-maker-theme-4-text,
.business-card-maker-theme-1-text,
.business-card-maker-theme-3-text {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-top: 10px;
}


.business-card-maker-theme-3-text > i {
  margin-top: 5px;
  color: #314259;
}



/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* theme 4 ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.business-card-maker-theme-4{
    display: flex;
}
.business-card-maker-theme-4>div:first-child{
    background-color: #0095d9;
    color: #fff;
    padding: 15px 20px;
}

.business-card-maker-theme-4-text > i {
    margin-top: 5px;
    color: #000;
}

.business-card-maker-theme-4>div:nth-child(2){
    /* background-color: #000; */
    height: max-content;
    margin: auto;
    padding: 20px 20px 20px 30px;
    border-left: #333 6px solid;
    margin-left: -6px;
}




/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* theme 5 ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */


.business-card-maker-theme-5 {
    background: url(../../../../media/images/website/BusinessCard/bg5.jpg);
}

.business-card-maker-theme-5>div{
    padding: 0 25px;
}


.business-card-maker-theme-5-logo{
    align-content: center;
    height: 120px;
    margin-top: 45px;
    border-right: 2px solid #c7c7c7;
}

@media only screen and (max-width: 1000px) {


  .business-card-maker-header-main{
    padding: 40px 5%;
  }
  

.business-card-maker-header-heading {
  font-size: 1.8rem;
}
  

.business-card-maker-header-content {
  grid-template-columns: 1fr;
  padding: 20px 0px;
}

.business-card-maker-header-content-card-main {
  width: 90vw;
  overflow: auto;
  /* display: none; */
}

.business-card-maker-header-content-details{
  width: 100%;
}

.business-card-maker-header-content-options {
  width: 100%;
  gap: 10px;
  padding: 20px 0;
}

.business-card-maker-header-content-buttons > div {
  padding: 8px 40px;
}



  
}
