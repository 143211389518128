
.app-package-expire-main{
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, rgba(23,69,85,1) 10%, rgba(0,0,0,1) 100%);
    margin: -20px;
    margin-bottom: 20px;
    padding: 20px;
    color: #fff;
    gap: 20px;
}

.app-package-expire-heading{
    font-weight: 400;
    font-size: 30px;
}
.app-package-expire-time{
    display: flex;
    align-items: center;
    text-align: center;
    gap: 10px;
}

.app-package-expire-time-col{
    background-color: #fff2;
    padding: 10px 25px;
    border-radius: 5px;
    font-size: 15px;
}
.app-package-expire-time-col>div{
    font-size: 30px;
}
.app-package-expire-time-colon{
    font-size: 30px;
    font-weight: 500;
}


@media only screen and (max-width: 1000px) {

.app-package-expire-main{
    flex-direction: column;
    gap: 10px;
    margin: -15px -20px 15px;
    padding: 20px 10px;
}

.app-package-expire-heading{
    font-size: 17px;
}

.app-package-expire-time-col{
    padding: 10px 14px;
}
.app-package-expire-time-col>div{
    font-size: 22px;
}
    
}



