.anytime{
    max-width: 1200px;
    margin: 120px auto;
    /* border: solid 1px; */
}


.anytime-main{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    height: 500px;
    gap: 80px;
}

.anytime-col-1-heading-1{
    font-size: 1.4rem;
    color: var(--main);
    font-weight: 500;
    margin: 0 0 20px 0;
}
.anytime-col-1-heading-2{
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1;
    margin: 20px 0;
}
.anytime-col-1-text{
    font-size: 1.1rem;
    margin: 20px 0 30px;
}
.anytime-col-1-button{
    background-color: var(--main);
    color: white;
    padding: 10px 50px;
    text-decoration: none;
    border-radius: 40px;
}

.anytime-col-2{
    overflow: scroll;
    height: 500px;
    display: grid;
    gap: 40px;
}
.anytime-col-2::-webkit-scrollbar{
    display: none;
}
.anytime-col-2>img{
    width: 100%;
    border-radius: 20px;
}



@media only screen and (max-width: 700px) {
    
    .anytime{
        margin: 80px auto;
    }
    
    .anytime-main{
        grid-template-columns: repeat(1, 1fr);
        height: auto;
        gap: 20px;
    }
    
    .anytime-col-1{
        margin: 20px;
    }


    .anytime-col-1-heading-1{
        font-size: 1.1rem;
        margin: 0 0 10px 0;
    }
    .anytime-col-1-heading-2{
        font-size: 2rem;
        margin: 10px 0;
    }
    
    .anytime-col-1-text{
        font-size: 1rem;
    }

    .anytime-col-1-button{
        background-color: var(--main);
        color: white;
        padding: 10px 50px;
        text-decoration: none;
        border-radius: 40px;
    }
    
    .anytime-col-2{
        overflow: scroll;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;
        height: auto;
        display: flex;
        padding: 0 20px;
        gap: 20px;
    }
    .anytime-col-2::-webkit-scrollbar{
        display: none;
    }
    .anytime-col-2>img{
        width: 80%;
        /* border-radius: 10px; */
    }
    
    
}



