.create-item-popup,
.create-party-popup {
  background-color: #00000060;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
  overflow: auto;
  margin: auto;
}
.create-item-popup-main,
.create-party-popup-main {
  background-color: #ffff;
  border-radius: 8px;
  height: max-content;
  align-content: center;
}

.create-item-popup-top,
.create-party-popup-top {
  border-radius: 8px;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  min-width: 515px;
  font-size: 18px;
  font-weight: 500;
}

.create-item-popup-top i,
.create-party-popup-top i {
  cursor: pointer;
  font-size: 22px;
}

.create-party-popup-form {
  padding: 15px 20px 0;
  max-height: calc(100vh - 150px);
  overflow: auto;
  margin-right: 1px;
}

.create-party-popup-form::-webkit-scrollbar {
  width: 8px;
}
.create-party-popup-form::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 5px;
}

.create-party-popup-form-col {
  display: grid;
  margin-bottom: 20px;
  height: max-content;
}

.create-party-popup-form-col label {
  color: #787878;
}
.create-party-popup-form-col label span {
  color: #d01717;
  font-weight: 700;
}

.create-party-popup-form-col input,
.create-party-popup-form-col select {
  appearance: none;
  color: #000;
  background-color: #fff;
}

.create-party-popup-form-col select,
.create-party-popup-form-col textarea,
.create-party-popup-form-col input {
  padding: 8px 10px;
  border: solid 1px #d9d4d4;
  border-radius: 5px;
  margin-top: 5px;
}

.create-party-popup-form-col select:focus,
.create-party-popup-form-col textarea:focus,
.create-party-popup-form-col input:focus {
  outline: solid 1px #488dff;
}

.create-party-popup-form-link {
  color: #488dff;
  font-weight: 500;
  cursor: pointer;
  margin: 5px 0 15px;
  width: max-content;
}

.create-item-popup-buttons,
.create-party-popup-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 5px 20px 20px;
  gap: 10px;
}

.create-item-popup-buttons > button,
.create-party-popup-buttons > button,
.create-item-popup-buttons > div,
.create-party-popup-buttons > div {
  padding: 5px 50px;
  border: solid 1px #d9d4d4;
  border-radius: 5px;
  cursor: pointer;
}

.create-item-popup-buttons > div:nth-child(2),
.create-party-popup-buttons > div :nth-child(2) {
  color: #4d3cceb7;
  background-color: #e8e6ff;
  font-weight: 500;
  cursor: no-drop;
}

.create-item-popup-buttons > button,
.create-party-popup-buttons > button {
  background-color: #4c3cce;
  color: #ffff;
  font-weight: 500;
}
.create-party-popup-form-head {
  display: flex;
  background-color: #f9f9f9;
  padding: 10px 20px;
  margin: 10px -20px;
  justify-content: space-between;
  align-items: center;
}
.create-party-popup-form-head span {
  color: #d01717;
  cursor: pointer;
}
.create-party-popup-form-head div {
  font-size: 16px;
}

.create-party-popup-form-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 20px;
}

.create-party-popup-form-row-flex > div,
.create-party-popup-form-row > div {
  width: 100%;
}
.create-party-popup-form-row-flex {
  display: flex;
  width: 100%;
  gap: 30px;
}

.create-party-popup-form-option {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 30px 0 20px;
  cursor: pointer;
  width: max-content;
}

.create-party-popup-form-option i {
  font-size: 20px;
}

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

/* -------------------------------------------------- */
.create-item-popup-main {
  width: 880px;
  color: #292d35;
}

.create-item-popup-top {
  padding: 20px;
  font-size: 20px;
  font-weight: 500;
}

.create-item-popup-content {
  display: flex;
  padding: 15px;
  gap: 15px;
}

.create-item-popup-content > div {
  border: solid 1px #d9d4d4;
  border-radius: 10px;
}

.create-item-popup-menu {
  width: 225px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 0;
  color: #667085;
  min-height: 400px;
}
.create-item-popup-menu b {
  color: #d01717;
}

.create-item-popup-menu-option-active,
.create-item-popup-menu-option {
  margin: 0 16px;
  padding: 8px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  gap: 5px;
  align-items: center;
}
.create-item-popup-menu-option-active {
  background-color: #f1f0fc;
  color: #4c3cce;
  font-weight: 600;
}
.create-item-popup-menu-option svg {
  fill: #667085;
}
.create-item-popup-menu-option-active svg {
  fill: #4c3cce;
}

.create-item-popup-menu-option:hover {
  background-color: #f3f3f3;
}

.create-item-popup-menu-hr {
  border-top: solid 1px #d9d4d4;
}

.create-item-popup-menu-heading {
  padding: 8px 16px;
  color: #212529;
  font-weight: 500;
}

/* ----------------------------------- */

.create-item-popup-form {
  flex: 1;
  padding: 15px;
  height: max-content;
}

.create-item-popup-form select,
.create-item-popup-form input {
  padding: 8px 10px;
  border: solid 1px #d9d4d4;
  border-radius: 5px;
  margin-top: 5px;
  outline: none;
  color: #292d35;
}

.create-item-popup-form-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0px 20px;
}
.create-item-popup-form-col {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.create-item-popup-form-col label {
  color: #667085;
  font-weight: 500;
}
.create-item-popup-form-col label span {
  color: #d01717;
  font-weight: 700;
  /* font-size: 16px; */
}

.create-item-popup-form-col-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  cursor: pointer;
}
.create-item-popup-form-col-type {
  border: solid 1px #d9d4d4;
  padding: 8px 10px;
  border-radius: 5px;
}

.create-item-popup-form-col-type input {
  width: 20px;
  height: 20px;
  margin: 0;
}

.create-item-popup-form-col-sale {
  display: flex;
  border: solid 1px #d9d4d4;
  align-items: center;
  border-radius: 5px;
  margin-top: 5px;
}

.create-item-popup-form-col-sale span {
  padding-left: 8px;
  font-size: 20px;
}

.create-item-popup-form-col-sale input {
  border: none;
  flex: 1;
  margin: 0;
}

.create-item-popup-form-col-sale select {
  border: none;
  padding: 8px 0;
  background-color: #f3f3f3;
  margin: 0;
}

.create-item-popup-form-col-stock {
  border: solid 1px #d9d4d4;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-top: 5px;
}

.create-item-popup-form-col-stock input {
  border: none;
  flex: 1;
  margin: 0;
}

.create-item-popup-form-col-stock span {
  background-color: #f3f3f3;
  padding: 8px;
}

.create-item-popup-form-custom {
  text-align: center;
  padding: 100px 0;
}
.create-item-popup-form-custom-button {
  padding: 15px;
}
.create-item-popup-form-custom-button span {
  background-color: #e7f4fd;
  color: #0c8ce9;
  font-weight: 600;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
}

/* ----------------------------------- */
.create-item-popup-buttons {
  border-top: solid 1px #d9d4d4;
}

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.create-party-popup-end {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: -10px; */
  background-color: #ffffff;
  border: solid 1px #d9d4d4;
  border-radius: 10px;
}
.create-party-popup-end-option {
  padding: 5px 20px 20px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
}
.create-party-popup-end-option-red {
  color: #d01717;
  font-weight: 600;
}

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.create-popup-form-adjust-balance {
  display: flex;
  gap: 10px;
  padding-top: 5px;
}
.create-popup-form-adjust-balance > div {
  background-color: #e6e2e2;
  border: solid 1px #e6e2e2;
  border-radius: 20px;
  padding: 3px 15px;
  cursor: pointer;
}
.create-popup-form-adjust-balance-active {
  background-color: #e8e6ff !important;
  color: #4c3cce;
  border-color: #4c3cce !important;
}

.create-party-popup-button-delete {
  background-color: #d01717;
  color: white;
}

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.create-expense-item-popup-main {
  background-color: white;
  border-radius: 10px;
}

.create-expense-page-table-add-item-main {
  background-color: white;
  width: 800px;
  border-radius: 10px;
}
.create-expense-page-table-add-item-main input {
  padding: 5px !important;
  border: none !important;
}

.create-expense-page-table-add-item-table-body-row,
.create-expense-page-table-add-item-table-head {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
}

@media only screen and (max-width: 1000px) {
  .create-item-popup,
  .create-party-popup {
    padding: 0px 15px 60px;
    z-index: 4;
    height: 100%;
  }

  .create-item-popup-main,
  .create-party-popup-main {
    width: 100%;
  }

  .create-item-popup-top,
  .create-party-popup-top {
    min-width: auto;
    position: sticky;
    top: 0;
    padding: 10px;
  }

  .create-party-popup-form {
    max-height: none;
  }

  .create-party-popup-form-row-flex {
    flex-direction: column;
    gap: 0px;
  }
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

  .create-item-popup-content {
    flex-direction: column;
  }

  .create-item-popup-menu {
    width: auto;
    min-height: auto;
    padding: 10px;
    border: solid 1px #4c3cce !important;
  }

  .create-item-popup-menu-heading,
  .create-item-popup-menu-hr {
    display: none;
  }

  .create-item-popup-menu {
    flex-direction: row;
    gap: 0;
  }

  .create-item-popup-menu-option-active > span,
  .create-item-popup-menu-option > span {
    display: none;
  }

  .create-item-popup-menu-option-active,
  .create-item-popup-menu-option {
    flex: 1;
  }

  .create-item-popup-menu-option-active,
  .create-item-popup-menu-option {
    margin: 0;
  }

  .create-item-popup-form > div > .create-item-popup-form-row {
    grid-template-columns: 1fr;
  }

  .create-party-popup-form-row {
    grid-template-columns: 1fr;
  }
  .create-expense-page-table-add-item-table-head {
    display: none;
  }

  .create-expense-page-table-add-item-table-body-row{
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    padding: 5px 0;
  }

  .create-expense-page-table-add-item-table-body-row
  .create-invoice-page-table-add-item-table-quantity-added>div{
    height: 30px;
  }
  
  .create-expense-page-table-add-item-table-purchase{
    order: 1;
    margin-top: -30px;
    font-weight: 500;
    font-size: 16px;
  }

  .create-item-popup-form-col-item-category{
    order: -1;
  }
  
}

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
