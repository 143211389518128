
.invoice-theme-1-theme {
  padding: 10px 25px;
  font-size: 14px;
  /* background-color: #00b944; */
}

.invoice-theme-1-heading{
  margin: 10px 0 10px 0;
  font-size: 14px;
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 600;
  text-transform: uppercase;
}
.invoice-theme-1-heading>span{
  font-size: 12px;
  border: solid 1px #868686;
  color: #868686;
  padding: 2px 5px;
}

/* address -------------------------------------------------------------------------------------- */

.invoice-theme-1-company {
  display: flex;
  align-items: center;
  gap: 15px;
  /* width: 850px; */
  font-size: 14px;
}

.invoice-theme-1-company-logo > img {
  max-width: 150px;
  max-height: 100px;
  min-width: 80px;
}

.invoice-theme-1-company-details-name {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--invoice);
}
.invoice-theme-1-company-details-text b {
  font-weight: 600;
}

.invoice-theme-1-company-details-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 450px;
}

/* invoice details -------------------------------------------------------------------------------------- */

.invoice-theme-1-invoive-details-wb {
  background-color: rgb(from var(--main) r g b / 20%);
  background-color: rgb(from var(--invoice) r g b / 20%);
}
.invoice-theme-1-invoive-details-wb,
.invoice-theme-1-invoive-details {
  border-top: 5px solid var(--main);
  border-top: 5px solid var(--invoice);
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  justify-content: space-around;
  padding: 10px;
}

.invoice-theme-1-invoive-details-col {
  display: flex;
  gap: 5px;
  align-items: center;
}
.invoice-theme-1-invoive-details-col > input {
  width: auto;
}
.invoice-theme-1-invoive-details-col-heading {
  font-weight: 600;
}

/* party details -------------------------------------------------------------------------------------- */

.invoice-theme-1-party-details {
  margin: 10px 10px 0;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.invoice-theme-1-party-details-col-1 {
  /* font-size: 0.95rem; */
  font-size: 14px;
  display: flex;
  flex: 1;
  gap: 20px;
}
.invoice-theme-1-party-details-col-1 > div {
  width: 100%;
}

.invoice-theme-1-party-details-col-1-add {
  font-size: 1.2rem;
  color: var(--main);
  color: var(--invoice);
  font-weight: 700;
  border: 2px dashed var(--main);
  border: 2px dashed var(--invoice);
  margin: 20px 40px;
  padding: 10px 20px;
  cursor: pointer;
}

.invoice-theme-1-party-details-col-1-heading {
  /* font-size: 1.2rem; */
  font-size: 16px;
  font-weight: 500;
  display: flex;
  gap: 50px;
  align-items: center;
}
.invoice-theme-1-party-details-col-1-heading > div {
  font-size: 1rem;
  color: white;
  color: var(--main);
  color: var(--invoice);
  text-decoration: underline;
  border-radius: 5px;
  cursor: pointer;
  color: blue;
  font-weight: 500;
  font-size: 1.1rem;
}
.invoice-theme-1-party-details-col-1-name {
  font-size: 16px;
  font-weight: 600;
  /* margin: 5px 0 2px; */
}

.invoice-theme-1-party-details-col-1-text b {
  font-weight: 600;
}

.invoice-theme-1-party-details-col-2 {
  gap: 15px;
  font-size: 0.95rem;
  line-height: 1.1rem;
  text-align: start;
}

.invoice-theme-1-party-details-col-2 table {
  width: max-content;
  max-width: 300px;
  font-size: 14px;
}
.invoice-theme-1-party-details-col-2 td {
  padding: 0px 4px 10px;
}
.invoice-theme-1-party-details-col-2 td:nth-child(1) {
  font-weight: 600;
}
.invoice-theme-1-party-details-col-2-col-colons,
.invoice-theme-1-party-details-col-2-col-heading {
  font-weight: 600;
}
.invoice-theme-1-party-details-col-2-col-data {
  padding-left: 10px;
}

/* items details -------------------------------------------------------------------------------------- */

.invoice-theme-1-item-data {
  min-height: 320px;
}

.invoice-theme-1-item-data-add {
  cursor: pointer;
  border: dashed 2px var(--main);
  border: dashed 2px var(--invoice);
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--main);
  color: var(--invoice);
  padding: 8px;
  text-align: center;
  margin: 20px;
}

.invoice-theme-1-item-data-table {
  width: 100%;
  margin-top: 10px;
}
.invoice-theme-1-item-data-table-wb > thead {
  background-color: rgb(from var(--main) r g b / 20%);
  background-color: rgb(from var(--invoice) r g b / 20%);
}
.invoice-theme-1-item-data-table > thead > tr > th {
  padding: 10px;
  padding: 10px 8px;
  font-weight: 600;
}
.invoice-theme-1-item-data-table-wb > tbody > tr:nth-child(even) {
  background-color: rgb(from var(--main) r g b / 6%);
  background-color: rgb(from var(--invoice) r g b / 6%);
}
.invoice-theme-1-item-data-table > tbody > tr > td {
  text-align: center;
  padding: 10px;
  vertical-align: top;
}
.invoice-theme-1-item-data-table > tbody > tr > td >div{
  font-size: 11px;
  color: #666666;
}
.invoice-theme-1-item-data-table > tbody > tr > td:nth-child(2) {
  text-align: start;
}

/* ------------------------ */
.invoice-theme-1-item-data-sub-total-wb {
  background-color: rgb(from var(--main) r g b / 20%);
  background-color: rgb(from var(--invoice) r g b / 20%);
}
.invoice-theme-1-item-data-sub-total {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  border-top: 2px solid var(--main);
  border-top: 2px solid var(--invoice);
  font-weight: 600;
  font-size: 1rem;
  margin-top: 0px;
}

/* invoice end -------------------------------------------------------------------------------------- */

.invoice-theme-1-end {
  display: grid;
  grid-template-columns: 5fr 3fr;
  padding: 10px 10px;
}

.invoice-theme-1-end-col-1 {
  max-width: 450px;
}
.invoice-theme-1-end .invoice-theme-1-end-col-1-bank-datails-heading {
  text-transform: uppercase;
}
.invoice-theme-1-end-col-1-bank-datails-heading {
  font-weight: 600;
  font-size: 15px;
  margin: 10px 0 0px;
  text-transform: capitalize;
}

.invoice-theme-1-end-col-1-bank-datails-data {
  font-size: 0.9rem;
  font-size: 13px;
}

.invoice-theme-1-end-col-1-bank-datails-data td {
  padding-right: 15px;
}
.invoice-theme-1-end-col-1-bank-datails-data td:nth-child(1) {
  font-weight: 600;
}


.invoice-theme-1-end-col-1-bank-icons{
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}
.invoice-theme-1-end-col-1-bank-icons>img{
  height: 12px;
}

.invoice-theme-1-end-col-1-bank-datails-upi{
  margin-bottom: 10px;
  display: flex;
  gap: 20px;
  font-size: 13px;
}
.invoice-theme-1-end-col-1-bank-datails-upi b{
  font-weight: 600;
}

.invoice-theme-1-end-col-1-bank-datails-upi-col-2{
  display: flex;
  align-items: center;
}


/* ------------------- */

.invoice-theme-1-end-col-1-tc-heading {
  font-weight: 600;
}

.invoice-theme-1-end-col-1-tc-list {
  font-size: 0.8em;
  margin-bottom: 10px;
}

.invoice-theme-1-end-col-1-tc-list pre{
  max-width: 100%;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-family: "Source Sans 3", sans-serif;
  font-size: 12px;
}

/* col-2 ---------------------------*/

.invoice-theme-1-end-col-2-amount-col {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
}
.invoice-theme-1-end-col-2-amount-col i {
  font-size: 1.2rem;
}
.invoice-theme-1-end-col-2-amount-col-blue {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.invoice-theme-1-end-col-2-amount-col-green {
  color: #00b944;
}

.invoice-theme-1-end-col-2-amount-col input {
  width: 110px;
  margin-right: -10px;
  margin-top: 5px;
  text-align: end;
}

.invoice-theme-1-end-col-2-amount-col-heading {
  font-weight: 500;
}

.invoice-theme-1-end-col-2-amount-col-total {
  border-top: 2px solid var(--main);
  border-bottom: 2px solid var(--main);
  border-top: 2px solid var(--invoice);
  border-bottom: 2px solid var(--invoice);
  padding: 2px 5px;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 2px;
}

.invoice-theme-1-end-col-2-amount-text {
  text-align: end;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 0.9rem;
}
.invoice-theme-1-end-col-2-amount-text > div:nth-child(1) {
  font-size: 0.9rem;
}

.invoice-theme-1-end-col-2-signature{
  text-align: end;
  font-weight: 600;
  margin-top: 20px;
  /* display: grid; */
}

.invoice-theme-1-end-col-2-signature-empty{
  border: solid 1px #000;
  height: 80px;
  margin-bottom: 5px;
}

  
.invoice-theme-1-end-col-2-signature>img{
  max-width: 110px;
  max-height: 110px;
  /* margin: auto; */
  margin-bottom: -2px;
  margin-top: 10px;
}
.invoice-theme-1-end-col-2-signature-text>div{
  font-weight: 700;
  color: #26353D;

}

/* ------------------------------------------------------------------------------ */

.invoice-theme-1-item-data-table-remove {
  font-size: 1.2rem;
  padding: 5px !important;
}
.invoice-theme-1-item-data-table-remove:hover {
  color: red;
  cursor: pointer;
}

.invoice-theme-1-item-data-table-sno {
  width: 40px;
}

.invoice-theme-1-item-data-table-qty {
  width: 80px;
  padding: 20px 10px !important;
}
.invoice-theme-1-item-data-table-tax {
  width: 80px;
  /* padding: 20px 10px !important; */
}

.invoice-theme-1-item-data-table-price {
  width: 120px;
  padding: 20px 10px !important;
}

.invoice-theme-1-item-data-table-total {
  width: 120px;
  padding: 20px 10px !important;
}

.invoice-theme-1-item-data-table-total > div,
.invoice-theme-1-item-data-table-tax > div,
.invoice-theme-1-item-data-table-price > div,
.invoice-theme-1-item-data-table-qty > div {
  /* border: solid 1px #c0c0c0; */
  /* padding: 2px;
        font-size: .9rem; */
}

.invoice-theme-1-item-data-table-total input,
.invoice-theme-1-item-data-table-tax input,
.invoice-theme-1-item-data-table-price input,
.invoice-theme-1-item-data-table-qty input {
  padding: 2px;
  width: 100%;
}


